const serverLink = process.env.REACT_APP_API_URL || 'https://api.attendlab.ml';
const serverApi = serverLink + '/api';

const config = {
    serverAPI: {
        employees: {
            get: serverApi + '/employees',
            delete: serverApi + '/employees/delete',
            export: serverApi + '/employees/export/csv',
            exportPdf: serverApi + '/employees/export/pdf',
        },
        users: {
            get: serverApi + '/users',
            delete: serverApi + '/users/delete',
            export: serverApi + '/users/export/csv',
            exportPdf: serverApi + '/users/export/pdf',
            me: serverApi + '/users/me',
            forget_password: serverApi + '/users/forget_password',
            change_password: serverApi + '/users/change_password',
            set_password: serverApi + '/users/set_password/',
        },
        locations: {
            get: serverApi + '/locations',
            filter: serverApi + '/filters/locations',
        },
        roles: {
            get: serverApi + '/roles',
            delete: serverApi + '/roles/delete',
            filter: serverApi + '/filters/roles',
        },
        attendanceLog: {
            get: serverApi + '/attendance',
            export: serverApi + '/attendance/export/csv',
            exportPdf: serverApi + '/attendance/export/pdf',
            exportZohoPeople: serverApi + '/attendance/export/zoho-csv',
        },
        auditLog: {
            get: serverApi + '/audit',
            export: serverApi + '/audit/export/csv',
            exportPdf: serverApi + '/audit/export/pdf',
        },
        timeSheet: {
            get: serverApi + '/timesheet',
            filter: serverApi + '/filters/employees',
            export: serverApi + '/timesheet/export/csv',
            exportPdf: serverApi + '/timesheet/export/pdf',
            exportDetailed: serverApi + '/timesheet-detailed/export/csv',
            exportPdfDetailed: serverApi + '/timesheet-detailed/export/pdf',
        },
        companies: {
            get: serverApi + '/companies',
        },
        login: {
            get: serverApi + '/login',
            logout: serverApi + '/logout',
        },
        dashboard: {
            get: serverApi + '/reports',
        },
        subscription: {
            cancel: serverApi + '/subscription/cancel',
            upgrade: serverApi + '/subscription/upgrade',
            downgrade: serverApi + '/subscription/downgrade',
            addon: serverApi + '/subscription/addon',
            stats: serverApi + '/subscription/stats',
        },
        invoices: {
            get: serverApi + '/subscription/invoices',
            export: serverApi + '/subscription/invoices/export',
            exportPdf: serverApi + '/subscription/invoices/pdf',
        },
        pricing: {
            get: serverApi + '/pricing',
        },
        support: {
            get: serverApi + '/support',
        },
        verify: {
            get: serverApi + '/verify',
            session: serverApi + '/session',
            email: serverApi + '/verify/email',
        },
    },
    exportLink: serverLink,
    subscription: {
        extensionValues: {
            user: 2,
            employee: 1,
            device: 1,
        },
        plans: {
            free: {
                users: 1,
                employees: 5,
                devices: 1,
            },
            professional: {
                users: 5,
                employees: 20,
                devices: 5,
            },
            enterprise: {
                users: 10,
                employees: 40,
                devices: 10,
            },
        },
    },
};

export default config;

import React, {Component} from 'react'
import Plans from '../LandingPage/Plans';
import {Button, Card, Dimmer, Icon, Label, Loader, Statistic} from 'semantic-ui-react';
import AddonModal from './AddonModal';
import Axios from 'axios';
import UpgradeConfirmationModal from './UpgradeConfirmationModal';
import CancelConfirmationModal from "./CancelConfirmationModal";
import config from 'react-global-configuration'
import DownGradeConfirmationModal from "./DwongradeConfirmationModal";

export default class MembershipManager extends Component {
    state = {
        addonModalVisible: false,
        upgradeConfirmationModalVisible: false,
        downgradeConfirmationModalVisible: false,
        cancelConfirmationModalVisible: false,
        upgradePlan: "",
        downgradePlan: "",
        planStats: {
            allowed: {
                users: null,
                employees: null,
                devices: null
            },
            current: {
                users: null,
                employees: null,
                devices: null
            }
        },
        pricing: {},
        loading: false,
        addons: {
            users: null,
            employees: null,
            devices: null
        }
    };

    componentDidMount() {
        document.title = 'AttendLab | Subscription';
        this.getData();
    }

    getData() {
        this.setState({loading: true});
        Axios.get(config.get('serverAPI').subscription.stats)
            .then(response => {
                this.setState({
                    planStats: {
                        allowed: {
                            users: response.data.data.user.allowed,
                            employees: response.data.data.employee.allowed,
                            devices: response.data.data.device.allowed,
                        },
                        current: {
                            users: response.data.data.user.count,
                            employees: response.data.data.employee.count,
                            devices: response.data.data.device.count,
                        }
                    },
                }, () => this.getPrices());
            })
    }

    getPrices = () => {
        Axios.get(config.get('serverAPI').pricing.get)
            .then(response => {
                this.setState({
                    pricing: response.data.data,
                    loading: false
                })
            })
    };

    showAddonModal = () => {
        this.setState({
            addonModalVisible: true
        })
    };

    hideAddOnModal = () => {
        this.setState({
            addonModalVisible: false
        })
    };

    showupgradeConfirmationModal = () => {
        this.setState({
            upgradeConfirmationModalVisible: true
        })
    };

    showCancelConfirmationModal = () => {
        this.setState({
            cancelConfirmationModalVisible: true
        })
    };

    hideUpgradeConfirmationModal = () => {
        this.setState({
            upgradeConfirmationModalVisible: false
        })
    };

    hideDowngradeConfirmationModal = () => {
        this.setState({
            downgradeConfirmationModalVisible: false
        })
    };

    hideCancelConfirmationModal = () => {
        this.setState({
            cancelConfirmationModalVisible: false
        })
    };

    confirmCancelation = () => {
        Axios.post(config.get('serverAPI').subscription.cancel, {
            "subscriptionId": localStorage.getItem("subscriptionId")
        })
            .then(Response => {
                this.hideCancelConfirmationModal();
                this.props.onLogout();
            })
            .catch(error => {
                this.hideCancelConfirmationModal();
            })
    };

    handleUpgrade = (plan) => {
        this.setState({
            upgradeConfirmationModalVisible: true,
            upgradePlan: plan
        });
    };

    handleDowngrade = (plan) => {
        this.setState({
            downgradeConfirmationModalVisible: true,
            downgradePlan: plan
        });
    };

    handleAddonSuccess = () => {
        this.hideAddOnModal();
    };

    confirmUpgrade = () => {
        this.hideUpgradeConfirmationModal();
        this.setState({loading: true});
        Axios.post(config.get('serverAPI').subscription.upgrade, {
            "subscriptionId": localStorage.getItem("subscriptionId"),
            "plan": this.state.upgradePlan,
        })
            .then(Response => {
                if (this.props.plan === "Free") {
                    window.location.replace(Response.data.data.url);
                    return;
                }
                window.location.replace("/#/thanks");
            })
            .catch(error => {
                this.setState({loading: false});
            })
    };

    confirmDowngrade = () => {
        this.hideDowngradeConfirmationModal();
        this.setState({loading: true});
        Axios.post(config.get('serverAPI').subscription.downgrade, {
            "subscriptionId": localStorage.getItem("subscriptionId"),
            "plan": this.state.downgradePlan,
        })
            .then(Response => {
                // this.getData();
                window.location.replace("/#/thanks");
            })
            .catch(error => {
                this.setState({loading: false});
            })
    };

    calculateDowngrade = () => {
        if (this.state.downgradePlan === "Free") return this.calculateDowngradeToFree();
        else if (this.state.downgradePlan === "Professional") return this.calculateDowngradeToProfessional();
    };

    calculateDowngradeToFree = () => {
        const plans = this.state.pricing.plans;
        const current = this.state.planStats.current;
        const diff = {
            users: current.users - plans["Free"].allowed.users,
            employees: current.employees - plans["Free"].allowed.employees,
            devices: current.devices - plans["Free"].allowed.devices,
        };
        return {
            users: diff.users > 0 ? diff.users : 0,
            employees: diff.employees > 0 ? diff.employees : 0,
            devices: diff.devices > 0 ? diff.devices : 0,
        };
    };

    calculateDowngradeToProfessional = () => {
        const plans = this.state.pricing.plans;
        const current = this.state.planStats.current;
        const diff = {
            users: current.users - plans["Professional"].allowed.users,
            employees: current.employees - plans["Professional"].allowed.employees,
            devices: current.devices - plans["Professional"].allowed.devices,
        };
        return {
            users: diff.users > 0 ? diff.users : 0,
            employees: diff.employees > 0 ? diff.employees : 0,
            devices: diff.devices > 0 ? diff.devices : 0,
        }
    };

    calculateAddonsCount = () => {
        const plan = this.props.plan;
        const plansPrice = this.state.pricing.plans;
        let addons = {};
        if (plansPrice && plansPrice[plan]) {
            addons = {
                users: this.state.planStats.allowed.users - plansPrice[plan].allowed.users,
                employees: this.state.planStats.allowed.employees - plansPrice[plan].allowed.employees,
                devices: this.state.planStats.allowed.devices - plansPrice[plan].allowed.devices,
            };
            return addons;
        }
    };

    render() {
        return (
            <section>
                <Dimmer active={this.state.loading} inverted>
                    <Loader size='medium'>Loading</Loader>
                </Dimmer>
                {this.props.plan === "Enterprise" ? (
                    <div style={{display: "flex", alignItems: "center", padding: "2rem"}}>
                        <h3 style={{margin: 0}}>Need more? </h3>
                        <Button
                            icon="resize horizontal"
                            onClick={this.showAddonModal}
                            style={{marginLeft: "1rem"}}>Extend your subscription</Button>
                    </div>
                ) : null}

                <Card.Group itemsPerRow={3} style={{padding: "1.5rem"}}>
                    <Card color='orange'>
                        <Card.Content style={{textAlign: 'center'}}>
                            <Statistic color='orange'>
                                <Statistic.Value>
                                    <Icon style={{marginLeft: '0.5rem'}} name='user circle'/> <br/>
                                    <span>{this.state.planStats.current.employees}</span>
                                    <span style={{fontSize: '3rem'}}>/</span>
                                    <span style={{fontSize: '3rem'}}>{this.state.planStats.allowed.employees}</span>
                                </Statistic.Value>
                                <Statistic.Label>Employees</Statistic.Label>
                            </Statistic>
                        </Card.Content>
                        {this.calculateAddonsCount() && this.calculateAddonsCount().employees > 0 ? (
                            <Label
                                style={{margin: "0.5rem"}}
                                tag color="orange" attached='top right'>
                                + {this.calculateAddonsCount().employees}
                            </Label>
                        ) : null}
                    </Card>
                    <Card color='teal'>
                        <Card.Content style={{textAlign: 'center'}}>
                            <Statistic color='teal'>
                                <Statistic.Value>
                                    <Icon style={{marginLeft: '0.5rem'}} name='users'/> <br/>
                                    <span>{this.state.planStats.current.users}</span>
                                    <span style={{fontSize: '3rem'}}>/</span>
                                    <span style={{fontSize: '3rem'}}>{this.state.planStats.allowed.users}</span>
                                </Statistic.Value>
                                <Statistic.Label>Users</Statistic.Label>
                            </Statistic>
                        </Card.Content>
                        {this.calculateAddonsCount() && this.calculateAddonsCount().users > 0 ? (
                            <Label
                                style={{margin: "0.5rem"}}
                                tag color="teal" attached='top right'>
                                + {this.calculateAddonsCount().users}
                            </Label>
                        ) : null}
                    </Card>
                    <Card color='grey'>
                        <Card.Content style={{textAlign: 'center'}}>
                            <Statistic color='grey'>
                                <Statistic.Value>
                                    <Icon style={{marginLeft: '0.5rem'}} name='mobile'/> <br/>
                                    <span>{this.state.planStats.current.devices}</span>
                                    <span style={{fontSize: '3rem'}}>/</span>
                                    <span style={{fontSize: '3rem'}}>{this.state.planStats.allowed.devices}</span>
                                </Statistic.Value>
                                <Statistic.Label>Devices</Statistic.Label>
                            </Statistic>
                        </Card.Content>
                        {this.calculateAddonsCount() && this.calculateAddonsCount().devices > 0 ? (
                            <Label
                                style={{margin: "0.5rem"}}
                                tag color="grey" attached='top right'>
                                + {this.calculateAddonsCount().devices}
                            </Label>
                        ) : null}
                    </Card>
                </Card.Group>

                <div style={{padding: "2rem"}}>
                    <Plans
                        pricing={this.state.pricing}
                        onUpgrade={(plan) => this.handleUpgrade(plan)}
                        onDowngrade={(plan) => this.handleDowngrade(plan)}
                        onCancel={() => this.showCancelConfirmationModal()}
                        plan={this.props.plan}>
                    </Plans>
                </div>

                {this.state.addonModalVisible ? (
                    <AddonModal onSuccess={this.handleAddonSuccess} addons={this.state.pricing.addons}
                                onClose={this.hideAddOnModal}/>
                ) : null}

                {this.state.upgradeConfirmationModalVisible ? (
                    <UpgradeConfirmationModal
                        onConfirm={this.confirmUpgrade}
                        onClose={this.hideUpgradeConfirmationModal}
                        upgradePlan={this.state.upgradePlan}/>
                ) : null}

                {this.state.downgradeConfirmationModalVisible ? (
                    <DownGradeConfirmationModal
                        onConfirm={this.confirmDowngrade}
                        difference={this.calculateDowngrade()}
                        onClose={this.hideDowngradeConfirmationModal}
                        downgradePlan={this.state.downgradePlan}/>
                ) : null}

                {this.state.cancelConfirmationModalVisible ? (
                    <CancelConfirmationModal
                        onClose={this.hideCancelConfirmationModal}
                        onConfirm={this.confirmCancelation}
                    />
                ) : null}
            </section>
        )
    }
}

import React from 'react';
import { Modal, Dimmer, Loader, Button, Header, Input, Grid, Table } from 'semantic-ui-react';
import Axios from "axios";
import config from "react-global-configuration";
import AddonConfirmationModal from "./AddonConfirmationModal";
import { toast } from 'react-toastify';

export default class AddonModal extends React.Component {
    state = {
        count: {
            users: 0,
            employees: 0,
            devices: 0
        },
        summary: {
            cost: {
                users: 0,
                employees: 0,
                devices: 0
            },
            overallCost: 0,
        },
        prices: {},
        loading: false,
        addonConfirmationModalVisible: false,
    };

    componentDidMount() {
        this.setState({
            prices: {
                user: this.props.addons["Extra User"].price,
                employee: this.props.addons["Extra Employee"].price,
                device: this.props.addons["Extra Device"].price,
            }
        })
    }

    incrementCount = (prop, by) => {
        this.setState({
            count: {
                ...this.state.count,
                [prop]: this.state.count[prop] + by
            }
        })
    };

    updateSummary = (count) => {
        this.setState({
            summary: {
                cost: {
                    users: count.users * this.state.prices.user,
                    employees: count.employees * this.state.prices.employee,
                    devices: count.devices * this.state.prices.device,
                },
            }
        }, () => {
            const count = this.state.summary.cost;
            this.setState({
                summary: {
                    ...this.state.summary,
                    overallCost: count.employees + count.devices + count.users
                }
            })
        })
    };

    handleChange = (e, { name, value }) => {
        this.setState({
            count: {
                ...this.state.count,
                [name]: value
            }
        }, () => this.updateSummary(this.state.count));
    };

    applyAddOn = () => {
        this.setState({ addonConfirmationModalVisible: true })
    };

    prepareAddons = () => {
        const addons = [];
        if (this.state.count.employees > 0)
            addons.push({ addonName: "Extra Employee", quantity: this.state.count.employees })
        if (this.state.count.users > 0)
            addons.push({ addonName: "Extra User", quantity: this.state.count.users })
        if (this.state.count.devices > 0)
            addons.push({ addonName: "Extra Device", quantity: this.state.count.devices })
        
        return addons;
    };

    confirmPayment = () => {
        this.setState({ loading: true, addonConfirmationModalVisible: false });
        Axios.post(config.get('serverAPI').subscription.addon, {
            "subscriptionId": localStorage.getItem("subscriptionId"),
            addons: this.prepareAddons()
        })
            .then(Response => {
                this.setState({ loading: false });
                this.props.onSuccess();
                toast.success("Add-ons will be applied soon.")
            })
            .catch(error => {
                this.setState({ loading: false });
            })
    };

    render() {
        if (this.state.addonConfirmationModalVisible) {
            return (
                <AddonConfirmationModal
                    price={this.state.summary.overallCost}
                    onClose={() => this.setState({ addonConfirmationModalVisible: false })}
                    onConfirm={this.confirmPayment}
                />
            )
        } else {
            return (
                <Modal
                    open={true} onClose={this.props.onClose} closeIcon
                >
                    <Header icon='resize horizontal' content="Extend Your Subscription" />
                    <Modal.Content>
                        <Dimmer active={this.state.loading} inverted>
                            <Loader inverted content='Loading' />
                        </Dimmer>
                        <Grid>
                            <Grid.Row columns={2} style={{ alignItems: "center" }}>
                                <Grid.Column width={6}>
                                    <section className="addons">
                                        <div className="ui labeled input"
                                            style={{ display: "flex", marginBottom: "1rem" }}>
                                            <div style={{ width: "100px" }} className="ui label label">Users</div>
                                            <Input min={0} onChange={this.handleChange} name="users" type="number"
                                                value={this.state.count.users} />
                                        </div>
                                        <div className="ui labeled input"
                                            style={{ display: "flex", marginBottom: "1rem" }}>
                                            <div style={{ width: "100px" }} className="ui label label">Employees</div>
                                            <Input min={0} onChange={this.handleChange} name="employees" type="number"
                                                value={this.state.count.employees} />
                                        </div>
                                        <div className="ui labeled input"
                                            style={{ display: "flex", marginBottom: "1rem" }}>
                                            <div style={{ width: "100px" }} className="ui label label">Devices</div>
                                            <Input min={0} onChange={this.handleChange} name="devices" type="number"
                                                value={this.state.count.devices} />
                                        </div>
                                    </section>
                                </Grid.Column>
                                <Grid.Column width={10}>
                                    <section className="summary">
                                        <Table size="small">
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>Extension</Table.HeaderCell>
                                                    <Table.HeaderCell>Count</Table.HeaderCell>
                                                    <Table.HeaderCell>Cost</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>

                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.Cell>Users</Table.Cell>
                                                    <Table.Cell>{this.state.count.users}</Table.Cell>
                                                    <Table.Cell>{this.state.summary.cost.users} $</Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell>Employees</Table.Cell>
                                                    <Table.Cell>{this.state.count.employees}</Table.Cell>
                                                    <Table.Cell>{this.state.summary.cost.employees} $</Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell>Devices</Table.Cell>
                                                    <Table.Cell>{this.state.count.devices}</Table.Cell>
                                                    <Table.Cell>{this.state.summary.cost.devices} $</Table.Cell>
                                                </Table.Row>
                                            </Table.Body>

                                            <Table.Footer>
                                                <Table.Row>
                                                    <Table.HeaderCell style={{ fontWeight: "bold" }}>Total
                                                        Cost</Table.HeaderCell>
                                                    <Table.HeaderCell />
                                                    <Table.HeaderCell
                                                        style={{ fontWeight: "bold" }}>{this.state.summary.overallCost} $</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Footer>
                                        </Table>
                                    </section>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>


                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='red' onClick={this.props.onClose}>
                            Cancel
                        </Button>
                        <Button
                            disabled={this.state.summary.overallCost === 0}
                            color='blue' onClick={this.applyAddOn}>
                            Apply
                        </Button>
                    </Modal.Actions>
                </Modal>
            )
        }
    }
}
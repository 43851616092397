import './index.css'
import React, {Component} from 'react'
import {Button, Form, Icon, Pagination, Table, Dimmer, Loader, Dropdown, Modal, Label} from 'semantic-ui-react'
import config from 'react-global-configuration'
import axios from 'axios'
import download from 'downloadjs'
import printJS from 'print-js';
import Moment from 'react-moment';
import 'moment-timezone';
import moment from 'moment';
import {toast} from "react-toastify";
import {DatesRangeInput} from 'semantic-ui-calendar-react';

export default class AttendanceLogTable extends Component {
    state = {
        timeRangeModalVisible: false,
        tableData: [],
        meta: {},
        datesRange: '',
        loading: false,
        queryParams: {
            filter: {},
            query: null,
            sort: {
                operationTimestamp: -1
            },
            page: 0,
        },
        note: {
            active: false,
            id: '',
            text: '',
            loading: false
        }
    };

    componentDidMount() {
        document.title = 'AttendLab | Attendance Log';
        this.getData()
    }

    getData = () => {
        this.setState({loading: true});
        axios.get(config.get('serverAPI').attendanceLog.get, {params: this.state.queryParams})
            .then((response) => {
                this.setState({
                    tableData: response.data.data,
                    meta: response.data.meta,
                    loading: false
                });
            })
            .catch(error => {
            })
    };

    handlePaginationChange = (e, {activePage}) => this.setState({
        queryParams: {
            ...this.state.queryParams,
            page: activePage - 1
        }
    }, () => this.getData());


    handleSearchTextChange = (e, {name, value}) => this.setState({
        queryParams: {
            ...this.state.queryParams,
            [name]: value
        }
    });

    filterByOperation = (value) => {
        this.setState({
            queryParams: {
                ...this.state.queryParams,
                filter: value === null ? {} : {
                    ...this.state.queryParams.filter,
                    operation: value
                }
            }
        }, () => this.getData())
    };

    handleSubmit = () => {
        const {query} = this.state.queryParams;

        this.setState({
            queryParams: {
                ...this.state.queryParams,
                query: query
            }
        }, () => this.getData());
    };

    handleNotesChange = (e, {name, value}) => {
        this.setState({
            note: {
                id: name,
                text: value,
                active: true
            }
        })
    };

    clearNote = () => {
        this.setState({
            note: {
                id: '',
                text: '',
                active: false,
                loading: false
            }
        })
    };

    submitNote = () => {
        this.setState({note: {...this.state.note, loading: true}});
        axios.put(config.get('serverAPI').attendanceLog.get + '/' + this.state.note.id, {
            note: this.state.note.text
        })
            .then(response => {
                toast.success("Note modified successfully.");
                this.setState({note: {...this.state.note, loading: false}}, () => this.clearNote());
                this.getData();
            })
            .catch(error => {
                this.setState({note: {...this.state.note, loading: false}});
            })
    };

    exportToCSV = () => {
        axios.post(config.get('serverAPI').attendanceLog.export, null, {
            params: this.state.queryParams
        })
            .then(response => {
                download(config.get('exportLink') + '/assets/exports/' + response.data.data.path)
            })
    };

    exportToZohoPeople = () => {
        axios.post(config.get('serverAPI').attendanceLog.exportZohoPeople, null, {
            params: this.state.queryParams
        })
            .then(response => {
                download(config.get('exportLink') + '/assets/exports/' + response.data.data.path)
            })
    };

    exportToPdf = () => {
        axios.post(config.get('serverAPI').attendanceLog.exportPdf, null, {
            params: this.state.queryParams
        })
            .then(response => {
                download(config.get('exportLink') + '/assets/exports/' + response.data.data.path)
            })
    };

    printTable = () => {
        axios.post(config.get('serverAPI').attendanceLog.exportPdf, null, {
            params: this.state.queryParams
        })
            .then(response => {
                printJS(config.get('exportLink') + '/assets/exports/' + response.data.data.path)
            })
    };

    handleTimeRangeChange = (event, {name, value}) => {
        if (this.state.hasOwnProperty(name)) {
            this.setState({[name]: value});
        }
    };

    setTimeRange = () => {
        const dates = this.state.datesRange.split(' - ');
        // dateFormat='MM/DD/YYYY'  
        const startTime = {
            month: dates[0].split('/')[0],
            day: dates[0].split('/')[1],
            year: dates[0].split('/')[2],
        };
        let endTime = {};
        if (dates[1]) {
            endTime = {
                month: dates[1].split('/')[0],
                day: dates[1].split('/')[1],
                year: dates[1].split('/')[2],
            };
        }

        this.setState({
            queryParams: {
                ...this.state.queryParams,
                filter: {
                    ...this.state.queryParams.filter,
                    operationTimestamp: {
                        // start: moment.tz(`${startTime.month}/${startTime.day}/${startTime.year}`, 'MM/DD/YYYY', this.props.timezone).valueOf(),
                        // end: dates[1] ?
                        //     moment.tz(`${endTime.month}/${endTime.day}/${endTime.year}`, 'MM/DD/YYYY', this.props.timezone).valueOf()
                        //     :
                        //     moment.tz(`${startTime.month}/${startTime.day}/${startTime.year}`, 'MM/DD/YYYY', this.props.timezone).valueOf()
                        start: `${startTime.month}/${startTime.day}/${startTime.year}`,
                        end: dates[1] ?
                            `${endTime.month}/${endTime.day}/${endTime.year}`
                            :
                            `${startTime.month}/${startTime.day}/${startTime.year}`
                    }
                }
            }
        }, () => {
            this.setState({timeRangeModalVisible: false});
            this.getData();
        })
    };

    render() {
        return (
            <section id="attendance-log-table">
                <section className="table-actions">
                    <div className="search-box">
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Input
                                name='query'
                                fluid loading={this.state.loading}
                                onChange={this.handleSearchTextChange}
                                icon={{name: 'search'}}
                                placeholder='Search...'
                            />
                        </Form>
                    </div>

                    <div className="actions">
                        {this.props.plan !== "Free" && this.props.permissions.reports ? (
                            <React.Fragment>
                                <Dropdown
                                    text='Export to CSV'
                                    icon='file excel outline'
                                    floating
                                    labeled
                                    button
                                    className='icon'
                                >
                                    <Dropdown.Menu>
                                        {/* <Dropdown.Header icon='tags' content='Filter by tag' /> */}
                                        <Dropdown.Item onClick={this.exportToCSV}>
                                            Normal Export
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={this.exportToZohoPeople}>
                                            Zoho People Export
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                                <Button
                                    onClick={this.exportToPdf}
                                    content='Export to PDF'
                                    icon='file pdf outline'
                                />

                                <Button
                                    onClick={this.printTable}
                                    content='Print'
                                    icon='print'
                                />
                            </React.Fragment>
                        ) : null}

                        <Dropdown
                            closeOnEscape
                            text='Filter'
                            icon='filter'
                            floating
                            labeled
                            button
                            className='icon'
                        >
                            <Dropdown.Menu style={{left: 'auto', right: 0}}>
                                <Dropdown.Header style={{fontStyle: 'italic'}} content='Filter by Operation'/>
                                <Dropdown.Item style={{fontSize: '12px', color: 'green'}}
                                               onClick={() => this.filterByOperation('CHECK_IN')}>
                                    Check In
                                </Dropdown.Item>
                                <Dropdown.Item style={{fontSize: '12px', color: 'red'}}
                                               onClick={() => this.filterByOperation('CHECK_OUT')}>
                                    Check Out
                                </Dropdown.Item>
                                <Dropdown.Item style={{fontSize: '12px'}}
                                               onClick={() => this.filterByOperation(null)}>
                                    All
                                </Dropdown.Item>
                                <Dropdown.Header style={{fontStyle: 'italic'}} content='Filter by Date'/>
                                <Dropdown.Item style={{fontSize: '12px'}}
                                               onClick={() => this.setState({timeRangeModalVisible: true})}>
                                    Select Date Range
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        {this.state.timeRangeModalVisible ? (
                            <Modal
                                onClose={() => this.setState({timeRangeModalVisible: false})}
                                open={true}
                                size='tiny'>
                                <Modal.Header>
                                    Select Date Range
                                </Modal.Header>
                                <Modal.Content>
                                    <DatesRangeInput
                                        dateFormat='MM/DD/YYYY'
                                        onChange={this.handleTimeRangeChange}
                                        style={{width: '100%'}}
                                        name="datesRange"
                                        placeholder="From - To"
                                        value={this.state.datesRange}
                                        iconPosition="left"
                                        // onChange={this.handleChange}
                                    />
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button
                                        onClick={this.setTimeRange}
                                        icon='checkmark'
                                        labelPosition='right'
                                        content='Apply'
                                    />
                                </Modal.Actions>
                            </Modal>
                        ) : null}


                    </div>
                </section>
                {Object.keys(this.state.queryParams.filter).length > 0 ? (
                    <Label.Group color='blue' style={{marginTop: '1rem'}}>
                        {this.state.queryParams.filter.operationTimestamp ? (
                            <Label as='a'>
                                Date From:
                                <span style={{margin: '0 0.5rem'}}>
                                    {moment.tz(this.state.queryParams.filter.operationTimestamp.start, this.props.timezone).format('MM/DD/YYYY')}
                                </span>
                                to
                                <span style={{margin: '0 0.5rem'}}>
                                    {moment.tz(this.state.queryParams.filter.operationTimestamp.end, this.props.timezone).format('MM/DD/YYYY')}
                                </span>
                                <Icon name='close' onClick={() => {
                                    const filter = {
                                        ...this.state.queryParams.filter,
                                    };
                                    delete filter.operationTimestamp;
                                    this.setState({
                                        queryParams: {
                                            ...this.state.queryParams,
                                            filter: {
                                                ...filter
                                            }
                                        }
                                    }, () => this.getData())
                                }}/>
                            </Label>
                        ) : null}
                        {this.state.queryParams.filter.operation ? (
                            <Label as='a'>
                                {this.state.queryParams.filter.operation}
                                <Icon name='close' onClick={() => {
                                    const filter = {
                                        ...this.state.queryParams.filter,
                                    };
                                    delete filter.operation;
                                    this.setState({
                                        queryParams: {
                                            ...this.state.queryParams,
                                            filter: {
                                                ...filter
                                            }
                                        }
                                    }, () => this.getData())
                                }}/>
                            </Label>
                        ) : null}
                    </Label.Group>
                ) : null}
                <Table celled compact definition sortable>
                    <Dimmer active={this.state.loading} inverted>
                        <Loader size='medium'>Loading</Loader>
                    </Dimmer>
                    <Table.Header fullWidth>
                        <Table.Row>
                            <Table.HeaderCell>
                                Device Location
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Employee Location
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Employee
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Check
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Time
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Notes
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {this.state.tableData.map(row =>
                            <Table.Row key={row._id}>
                                <Table.Cell>{row.location.locationName}</Table.Cell>
                                <Table.Cell>{row.employee.location}</Table.Cell>
                                <Table.Cell>{row.employee.firstName + ' ' + row.employee.lastName}</Table.Cell>
                                <Table.Cell>{row.operation === 'CHECK_IN' ? (
                                    <div style={{display: "flex", justifyContent: "space-between"}}>
                                        <span>IN</span>
                                        <Icon color='green' style={{marginLeft: '0.5rem'}}
                                              name='arrow alternate circle left outline'>
                                        </Icon>
                                    </div>
                                ) : (
                                    <div style={{display: "flex", justifyContent: "space-between"}}>
                                        <span>OUT</span>
                                        <Icon color='red' style={{marginLeft: '0.5rem'}}
                                              name='arrow alternate circle right outline'>
                                        </Icon>
                                    </div>
                                )}</Table.Cell>
                                <Table.Cell>{
                                    <Moment
                                        format="DD MMM YYYY - HH:mm"
                                        fromNowDuring={5184000}
                                        tz={this.props.timezone}>
                                        {row.operationTimestamp}
                                    </Moment>
                                }</Table.Cell>
                                <Table.Cell>{
                                    row.notes ? (
                                            <Form onSubmit={this.submitNote}>
                                                <Form.Input
                                                    value={this.state.note.id === row._id ? this.state.note.text : row.notes}
                                                    disabled={(this.state.note.id !== row._id && this.state.note.active) || !this.props.permissions.update}
                                                    onChange={this.handleNotesChange}
                                                    name={row._id}
                                                    size='small'
                                                    icon={
                                                        this.state.note.id === row._id && this.state.note.text ? (
                                                            <Icon color='red' link onClick={this.clearNote}
                                                                  name='close'/>
                                                        ) : null
                                                    }
                                                    loading={this.state.note.loading}
                                                    placeholder="Click to add notes">
                                                </Form.Input>
                                            </Form>
                                        )
                                        :
                                        <Form onSubmit={this.submitNote}>
                                            <Form.Input
                                                disabled={(this.state.note.id !== row._id && this.state.note.active) || !this.props.permissions.update}
                                                onChange={this.handleNotesChange}
                                                name={row._id}
                                                size='small'
                                                icon={this.state.note.id === row._id && this.state.note.text ? (
                                                    <Icon link onClick={this.clearNote} name='close'/>
                                                ) : null
                                                }
                                                placeholder="Click to add notes">
                                            </Form.Input>
                                        </Form>
                                }</Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>

                {this.state.tableData.length === 0 && !this.state.loading ? (
                    <p style={{textAlign: 'center'}}>No Results!</p>
                ) : null}

                <section style={{display: 'flex', justifyContent: 'center'}}>
                    {this.state.meta.totalRecords >= this.state.meta.pageLimit ? (
                        <Pagination
                            onPageChange={this.handlePaginationChange}
                            defaultActivePage={this.state.meta.currentPage + 1}
                            totalPages={Math.ceil(this.state.meta.totalRecords / this.state.meta.pageLimit)}
                        />
                    ) : null}
                </section>
            </section>
        )
    }
}

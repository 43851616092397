import React, {Component} from 'react';
import {Dimmer, Icon, Loader} from "semantic-ui-react";
import Axios from 'axios';
import config from 'react-global-configuration';

export default class VerifyEmail extends Component {
    state = {
        token: '',
        email: '',
        loading: true,
        success: false,
        error: false,
    };

    componentDidMount() {
        const url = window.location.hash.split("?")[1];
        const urlParams = new URLSearchParams('?' + url);
        const token = urlParams.get('token');
        const email = urlParams.get('email');
        this.setState({
            token: token,
            email: email
        }, () => {
            this.verifyEmail();
        });
    }

    verifyEmail() {
        this.setState({loading: true});
        Axios.post(config.get("serverAPI").verify.get, {
            code: this.state.token,
            email: this.state.email
        })
            .then(response => {
                this.setState({loading: false, success: true});
            })
            .catch(err => {
                this.setState({loading: false, error: true});
            })
    }

    goBackToMainPage = () => {
        this.props.onClose();
        window.location.replace("/#/")
    };

    render() {
        return (
            <Dimmer active>
                {this.state.loading ? (
                    <Loader size='large'>Verifying your account...</Loader>
                ) : null}

                {this.state.success ? (
                    <div>
                        <Icon size='massive' color="green" name="check circle"/>
                        <h3>Your account has been verified.</h3>
                        <a
                            style={{textDecoration: "underline", color: "white", cursor: "pointer"}}
                            onClick={this.goBackToMainPage}>Back to Main Page</a>
                    </div>
                ): null}

                {this.state.error ? (
                    <div>
                        <Icon size='massive' color="red" name="exclamation triangle"/>
                        <h3>There was a problem verifying your account.</h3>
                        <a
                            href="#"
                            style={{textDecoration: "underline", color: "white", cursor: "pointer"}}
                            onClick={(e) => {e.preventDefault(); this.goBackToMainPage();}}>Back to Main Page</a>
                    </div>
                ) : null}
            </Dimmer>
        )
    }
}
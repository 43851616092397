import React, { Component } from 'react'
import { Grid, Statistic, Icon, Card, Dimmer, Loader, Segment } from 'semantic-ui-react';
import Axios from 'axios';
import config from 'react-global-configuration'
import { Bar, Doughnut, Pie } from 'react-chartjs-2';
import { DateInput } from 'semantic-ui-calendar-react';

export default class Dashboard extends Component {
    state = {
        data: {
            monthAttendance: []
        },
        monthAttendance: [],
        hourlyAttendance: [],
        loading: false,
        date: new Date()
    };

    componentDidMount() {
        document.title = 'AttendLab | Dashboard';
        this.getData();
    }

    getData = () => {
        this.setState({ loading: true });
        Axios.post(config.get('serverAPI').dashboard.get, { date: (new Date(this.state.date)).getTime() })
            .then(response => {
                const monthAttendance = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                const hourlyAttendance = [];
                for (const month in response.data.data.monthAttendance) {
                    monthAttendance[month - 1] = response.data.data.monthAttendance[month]
                }
                for (const hr in response.data.data.hourlyAttendance) {
                    hourlyAttendance.push(Math.round(response.data.data.hourlyAttendance[hr] * 100))
                }
                this.setState({
                    data: response.data.data,
                    monthAttendance: [...monthAttendance],
                    hourlyAttendance: hourlyAttendance
                });
                this.setState({ loading: false })
            })
            .catch(err => {
                this.setState({ loading: false })
            })
    };

    handleChange = (event, { name, value }) => {
        if (this.state.hasOwnProperty(name)) {
            this.setState({ [name]: value }, () => this.getData());
        }
    };

    append00ToHourlyAttendance = () => {
        const hours = Object.keys(this.state.data.hourlyAttendance);
        const newHours = [];
        for (const h of hours) {
            const arr = h.split(" - ");
            newHours.push(arr[0] + ":00" + " - " + arr[1] + ":00");
        }
        return newHours;
    };

    getMonthName = () => {
        const d = new Date(this.state.date);
        return d.toLocaleString('default', { month: 'short' });
    };

    getMonthNumbers = () => {
        const d = new Date(this.state.date);
        const max = new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate();
        return Array.from({ length: max }, (v, k) => (k + 1) + " " + this.getMonthName());
    };

    render() {
        return (
            <section id='stats'>
                <Dimmer active={this.state.loading} inverted>
                    <Loader size='medium'>Loading</Loader>
                </Dimmer>
                <section style={{ marginBottom: '1rem', display: "flex", alignItems: "center" }}>
                    <label style={{ marginRight: "0.5rem" }}>
                        Select Day
                    </label>
                    <DateInput
                        dateFormat='YYYY-MM-DD'
                        name="date"
                        placeholder="Date"
                        value={this.state.date}
                        iconPosition="left"
                        onChange={this.handleChange}
                    />
                </section>
                {/* <Card.Group itemsPerRow={4}>
                    <Card color='red'>
                        <Card.Content style={{ textAlign: 'center' }}>
                            <Statistic color='red'>
                                <Statistic.Value>
                                    <Icon style={{ display: "block" }} name='location arrow' />
                                    {this.state.data.locations}
                                </Statistic.Value>
                                <Statistic.Label>Locations</Statistic.Label>
                            </Statistic>
                        </Card.Content>
                    </Card>
                    <Card color='green'>
                        <Card.Content style={{ textAlign: 'center' }}>
                            <Statistic color='green'>
                                <Statistic.Value>
                                    <Icon style={{ display: "block" }} name='user circle' />
                                    {this.state.data.registerdEmployees}
                                </Statistic.Value>
                                <Statistic.Label>Registered Employees</Statistic.Label>
                            </Statistic>
                        </Card.Content>
                    </Card>
                    <Card color='blue'>
                        <Card.Content style={{ textAlign: 'center' }}>
                            <Statistic color='blue'>
                                <Statistic.Value>
                                    <Icon style={{ display: "block" }} name='users' />
                                    {this.state.data.checkedInEmployees}
                                </Statistic.Value>
                                <Statistic.Label>Present Today</Statistic.Label>
                            </Statistic>
                        </Card.Content>
                    </Card>
                    <Card color='black'>
                        <Card.Content style={{ textAlign: 'center' }}>
                            <Statistic color='black'>
                                <Statistic.Value>
                                    <Icon style={{ display: "block" }} name='mobile' />
                                    {this.state.data.activeDevices}
                                </Statistic.Value>
                                <Statistic.Label>active Devices</Statistic.Label>
                            </Statistic>
                        </Card.Content>
                    </Card>
                </Card.Group> */}

                <Grid columns={2} verticalAlign='middle'>
                    <Grid.Column width={6}>
                        <Grid columns={2} verticalAlign='middle'>
                            <Grid.Column width={8}>
                                <Card color='red'>
                                    <Card.Content style={{ textAlign: 'center' }}>
                                        <Statistic color='red'>
                                            <Statistic.Value>
                                                <Icon style={{ display: "block" }} name='location arrow' />
                                                {this.state.data.locations}
                                            </Statistic.Value>
                                            <Statistic.Label>Locations</Statistic.Label>
                                        </Statistic>
                                    </Card.Content>
                                </Card>
                                <Card color='green'>
                                    <Card.Content style={{ textAlign: 'center' }}>
                                        <Statistic color='green'>
                                            <Statistic.Value>
                                                <Icon style={{ display: "block" }} name='user circle' />
                                                {this.state.data.registerdEmployees}
                                            </Statistic.Value>
                                            <Statistic.Label>Employees</Statistic.Label>
                                        </Statistic>
                                    </Card.Content>
                                </Card>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <Card color='blue'>
                                    <Card.Content style={{ textAlign: 'center' }}>
                                        <Statistic color='blue'>
                                            <Statistic.Value>
                                                <Icon style={{ display: "block" }} name='users' />
                                                {this.state.data.checkedInEmployees}
                                            </Statistic.Value>
                                            <Statistic.Label>Present Today</Statistic.Label>
                                        </Statistic>
                                    </Card.Content>
                                </Card>
                                <Card color='black'>
                                    <Card.Content style={{ textAlign: 'center' }}>
                                        <Statistic color='black'>
                                            <Statistic.Value>
                                                <Icon style={{ display: "block" }} name='mobile' />
                                                {this.state.data.activeDevices}
                                            </Statistic.Value>
                                            <Statistic.Label>Active Devices</Statistic.Label>
                                        </Statistic>
                                    </Card.Content>
                                </Card>
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={10}>
                        <Card fluid>
                            <Card.Content>
                                <Card.Header>Hourly Attendance</Card.Header>
                                <Card.Meta>Attendance Hourly Distribution</Card.Meta>
                                <Card.Description>
                                    <Dimmer active={this.state.hourlyAttendance.length === 0} inverted>
                                        <p style={{ color: "#444" }}>
                                            Data will be displayed as soon as the employees check in.
                                </p>
                                    </Dimmer>
                                    <Bar options={{
                                        title: { display: false, text: 'Hourly Attendance' },
                                        tooltips: {
                                            callbacks: {
                                                label: function (tooltipItem, data) {
                                                    var label = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                                                    // console.log(data.datasets[tooltipItem.datasetIndex])
                                                    // console.log(tooltipItem, data)
                                                    label = "Attendance: " + label + "%";
                                                    return label;
                                                }
                                            }
                                        },
                                        scales: {
                                            yAxes: [{
                                                ticks: {
                                                    beginAtZero: true,
                                                    suggestedMax: 100,
                                                    callback: function (value, index) {
                                                        return value + "%"
                                                    }
                                                },
                                                scaleLabel: {
                                                    display: true,
                                                    labelString: 'Attendance Percentage %'
                                                }
                                            }],
                                            xAxes: [{
                                                scaleLabel: {
                                                    display: true,
                                                    labelString: 'Day Time'
                                                }
                                            }]
                                        }
                                    }} data={{
                                        labels: this.state.data.hourlyAttendance ? this.append00ToHourlyAttendance() : [],
                                        datasets: [{
                                            data: this.state.hourlyAttendance,
                                            label: 'Attendance',
                                            backgroundColor: 'rgba(255, 159, 67,0.75)',
                                            borderColor: "rgba(254, 202, 87,1.0)",
                                            borderWidth: 1
                                        }]
                                    }} />
                                </Card.Description>
                            </Card.Content>
                        </Card>

                    </Grid.Column>
                </Grid>

                <Card fluid>
                    <Card.Content>
                        <Card.Header>Monthly Attendance</Card.Header>
                        <Card.Meta>Attendance Monthly Distribution</Card.Meta>
                        <Card.Description>
                            <Dimmer active={this.state.monthAttendance.length === 0} inverted>
                                <p style={{ color: "#444" }}>
                                    Data will be displayed as soon as the employees check in.
                                </p>
                            </Dimmer>
                            <Bar options={{
                                title: { display: false, text: 'Monthly Attendance' },
                                scales: this.state.monthAttendance.length > 0 ? {
                                    xAxes: [{
                                        barPercentage: 0.5,
                                        barThickness: 15,
                                        maxBarThickness: 20,
                                        minBarLength: 2,
                                        gridLines: {
                                            offsetGridLines: true
                                        }
                                    }],
                                    yAxes: [{
                                        scaleLabel: {
                                            display: true,
                                            labelString: 'Number of Attended Employees'
                                        },
                                        ticks: {
                                            suggestedMax: 8,
                                            stepSize: 1,
                                            callback: function (value, index) {
                                                if (value > 0) return value;
                                            }
                                        }
                                    }]
                                } : {},
                            }} data={{
                                labels: this.getMonthNumbers(),
                                datasets: [{
                                    fill: false,
                                    data: this.state.monthAttendance,
                                    label: 'Present',
                                    backgroundColor: 'rgba(238, 82, 83, 0.45)',
                                    borderColor: "rgba(255, 107, 107,1.0)",
                                    borderWidth: 1
                                }]
                            }} />
                        </Card.Description>
                    </Card.Content>
                </Card>

            </section>
        )
    }
}

// const hours = ["0:00-1:00", "1:00-2:00", "2:00-3:00", "3:00-4:00", "4:00-5:00", "5:00-6:00", "6:00-7:00", "7:00-8:00", "8:00-9:00", "9:00-10:00", "10:00-11:00", "11:00-12:00", "12:00-13:00", "13:00-14:00", "14:00-15:00", "15:00-16:00", "16:00-17:00", "17:00-18:00", "18:00-19:00", "19:00-20:00", "20:00-21:00", "21:00-22:00", "22:00-23:00", "23:00-24:00"]
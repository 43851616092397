import React, {Component} from 'react'
import {Modal, Select, Button, Dimmer, Label, Loader} from 'semantic-ui-react'
import {addValidationRule} from "formsy-react"
import {Form} from 'formsy-semantic-ui-react'
import MapWithADrawingManager from './Map';
import Axios from 'axios';
import config from 'react-global-configuration';
import {toast} from 'react-toastify';

addValidationRule('isRequired', function (values, value) {
    return Boolean(value)
});

class AddLocationsModal extends Component {
    state = {
        location: {
            locationName: '',
            restrictionType: 'IP',
            ips: ['', ''],
            geolocation: {}
        },
        coordinates: '',
        locationSelected: false,
        mapVisible: false,
        loading: false
    };

    componentDidMount() {
        if (this.props.method === 'edit') {
            this.setState({
                loading: true, locationSelected: true,
            });
            Axios.get(config.get('serverAPI').locations.get + '/' + this.props.id)
                .then(res => {
                    this.setState({
                        location: {
                            restrictionType: res.data.data.restrictionMethod,
                            locationName: res.data.data.locationName,
                            ips: res.data.data.ips || ['', ''],
                            geolocation: res.data.data.geoLocation || {}
                        },
                    }, () => this.setState({loading: false, mapVisible: true}))
                })
        } else {
            this.setState({mapVisible: true});
        }
    }

    handleChange = (e, {name, value}) => {
        if (name.startsWith('ip')) {
            const ips = this.state.location.ips;
            name === 'ip1' ? ips[0] = value : ips[1] = value;
            this.setState({
                location: {
                    ...this.state.location,
                    ips: ips
                }
            });
            return
        }

        this.setState({
            location: {
                ...this.state.location,
                [name]: value
            }
        });
    };

    handleSelection = (polygon) => {
        this.setState({
                location: {
                    ...this.state.location,
                    geolocation: {
                        radius: polygon.getRadius(),
                        center: {
                            lat: polygon.getBounds().getCenter().lat(),
                            lng: polygon.getBounds().getCenter().lng()
                        }
                    }
                },
                locationSelected: true
            }, () => console.log(this.state.location.geolocation)
        )
    };

    handleAnchorSelection = (anchor) => {
        console.log(anchor)
    };

    handleLocationChange = (e, {name, value}) => {
        this.setState({
            location: {
                ...this.state.location,
                restrictionType: value
            }
        })
    };

    clearSelection = (e) => {
        e.preventDefault();
        this.setState({
            location: {
                ...this.state.location,
                geolocation: {}
            },
            locationSelected: false,
            mapVisible: false,
        }, () => {
            setTimeout(() => {
                this.setState({mapVisible: true})
            }, 100)
        })
    };

    addLocation = () => {
        if (this.state.location.restrictionType === 'GEO' && Object.keys(this.state.location.geolocation).length === 0)
            return;

        this.setState({loading: true});
        const objToPost = {
            locationName: this.state.location.locationName,
            restrictionMethod: this.state.location.restrictionType,
            ips: this.state.location.ips,
            geoLocation: this.state.location.geolocation
        };
        if (this.state.location.restrictionType === 'IP') delete objToPost.geoLocation;
        else if (this.state.location.restrictionType === 'GEO') delete objToPost.ips;
        else if (this.state.location.restrictionType === 'GEO_IP') {
        } else {
            delete objToPost.ips;
            delete objToPost.geoLocation
        }

        if (this.props.method === 'edit') {
            Axios.put(config.get('serverAPI').locations.get + '/' + this.props.id, objToPost)
                .then(response => {
                    this.setState({loading: false});
                    toast.success("Location modified successfully.");
                    this.props.onSuccess()
                })
                .catch(err => this.setState({loading: false}))
        } else {
            Axios.post(config.get('serverAPI').locations.get, objToPost)
                .then(response => {
                    this.setState({loading: false});
                    toast.success("Location added successfully.");
                    this.props.onSuccess()
                })
                .catch(err => this.setState({loading: false}))
        }
    };

    renderRestrictionInput = () => {
        const restrictionType = this.state.location.restrictionType;
        const errorLabel = <Label color="red" pointing/>;

        if (restrictionType === 'IP') {
            return (
                <Form.Group widths='equal'>
                    <Form.Input
                        value={this.state.location.ips[0]}
                        validations="isRequired"
                        validationErrors={{isRequired: 'Start Location IP is required.'}}
                        errorLabel={errorLabel}
                        fluid placeholder='Start IP' name='ip1' onChange={this.handleChange}
                    />
                    <Form.Input
                        value={this.state.location.ips[1]}
                        validations="isRequired"
                        validationErrors={{isRequired: 'End Location IP is required.'}}
                        errorLabel={errorLabel}
                        fluid placeholder='End IP' name='ip2' onChange={this.handleChange}
                    />
                </Form.Group>
            )
        }
        if (restrictionType === 'GEO') {
            return (
                <section id='map' style={{position: 'relative'}}>
                    <Dimmer
                        style={{opacity: 0.3}} active={this.state.locationSelected}
                        inverted/>
                    {this.state.mapVisible ? (
                        <MapWithADrawingManager
                            location={this.state.location.geolocation}
                            method={this.props.method}
                            handleAnchorSelection={this.handleAnchorSelection}
                            handleSelection={this.handleSelection}>
                        </MapWithADrawingManager>
                    ) : null}
                </section>
            )
        }
        if (restrictionType === 'GEO_IP') {
            return (
                <section>
                    <Form.Group widths='equal'>
                        <Form.Input
                            value={this.state.location.ips[0]}
                            validations="isRequired"
                            validationErrors={{isRequired: 'Start Location IP is required.'}}
                            errorLabel={errorLabel}
                            fluid placeholder='Start IP' name='ip1' onChange={this.handleChange}
                        />
                        <Form.Input
                            value={this.state.location.ips[1]}
                            validations="isRequired"
                            validationErrors={{isRequired: 'End Location IP is required.'}}
                            errorLabel={errorLabel}
                            fluid placeholder='End IP' name='ip2' onChange={this.handleChange}
                        />
                    </Form.Group>
                    <section id='map' style={{position: 'relative'}}>
                        <Dimmer
                            style={{opacity: 0.3}} active={this.state.locationSelected}
                            inverted/>
                        {this.state.mapVisible ? (
                            <MapWithADrawingManager
                                method={this.props.method}
                                location={this.state.location.geolocation}
                                handleAnchorSelection={this.handleAnchorSelection}
                                handleSelection={this.handleSelection}>
                            </MapWithADrawingManager>
                        ) : null}
                    </section>
                </section>
            )
        }

        return null
    };

    render() {
        const selectOptions = [
            {key: 'ip', text: 'IP Restriction', value: 'IP'},
            {key: 'no', text: 'No Restriction', value: 'NA'},
        ];
        if (this.props.plan !== "Free") {
            selectOptions.push({key: 'geolocation', text: 'Geolocation Restriction', value: 'GEO'});
            selectOptions.push({key: 'geolocation_ip', text: 'Geolocation & IP Restriction', value: 'GEO_IP'});
        }

        const errorLabel = <Label color="red" pointing/>;
        return (
            <Modal open={true} onClose={this.props.onClose} closeIcon>
                <Modal.Header>
                    {this.props.method === 'edit' ? 'Edit Location' : 'Add New Location'}
                </Modal.Header>
                <Modal.Content>
                    <Dimmer active={this.state.loading} inverted>
                        <Loader inverted content='Loading'/>
                    </Dimmer>
                    <Form id="add-location-form" onValidSubmit={this.addLocation}>
                        <Form.Input
                            value={this.state.location.locationName}
                            validations="isRequired"
                            validationErrors={{isRequired: 'Location name is required.'}}
                            errorLabel={errorLabel}
                            fluid placeholder='Location Name' name='locationName' onChange={this.handleChange}
                        />
                        <Form.Field>
                            <Select
                                onChange={this.handleLocationChange}
                                value={this.state.location.restrictionType}
                                options={selectOptions}/>
                        </Form.Field>
                        <Form.Field>
                            {this.renderRestrictionInput()}
                            {(this.state.location.restrictionType === "GEO" || this.state.location.restrictionType === "GEO_IP")
                            && (this.state.locationSelected) ? (
                                <div style={{marginTop: '1rem', display: 'flex'}}>
                                    <p style={{marginRight: '1rem'}}>Location Selected</p>
                                    <a onClick={this.clearSelection} style={{textDecoration: 'underline'}} href='#'>Clear
                                        Selection</a>
                                </div>
                            ) : null}
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={this.props.onClose}>Cancel</Button>
                    {
                        this.props.method === 'edit' ? (
                            <Button loading={this.state.loading} type='submit' form='add-location-form'
                                    color='blue'>Save</Button>
                        ) : (
                            <Button loading={this.state.loading} type='submit' form='add-location-form'
                                    color='blue'>Add</Button>
                        )
                    }
                </Modal.Actions>
            </Modal>
        )
    }
}

export default AddLocationsModal

import React from 'react'
import {Header, Modal, Button} from 'semantic-ui-react'
import QRCode from 'qrcode.react'

const QrModal = (props) => (
    <Modal open={true} closeIcon onClose={props.onClose} size='tiny'>
        <Header content='Scan QR code to authenticate'/>
        <Modal.Content style={{textAlign: 'center'}}>
            <QRCode value={props.value} size={256}/> <br />
            <Button
                onClick={() => window.open("https://play.google.com/store/apps/details?id=com.hayasolutions.AttendLab")}
                color="black"
                size="massive"
                style={{marginTop: "1rem"}}
                content='Get App Now' icon='google play' labelPosition='right'/>
        </Modal.Content>
    </Modal>
);

export default QrModal

import React, {Component} from 'react'
import {Button, Header, Icon, Modal} from 'semantic-ui-react'

class DeleteModal extends Component {
    state = {
        visible: true,
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.show) {
            this.setState({visible: nextProps.show})
        }
    }

    confirmDelete = () => {
        this.setState({visible: false});
        this.props.onConfirm();
    };

    render() {
        return (
            <Modal
                open={this.state.visible} basic size='small'
                closeOnDimmerClick={true} closeIcon onClose={this.props.onClose}
            >
                <Header icon='archive' content={'Delete ' + this.props.model}/>
                <Modal.Content>
                    <p>
                        Are you sure you want to delete selected {this.props.model}(s)?
                    </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='grey' basic onClick={this.props.onClose}>
                        <Icon name='remove'/> Cancel
                    </Button>
                    <Button color='red' inverted onClick={this.confirmDelete}>
                        <Icon name='archive'/> Yes, delete
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
};

export default DeleteModal;
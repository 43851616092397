import React, {Component} from 'react'
import {Button, Header, Icon, Modal} from 'semantic-ui-react'

class DeleteModal extends Component {
    confirmDelete = () => {
        this.props.onConfirm();
    };

    render() {
        return (
            <Modal
                open={true} basic size='small' onClose={this.props.onClose} closeIcon
            >
                <Header icon='archive' content='Delete Role'/>
                <Modal.Content>
                    <p>
                        Are you sure you want to delete selected role?
                    </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='grey' basic onClick={this.props.onClose}>
                        <Icon name='remove'/> Cancel
                    </Button>
                    <Button color='red' inverted onClick={this.confirmDelete}>
                        <Icon name='archive'/> Yes, delete
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
};

export default DeleteModal;
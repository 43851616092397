import React, {Component} from 'react'
import {Button, Header, Icon, Modal} from 'semantic-ui-react'

class DownGradeConfirmationModal extends Component {
    confirmUpgrade = () => {
        this.props.onConfirm();
    };

    render() {
        return (
            <Modal
                open={true} basic size='small' onClose={this.props.onClose} closeIcon
            >
                <Header icon='sort amount down' content={'Downgrade to ' + this.props.downgradePlan}/>
                <Modal.Content>
                    {
                        (this.props.difference.users !== 0 || this.props.difference.employees !== 0 || this.props.difference.devices !== 0) ? (
                            <p>
                                You need to
                                remove {this.props.difference.users} users, {this.props.difference.employees} employees
                                and {this.props.difference.devices} devices before you can downgrade
                                to {this.props.downgradePlan} plan.
                            </p>
                        ) : (
                            <React.Fragment>
                                <p>
                                    Are you sure you want to downgrade to <span
                                    style={{fontWeight: 'bold'}}>{this.props.downgradePlan}</span> plan
                                </p>
                                <p>
                                    Note: System will work on changing your subscription.
                                    During subscription changes you will not be able to login.
                                    A verification email will be sent to you once the subscription changes completed.
                                </p>
                            </React.Fragment>
                        )
                    }
                </Modal.Content>
                <Modal.Actions>
                    <Button color='grey' basic onClick={this.props.onClose}>
                        <Icon name='remove'/> Cancel
                    </Button>
                    {(this.props.difference.users !== 0 || this.props.difference.employees !== 0 || this.props.difference.devices !== 0) ? null : (
                        <Button color='red' inverted onClick={this.confirmUpgrade}>
                            <Icon name='arrow alternate circle down outline'/> Yes, downgrade
                        </Button>
                    )}
                </Modal.Actions>
            </Modal>
        );
    }
};

export default DownGradeConfirmationModal;
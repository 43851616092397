import React from 'react';
import {Button, Dimmer, Header, Label, Loader, Modal, TextArea} from "semantic-ui-react";
import {Form} from "formsy-semantic-ui-react";
import axios from 'axios';
import config from 'react-global-configuration';

export default class SupportModal extends React.Component {
    state = {
        loading: false,
        title: "",
        message: "",
        email: "",
        sent: false,
        disableEmail: false
    };

    componentDidMount() {
        if (localStorage.getItem("user_email")) {
            this.setState({email: localStorage.getItem("user_email"), disableEmail: true});
        }
    }

    handleChange = (e, {name, value}) => {
        this.setState({
            [name]: value
        });
    };

    sendMessage = () => {
        this.setState({loading: true});
        axios.post(config.get("serverAPI").support.get, {
            message: this.state.message,
            title: this.state.title,
            email: this.state.email
        })
            .then(response => {
                this.setState({loading: false, sent: true});
                setTimeout(() => {
                    this.props.onClose();
                }, 5000)
            })
            .catch(err => {
                this.setState({loading: false});
            })
    };

    render() {
        const errorLabel = <Label color="red" pointing/>;
        return (
            <Modal
                open={true} onClose={this.props.onClose} closeIcon
            >
                <Header icon='envelope' content="Contact Us"/>
                <Modal.Content>
                    <Dimmer active={this.state.loading} inverted>
                        <Loader inverted content='Loading'/>
                    </Dimmer>
                    {this.state.sent ? (
                        <h4 style={{textAlign: 'center'}}>
                            Your message has been sent successfully.
                        </h4>
                    ) : (
                        <Form id="support-form" onValidSubmit={this.sendMessage}>
                            {!this.state.disableEmail ? (
                                <Form.Input
                                    value={this.state.email}
                                    validations="isRequired,isEmail"
                                    validationErrors={{isRequired: 'Email is required', isEmail: 'Email not valid'}}
                                    errorLabel={errorLabel}
                                    fluid type='email' placeholder='Email' name='email'
                                    onChange={this.handleChange}/>
                            ) : null}
                            <Form.Input
                                value={this.state.title}
                                validations="isRequired"
                                validationErrors={{isRequired: 'Subject is required.'}}
                                errorLabel={errorLabel}
                                fluid placeholder='Subject' name='title' onChange={this.handleChange}
                            />
                            <Form.TextArea
                                value={this.state.message}
                                validations="isRequired"
                                validationErrors={{isRequired: 'Message is required.'}}
                                errorLabel={errorLabel}
                                control={TextArea}
                                placeholder='Write your message here...'
                                name='message' onChange={this.handleChange}
                            />
                        </Form>

                    )}
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={this.props.onClose}>
                        {this.state.sent ? 'Close' : 'Cancel'}
                    </Button>
                    {this.state.sent ? null : (
                        <Button color='blue' type="submit" form="support-form">
                            Send Message
                        </Button>
                    )}
                </Modal.Actions>
            </Modal>
        )
    }
}
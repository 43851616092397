import React, { Component } from 'react'
import LoginForm from '../Login'
import "./index.css"
import Register from '../Login/Register'

export default class HomePage extends Component {
    state = {
        login: {
            mode: "login"
        },
        plan: "",
        registerationMode: false
    };

    static getDerivedStateFromProps(props, state) {
        return {
            plan: props.registerationPlan || "Free",
            registerationMode: props.registerationMode || false
        }
    }

    handlePlanSelect = (plan) => {
        this.setState({
            plan: plan,
            registerationMode: true
        });
    };

    handleClose = () => {
        this.setState({ registerationMode: false });
        this.props.cancelReg();
    };

    render() {
        return (
            <section>
                {this.state.registerationMode ? (
                    <div style={{ marginTop: "5rem" }}>
                        <Register
                            onClose={this.handleClose}
                            plan={this.state.plan} />
                    </div>
                ) : (
                        <React.Fragment>
                            <LoginForm
                                onSubscribe={this.props.onSubscribe}
                                plan={this.state.plan}
                                onSuccess={this.props.onLoginSuccess} />
                        </React.Fragment>
                    )}
            </section>
        )
    }
}
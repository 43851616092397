import React from "react";
import { Header, Image, Segment } from "semantic-ui-react";
import mailPhoto from "./mail.png"
import loginPhoto from "./logo_Admin.png"

export default class ThanksPage extends React.Component {
    render() {
        return (
            <section style={{
                width: 600,
                margin: "auto",
                textAlign: "center",
                marginTop: 50,
            }}>
                <Image style={{ margin: "1.5rem auto" }} src={loginPhoto} />
                <Image size="medium" src={mailPhoto} style={{ margin: "auto" }} />
                <Header as='h2' style={{ color: "#4360BA", textTransform: "uppercase" }} textAlign='center'>
                    Thanks for your subscription
                            </Header>
                <h3>Please check your email to verify your account.</h3>
                <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'space-around' }}>
                    <a
                        onClick={(e) => {
                            e.preventDefault();
                            this.props.onClose()
                        }}
                        style={{ textDecoration: 'underline', color: '#444' }}
                        href='#'>Back to Home</a>
                </div>
            </section>
        )
    }
}
import React, { Component } from 'react'
import { Modal, Table, Button, Radio, Input, Dimmer, Loader, Icon, Popup } from 'semantic-ui-react'
import config from 'react-global-configuration'
import axios from 'axios'
import QrModal from './QrModal'
import randomString from 'randomstring'
import CannotAddModal from "../Employees/CannotAddModal";
import { toast } from "react-toastify";

export default class DevicesModal extends Component {
    state = {
        devices: [],
        qr: {
            modalVisible: false,
            value: ''
        },
        loading: false,
        newDevice: {
            active: false,
            data: {
                deviceName: '',
                bypassRestriction: false,
                maintainancePeriod: 0,
            }
        },
        editDevice: {
            indices: {},
        },
        canAddDevices: true,
        cannotAddModalVisible: false,
    };

    getData = () => {
        this.setState({ loading: true });
        axios.get(config.get('serverAPI').locations.get + '/' + this.props.id + '/devices')
            .then(response => {
                this.setState({
                    devices: response.data.data,
                    loading: false
                })
            })
    };

    getAllowed = (fn) => {
        this.setState({ loading: true });
        axios.get(config.get('serverAPI').subscription.stats)
            .then(response => {
                this.setState({
                    canAddDevices: response.data.data.device.allowed > response.data.data.device.count,
                }, () => fn())
            })
            .catch(err => {
                this.setState({ loading: false });
            })
    };

    componentDidMount() {
        this.getAllowed(() => {
            if (this.props.shouldAddNewDevice && this.state.canAddDevices) {
                this.setState({
                    newDevice: {
                        ...this.state.newDevice,
                        active: true
                    }
                }, () => {
                    document.getElementById('device-name').focus();
                    this.setState({ loading: false });
                })
            } else {
                this.getData();
            }
        })
    }

    resetAll = () => {
        this.setState({
            editDevice: {
                indices: {}
            }
        }, () => this.getData())
    };

    handleBypassRestrictionChange = (index) => {
        const devicesArray = [...this.state.devices];
        devicesArray[index].bypassRestriction = !devicesArray[index].bypassRestriction;
        this.setState({
            devices: devicesArray,
            editDevice: {
                indices: {
                    ...this.state.editDevice.indices,
                    [index]: true
                }
            }
        });
    };

    handleMaintainancePeriodChange = (index, e) => {
        const devicesArray = [...this.state.devices];
        devicesArray[index].maintainancePeriod = e.target.value;
        this.setState({
            devices: devicesArray,
            editDevice: {
                indices: {
                    ...this.state.editDevice.indices,
                    [index]: true
                }
            }
        });
    };

    setQrCode = (id) => {
        const qrValue = randomString.generate();
        this.setState({
            qr: {
                modalVisible: true,
                value: JSON.stringify({
                    code: qrValue,
                    deviceID: id
                })
            }
        }, () => {
            axios.post(config.get('serverAPI').locations.get + '/' + this.props.id + '/devices/' + id + '/set_qrcode ', {
                qrCode: qrValue
            }).then(response => {
                console.log(response)
            })
        })
    };

    closeQrModal = () => {
        this.setState({
            qr: {
                modalVisible: false,
                value: ''
            }
        })
    };

    addNewDevice = () => {
        if (this.state.canAddDevices) {
            this.setState({
                newDevice: {
                    ...this.state.newDevice,
                    active: !this.state.newDevice.active
                }
            }, () => {
                if (this.state.newDevice.active) document.getElementById('device-name').focus()
            })
        } else {
            this.setState({ cannotAddModalVisible: true });
        }
    };

    insertOneDevice = () => {
        this.setState({ loading: true });
        axios.post(config.get('serverAPI').locations.get + '/' + this.props.id + '/devices', this.state.newDevice.data)
            .then(response => {
                console.log(response);
                this.setState({
                    newDevice: {
                        active: false,
                        data: {
                            bypassRestriction: false,
                            deviceName: '',
                            maintainancePeriod: 0
                        }
                    }
                });
                toast.success("Device added successfully.");
                this.getData();
                this.props.onSuccess();
            })
            .catch(err => {
                this.setState({ loading: false });
            });
    };

    editOneDevice = (id, index) => {
        this.setState({ loading: true });
        axios.put(config.get('serverAPI').locations.get + '/' + this.props.id + '/devices/' + id, this.state.devices[index])
            .then(response => {
                const indices = { ...this.state.editDevice.indices };
                delete indices[index];
                toast.success("Device modified successfully.");
                this.setState({ loading: false, editDevice: { indices: indices } });
                this.props.onSuccess();
            })
            .catch(err => {
                this.setState({ loading: false });
            });
    };

    deleteOneDevice = (id) => {
        this.setState({ loading: true });
        axios.delete(config.get('serverAPI').locations.get + '/' + this.props.id + '/devices/' + id)
            .then(response => {
                toast.success("Device deleted successfully.");
                this.getData();
                this.props.onSuccess();
            })
            .catch(err => {
                this.setState({ loading: false });
            });
    };

    handleNewDeviceDataChange = (e, { name, value }) => {
        this.setState({
            newDevice: {
                ...this.state.newDevice,
                data: {
                    ...this.state.newDevice.data,
                    [name]: value
                }
            }
        });
    };

    handleNewDeviceBypassRestrictionChange = () => {
        this.setState({
            newDevice: {
                ...this.state.newDevice,
                data: {
                    ...this.state.newDevice.data,
                    bypassRestriction: !this.state.newDevice.data.bypassRestriction
                }
            }
        })
    };

    render() {
        if (!this.state.qr.modalVisible) {
            return (
                <Modal open={true} onClose={this.props.onClose} closeIcon>
                    <Modal.Header>Devices of Location "{this.props.locationName}"</Modal.Header>
                    <Modal.Content>
                        <Dimmer active={this.state.loading} inverted>
                            <Loader inverted content='Loading' />
                        </Dimmer>
                        <Table celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Device Name</Table.HeaderCell>
                                    <Table.HeaderCell style={{ textAlign: 'center' }}>Bypass
                                        Restrictions</Table.HeaderCell>
                                    <Table.HeaderCell style={{ textAlign: 'center' }}>Re-Authentication</Table.HeaderCell>
                                    <Table.HeaderCell style={{ textAlign: 'center' }}>Authentication</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <div className="actions"
                                            style={{ display: 'flex', justifyContent: 'space-around' }}>
                                            <Popup trigger={
                                                <Button
                                                    onClick={this.addNewDevice}
                                                    circular compact basic
                                                    color="black"
                                                    icon={this.state.newDevice.active ? 'undo' : 'add'} />
                                            } content={this.state.newDevice.active ? "Undo" : "Add Device"} size='mini' />
                                        </div>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {this.state.devices.map((row, index) =>
                                    <Table.Row disabled={this.state.newDevice.active} key={row._id}>
                                        <Table.Cell>{row.deviceName || 'No Name'}</Table.Cell>
                                        <Table.Cell style={{ textAlign: 'center' }}>
                                            <Radio
                                                disabled={this.state.newDevice.active}
                                                onClick={() => this.handleBypassRestrictionChange(index)}
                                                checked={row.bypassRestriction} toggle />
                                        </Table.Cell>
                                        <Table.Cell style={{ textAlign: 'center' }}>
                                            <span style={{ marginRight: "0.5rem" }}>Every</span>
                                            <Input
                                                disabled={this.state.newDevice.active}
                                                min={0}
                                                onChange={(e) => this.handleMaintainancePeriodChange(index, e)}
                                                style={{ width: '80px' }} type='number' value={row.maintainancePeriod}
                                                size='small' />
                                            <span style={{ marginLeft: "0.5rem" }}>Month(s)</span>
                                        </Table.Cell>
                                        <Table.Cell style={{ textAlign: 'center' }}>
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "space-around",
                                                alignItems: "center"
                                            }}>
                                                {row.authenticated ? (
                                                    <Icon
                                                        size="large" name="check" color="green"
                                                    />
                                                ) : (
                                                        <Icon
                                                            size="large" name="close" color="red"
                                                        />
                                                    )}
                                                <Popup trigger={
                                                    <Button
                                                        disabled={this.state.newDevice.active}
                                                        onClick={() => this.setQrCode(row._id)}
                                                        size='huge' style={{ padding: '0.3rem' }} icon='qrcode' basic
                                                    />
                                                } content="Authenticate" size='mini' />
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div className="actions"
                                                style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                {this.state.editDevice.indices[index] ? (
                                                    <Popup trigger={
                                                        <Button
                                                            disabled={this.state.newDevice.active}
                                                            onClick={() => this.editOneDevice(row._id, index)}
                                                            circular compact basic
                                                            color='blue' icon='check' />
                                                    } content="Save" size='mini' />
                                                ) : (
                                                        <Popup trigger={
                                                            <Button
                                                                disabled={this.state.newDevice.active}
                                                                onClick={() => this.deleteOneDevice(row._id)}
                                                                circular compact basic
                                                                color='red' icon='trash alternate' />
                                                        } content="Delete" size='mini' />
                                                    )}
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                )}

                                {this.state.newDevice.active ? (
                                    <Table.Row>
                                        <Table.Cell style={{ textAlign: 'center' }}>
                                            <Input id="device-name" onChange={this.handleNewDeviceDataChange}
                                                name='deviceName' fluid placeholder='Device Name' />
                                        </Table.Cell>
                                        <Table.Cell style={{ textAlign: 'center' }}>
                                            <Radio onChange={this.handleNewDeviceBypassRestrictionChange}
                                                name='bypassRestriction' toggle />
                                        </Table.Cell>
                                        <Table.Cell style={{ textAlign: 'center' }}>
                                            <span style={{ marginRight: "0.5rem" }}>Every</span>
                                            <Input
                                                onChange={this.handleNewDeviceDataChange}
                                                name='maintainancePeriod'
                                                style={{ width: '80px' }}
                                                type='number' size='small' />
                                            <span style={{ marginLeft: "0.5rem" }}>Month(s)</span>
                                        </Table.Cell>
                                        <Table.Cell style={{ textAlign: 'center' }}>
                                            <Button
                                                size='huge' style={{ padding: '0.3rem' }} icon='qrcode' basic disabled
                                            >
                                            </Button>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div className="actions"
                                                style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                <Popup trigger={
                                                    <Button
                                                        onClick={this.insertOneDevice}
                                                        circular compact basic
                                                        color='green' icon='check' />
                                                } content="Add" size='mini' />
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                ) : null}
                            </Table.Body>
                        </Table>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            icon='refresh' color='red' content='Refresh' onClick={this.resetAll} />
                        {/* <Button
                            disabled={this.state.newDevice.active || Object.keys(this.state.editDevice.indices).length > 0}
                            icon='check' color='blue' content='Apply' onClick={this.applyChanges} /> */}
                    </Modal.Actions>

                    {this.state.cannotAddModalVisible ? (
                        <CannotAddModal
                            onClose={() => this.setState({ cannotAddModalVisible: false })}
                            plan={this.props.plan}
                            unitType="devices"
                        />
                    ) : null}
                </Modal>
            )
        }
        return (
            <QrModal onClose={this.closeQrModal} value={this.state.qr.value} />
        )
    }
}

import './index.css'
import React, { Component } from 'react'
import { Button, Form, Icon, Pagination, Table, Dimmer, Loader, Popup } from 'semantic-ui-react'
import config from 'react-global-configuration'
import axios from 'axios'
import DevicesModal from './DevicesModal';
import AddLocationsModal from './AddLocationModal'
import DeleteModal from '../Employees/DeleteModal';
import { toast } from "react-toastify";

export default class LocationsTable extends Component {
    state = {
        tableData: [],
        meta: {},
        loading: false,
        queryParams: {
            filter: null,
            query: null,
            sort: {},
            page: 0
        },
        devicesModalVisible: false,
        locationID: '',
        locationName: '',
        addLocationModalVisible: false,
        shouldAddNewDevice: false,
        deleteLocation: {
            modalVisible: false,
            id: ''
        },
        addOrEdit: 'add',
        editID: '',
    };

    getData = () => {
        this.setState({ loading: true });
        axios.get(config.get('serverAPI').locations.get, { params: this.state.queryParams })
            .then((response) => {
                this.setState({
                    tableData: response.data.data,
                    meta: response.data.meta,
                    loading: false
                });
            })
            .catch(error => {

            })
    };

    componentDidMount() {
        document.title = 'AttendLab | Locations';
        this.getData();
    }

    handleSearchTextChange = (e, { name, value }) => this.setState({
        queryParams: {
            ...this.state.queryParams,
            [name]: value
        }
    });

    handleSubmit = () => {
        const { query } = this.state.queryParams;

        this.setState({
            queryParams: {
                ...this.state.queryParams,
                query: query
            }
        }, () => this.getData());
    };

    handlePaginationChange = (e, { activePage }) => this.setState({
        queryParams: {
            ...this.state.queryParams,
            page: activePage - 1
        }
    }, () => this.getData());

    handleSort = (clickedColumn) => {
        const sortDirection = this.state.queryParams.sort[clickedColumn];
        if (sortDirection) {
            if (sortDirection === 1) {
                this.setState({
                    queryParams: {
                        ...this.state.queryParams,
                        sort: {
                            [clickedColumn]: -1
                        }
                    }
                }, () => {
                    this.getData()
                })
            } else {
                this.setState({
                    queryParams: {
                        ...this.state.queryParams,
                        sort: {
                            [clickedColumn]: 1
                        }
                    }
                }, () => {
                    this.getData()
                })
            }
        } else {
            this.setState({
                queryParams: {
                    ...this.state.queryParams,
                    sort: {
                        [clickedColumn]: 1
                    }
                }
            }, () => {
                this.getData()
            })
        }
    };

    sortDirection = (clickedColumn) => {
        const sortDirection = this.state.queryParams.sort[clickedColumn];
        if (sortDirection) {
            if (sortDirection === 1) return 'ascending';
            else if (sortDirection === -1) return 'descending';
        }
        return null
    };

    deleteEmployee = (id) => {
        this.setState({
            deleteEmployee: {
                modalVisible: true,
                id: id
            }
        });
    };

    deleteLocation = (id) => {
        this.setState({
            deleteLocation: {
                modalVisible: true,
                id: id
            }
        })
    };

    confirmDelete = () => {
        this.setState({ loading: true });
        axios.delete(config.get('serverAPI').locations.get + '/' + this.state.deleteLocation.id)
            .then(() => {
                toast.success("Location deleted successfully.");
                this.setState({ loading: false, deleteLocation: { modalVisible: false } }, () => this.getData())
            })
            .catch(() => {
                this.setState({ loading: false, deleteLocation: { modalVisible: false } }, () => this.getData())
            })
    };

    editLocation = (id) => {
        this.setState({
            addOrEdit: 'edit',
            editID: id,
            addLocationModalVisible: true
        })
    };

    showDevices = (id, name, addNew) => {
        this.setState({
            locationID: id,
            locationName: name,
            devicesModalVisible: true,
            shouldAddNewDevice: addNew
        })
    };

    handleSuccess = () => {
        this.setState({
            devicesModalVisible: false,
        });
        this.getData();
    };

    closeDevicesModal = () => {
        this.setState({ devicesModalVisible: false, });
    };

    render() {
        return (
            <section id="locations-table">
                <section className="table-actions">
                    <div className="search-box">
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Input
                                name='query'
                                fluid loading={this.state.loading}
                                onChange={this.handleSearchTextChange}
                                icon={{ name: 'search' }}
                                placeholder='Search...'
                            />
                        </Form>
                    </div>

                    <div className="actions">
                        {this.props.permissions.create ? (
                            <Button icon
                                onClick={() => this.setState({ addLocationModalVisible: true, addOrEdit: 'add' })}>
                                <Icon name='map marker alternate' />
                                <span style={{marginLeft: "0.5rem"}}>Add Location</span>
                            </Button>
                        ) : null}
                    </div>
                </section>

                <Table celled compact definition sortable>
                    <Dimmer active={this.state.loading} inverted>
                        <Loader size='medium'>Loading</Loader>
                    </Dimmer>
                    <Table.Header fullWidth>
                        <Table.Row>
                            <Table.HeaderCell
                                sorted={this.sortDirection('locationName')}
                                onClick={() => this.handleSort('locationName')}
                            >
                                Location
                            </Table.HeaderCell>
                            <Table.HeaderCell style={{ textAlign: 'center' }}>
                                Devices
                            </Table.HeaderCell>
                            <Table.HeaderCell colSpan='2' style={{ textAlign: 'center' }}>
                                Restriction Method
                            </Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {this.state.tableData.map(row =>
                            <Table.Row key={row._id}>
                                <Table.Cell>{row.locationName}</Table.Cell>
                                <Table.Cell style={{ textAlign: 'center' }}>
                                    {row.devices.length === 0 ? (
                                        <Popup trigger={
                                            <Button
                                                color="grey"
                                                onClick={() => this.showDevices(row._id, row.locationName, true)}
                                                size='massive' style={{ padding: '0.3rem', width: "40px" }} basic
                                            >
                                                <Icon.Group>
                                                    <Icon name='mobile' />
                                                    <Icon corner name='add' />
                                                </Icon.Group>
                                            </Button>
                                        } content='Add Device(s)' size='mini' />
                                    ) : (
                                            <Popup trigger={
                                                <Button
                                                    color="black"
                                                    onClick={() => this.showDevices(row._id, row.locationName, false)}
                                                    size='massive' style={{ padding: '0.3rem', width: "40px" }} icon='mobile' basic
                                                >
                                                </Button>
                                            } content='Show/Edit Devices' size='mini' />
                                        )}
                                </Table.Cell>
                                <Table.Cell
                                    style={{ textAlign: 'center' }}>{row.restrictionMethod + ' Restriction'}</Table.Cell>
                                {this.props.permissions.delete || this.props.permissions.update ? (
                                    <Table.Cell className='cell-actions' collapsing textAlign='right'>
                                        <div className="actions"
                                            style={{ display: 'flex', justifyContent: 'space-around' }}>
                                            {this.props.permissions.update ? (
                                                <Popup trigger={
                                                    <Button circular compact basic icon='edit'
                                                        onClick={() => this.editLocation(row._id)} />
                                                } content='Edit Location' size='mini' />
                                            ) : null}
                                            {this.props.permissions.delete ? (
                                                <Popup trigger={
                                                    <Button
                                                        onClick={() => this.deleteLocation(row._id)}
                                                        circular compact basic
                                                        color='red' icon='trash alternate' />
                                                } content='Delete Location' size='mini' />
                                            ) : null}
                                        </div>
                                    </Table.Cell>
                                ) : null}
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>

                {this.state.tableData.length === 0 && !this.state.loading ? (
                    <p style={{ textAlign: 'center' }}>No Results!</p>
                ) : null}

                <section style={{ display: 'flex', justifyContent: 'center' }}>
                    {this.state.meta.totalRecords >= this.state.meta.pageLimit ? (
                        <Pagination
                            onPageChange={this.handlePaginationChange}
                            defaultActivePage={this.state.meta.currentPage + 1}
                            totalPages={Math.ceil(this.state.meta.totalRecords / this.state.meta.pageLimit)}
                        />
                    ) : null}
                </section>

                {
                    this.state.devicesModalVisible ? (
                        <DevicesModal
                            onSuccess={() => this.getData()}
                            shouldAddNewDevice={this.state.shouldAddNewDevice}
                            locationName={this.state.locationName} id={this.state.locationID}
                            onClose={this.closeDevicesModal} />
                    ) : null
                }

                {
                    this.state.addLocationModalVisible ? (
                        <AddLocationsModal
                            plan={this.props.plan}
                            id={this.state.editID}
                            method={this.state.addOrEdit}
                            onSuccess={() => this.setState({ addLocationModalVisible: false }, () => this.getData())}
                            onClose={() => this.setState({ addLocationModalVisible: false })} />
                    ) : null
                }

                {this.state.deleteLocation.modalVisible ? (
                    <DeleteModal model='location' onConfirm={this.confirmDelete}
                        onClose={() => this.setState({ deleteLocation: { modalVisible: false } })} />
                ) : null}
            </section>
        )
    }
}

import React from "react"
import { compose, withProps, lifecycle } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Circle } from "react-google-maps"
import DrawingManager from "react-google-maps/lib/components/drawing/DrawingManager";
import SearchBox from "react-google-maps/lib/components/places/SearchBox";
import _ from 'lodash'
import { Form } from "semantic-ui-react";

const TorontoLocation = {
    lat: 43.6532, lng: -79.384293
};

const MyMapComponent = compose(
    withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyAF38j0KGvqD7SqGhqKk0wbcrnHtUAhcnI&libraries=geometry,drawing,places",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    lifecycle({
        componentWillMount() {
            if (this.props.method === "edit" && this.props.location.center) {
                this.setState({
                    center: {
                        lat: this.props.location.center.lat,
                        lng: this.props.location.center.lng
                    }
                })
            }
            else {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(pos => {
                        this.setState({
                            center: {
                                lng: pos.coords.longitude,
                                lat: pos.coords.latitude
                            }
                        })
                    }, () => {
                        this.setState({
                            center: TorontoLocation
                        })
                    })
                } else {
                    this.setState({
                        center: TorontoLocation
                    })
                }
            }
        },
        componentDidMount() {
            const refs = {};
            this.setState({
                bounds: null,
                markers: this.props.method === "edit" && this.props.location.center ? [
                    {
                        position: {
                            lat: this.props.location.center.lat,
                            lng: this.props.location.center.lng
                        }
                    }
                ] : [],
                onMapMounted: ref => {
                    refs.map = ref;
                },
                onBoundsChanged: () => {
                    this.setState({
                        bounds: refs.map.getBounds(),
                        center: refs.map.getCenter(),
                    }, () => {
                        if (this.props.method === "edit" && this.props.location.center) {
                            const bounds = refs.circle.getBounds();
                            refs.map.fitBounds(bounds);
                        }
                    });
                },
                onSearchBoxMounted: ref => {
                    refs.searchBox = ref;
                },
                onCircleMounted: ref => {
                    refs.circle = ref;
                },
                onPlacesChanged: () => {
                    const places = refs.searchBox.getPlaces();
                    const bounds = new window.google.maps.LatLngBounds();

                    places.forEach(place => {
                        if (place.geometry.viewport) {
                            bounds.union(place.geometry.viewport)
                        } else {
                            bounds.extend(place.geometry.location)
                        }
                    });
                    const nextMarkers = places.map(place => ({
                        position: place.geometry.location,
                    }));
                    const nextCenter = _.get(nextMarkers, '0.position', this.state.center);

                    this.setState({
                        center: nextCenter,
                        markers: nextMarkers,
                    });
                    refs.map.fitBounds(bounds);
                },
            })
        },
    }),
    withScriptjs,
    withGoogleMap
)(props =>
    <GoogleMap
        ref={props.onMapMounted}
        zoom={8}
        center={props.center}
        onBoundsChanged={props.onBoundsChanged}
    >
        {props.method === "edit" ? (
            <Circle
                ref={props.onCircleMounted}
                defaultCenter={props.method === "edit" && props.location.center ? {
                    lat: (props.location.center.lat),
                    lng: (props.location.center.lng)
                } : null}
                radius={props.location.radius}
            />
        ) : null}
        <SearchBox
            ref={props.onSearchBoxMounted}
            bounds={props.bounds}
            controlPosition={window.google.maps.ControlPosition.BOTTOM_CENTER}
            onPlacesChanged={props.onPlacesChanged}
        >
            <Form.Input
                type="text"
                placeholder="Search for places..."
            />
        </SearchBox>
        <DrawingManager
            defaultOptions={{
                drawingControl: true,
                drawingControlOptions: {
                    position: window.google.maps.ControlPosition.TOP_CENTER,
                    drawingModes: [
                        window.google.maps.drawing.OverlayType.CIRCLE
                    ],
                }
            }}
            drawingMode='circle'
            onRectangleComplete={(polygon) => props.handleSelection(polygon)}
            onCircleComplete={(polygon) => props.handleSelection(polygon)}
            onPolygonComplete={(polygon) => props.handleSelection(polygon)}
            onPolylineComplete={(polygon) => props.handleSelection(polygon)}
            onMarkerComplete={(polygon) => props.handleAnchorSelection(polygon)}
        />
        {props.markers.map((marker, index) =>
            <Marker
                key={index} position={marker.position} />
        )}
    </GoogleMap>
);

export default MyMapComponent
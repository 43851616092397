import React, { Component } from 'react'
import { Button, Popup, Table, Dimmer, Loader, Pagination, Icon } from 'semantic-ui-react'
import config from 'react-global-configuration'
import axios from 'axios'
import './index.css'
import AddRole from './AddRole';
import DeleteModal from './DeleteModal';
import { toast } from "react-toastify";

export default class RolesTable extends Component {
    state = {
        tableData: [],
        meta: {},
        loading: false,
        queryParams: {
            filter: null,
            query: null,
            sort: {},
            page: 0
        },
        addRoleModalVisible: false,
        addOrEdit: 'add',
        editID: '',
        deleteRole: {
            modalVisible: false,
            id: ''
        }
    };

    getData = () => {
        this.setState({ loading: true });
        axios.get(config.get('serverAPI').roles.get, { params: this.state.queryParams })
            .then((response) => {
                this.setState({
                    tableData: response.data.data,
                    meta: response.data.meta,
                    loading: false
                });
            })
            .catch(error => {

            })
    };

    componentDidMount() {
        document.title = 'AttendLab | Roles';
        this.getData();
    }

    handlePaginationChange = (e, { activePage }) => this.setState({
        queryParams: {
            ...this.state.queryParams,
            page: activePage - 1
        }
    }, () => this.getData());

    editRole = (id) => {
        this.setState({
            addOrEdit: 'edit',
            editID: id,
            addRoleModalVisible: true
        })
    };

    deleteRole = (id) => {
        this.setState({
            deleteRole: {
                modalVisible: true,
                id: id
            }
        })
    };

    confirmDelete = () => {
        this.setState({ loading: true });
        axios.post(config.get('serverAPI').roles.delete, { ids: [this.state.deleteRole.id] })
            .then(() => {
                toast.success("Role deleted successfully.");
                this.setState({ deleteRole: { modalVisible: false } }, () => this.getData())
            })
            .catch(() => {
                this.setState({ loading: false, deleteRole: { modalVisible: false } }, () => this.getData())
            })
    };

    onSuccess = () => {
        this.setState({
            addRoleModalVisible: false
        }, () => this.getData())
    };

    render() {
        return (
            <section id="roles-table">
                <section className="table-actions">
                    <div className="actions" style={{ textAlign: 'right' }}>
                        {this.props.permissions.create ? (
                            <Popup trigger={
                                <Button icon onClick={() => this.setState({ addRoleModalVisible: true, addOrEdit: 'add' })}>
                                    <Icon name='add' />
                                </Button>
                            } content="Add Role" size='mini' />
                        ) : null}
                    </div>
                </section>

                <Table celled compact definition sortable>
                    <Dimmer active={this.state.loading} inverted>
                        <Loader size='medium'>Loading</Loader>
                    </Dimmer>
                    <Table.Header fullWidth>
                        <Table.Row>
                            <Table.HeaderCell width={2}>
                                Role Name
                            </Table.HeaderCell>
                            <Table.HeaderCell colSpan='2'>
                                Description
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {this.state.tableData.map(row =>
                            <Table.Row key={row._id}>
                                <Table.Cell>{row.roleName}</Table.Cell>
                                <Table.Cell>
                                    {row.description || <span style={{ color: 'grey' }}>No description</span>}
                                </Table.Cell>
                                {this.props.permissions.update || this.props.permissions.delete ? (
                                    <Table.Cell className='cell-actions' collapsing textAlign='right'>
                                        <div className="actions"
                                            style={{ display: 'flex', justifyContent: 'space-around' }}>
                                            {this.props.permissions.update ? (
                                                <Popup trigger={
                                                    <Button circular compact basic icon='edit'
                                                        onClick={() => this.editRole(row._id)} />
                                                } content='Edit Role' size='mini' />
                                            ) : null}
                                            {this.props.permissions.delete ? (
                                                <Popup trigger={
                                                    <Button
                                                        onClick={() => this.deleteRole(row._id)}
                                                        circular compact basic
                                                        color='red' icon='trash alternate' />
                                                } content='Delete Role' size='mini' />
                                            ) : null}
                                        </div>
                                    </Table.Cell>
                                ) : null}
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>

                {this.state.tableData.length === 0 && !this.state.loading ? (
                    <p style={{ textAlign: 'center' }}>No Results!</p>
                ) : null}

                <section style={{ display: 'flex', justifyContent: 'center' }}>
                    {this.state.meta.totalRecords >= this.state.meta.pageLimit ? (
                        <Pagination
                            onPageChange={this.handlePaginationChange}
                            defaultActivePage={this.state.meta.currentPage + 1}
                            totalPages={Math.ceil(this.state.meta.totalRecords / this.state.meta.pageLimit)}
                        />
                    ) : null}
                </section>

                {
                    this.state.addRoleModalVisible ? (
                        <AddRole
                            plan={this.props.plan}
                            method={this.state.addOrEdit}
                            id={this.state.editID}
                            onSuccess={this.onSuccess}
                            onClose={() => this.setState({ addRoleModalVisible: false })} />
                    ) : null
                }

                {this.state.deleteRole.modalVisible ? (
                    <DeleteModal model='role' onConfirm={this.confirmDelete}
                        onClose={() => this.setState({ deleteRole: { modalVisible: false } })} />
                ) : null}
            </section>
        )
    }
}

import React, { Component } from 'react';
import { Header, Modal, Table, Dimmer, Loader, Icon, Label } from 'semantic-ui-react';

import axios from 'axios';
import config from 'react-global-configuration';
import moment from 'moment';

export default class TimeSheetDetailsModal extends Component {
    state = {
        employee: {},
        tableData: [],
        loading: false,
    };

    componentDidMount() {
        console.log(`this.props.employeeId`, this.props.employeeId);
        this.getData();
    }

    getData = () => {
        this.setState({ loading: true });
        axios
            .get(`${config.get('serverAPI').timeSheet.get}/${this.props.employeeId}`, {
                params: {
                    startDate: this.props.startDate,
                    endDate: this.props.endDate,
                },
            })
            .then((response) => {
                console.log(
                    '🚀 ~ file: TimeSheetDetailsModal.js ~ line 27 ~ TimeSheetDetailsModal ~ .then ~ response',
                    response
                );
                this.setState({
                    employee: response.data.data.employee,
                    tableData: response.data.data.perDay,
                    loading: false,
                });
            })
            .catch((error) => {
                // this.setState({
                //     employee: empTimeSheet.data.employee,
                //     tableData: empTimeSheet.data.perDay,
                //     loading: false,
                // });
            });
    };
    render() {
        return (
            <Modal open={true} onClose={this.props.onClose} closeIcon size="large">
                <Header icon="calendar alternate outline" content={`${this.props.employeeName} TimeSheet Details`} />
                <Modal.Content>
                    <Dimmer active={this.state.loading} inverted>
                        <Loader inverted content="Loading" />
                    </Dimmer>
                    <Label.Group color="blue" style={{ marginTop: '1rem' }}>
                        <Label as="a">
                            Date From:
                            <span style={{ margin: '0 0.5rem' }}>
                                {moment(this.props.startDate).format('MM/DD/YYYY')}
                            </span>
                            to
                            <span style={{ margin: '0 0.5rem' }}>
                                {moment(this.props.endDate).format('MM/DD/YYYY')}
                            </span>
                        </Label>
                    </Label.Group>
                    <Table celled compact definition sortable>
                        <Table.Header fullWidth>
                            <Table.Row>
                                <Table.HeaderCell>Date</Table.HeaderCell>
                                <Table.HeaderCell>In</Table.HeaderCell>
                                <Table.HeaderCell>Out</Table.HeaderCell>
                                <Table.HeaderCell>Total Hours</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {this.state.tableData
                                .sort(
                                    (a, b) =>
                                        moment(`${a.year}-${a.month}-${a.day}`) -
                                        moment(`${b.year}-${b.month}-${b.day}`)
                                )
                                .map((day) => (
                                    <Table.Row key={day.attendance.firstCheckIn}>
                                        <Table.Cell>{`${day.day} ${moment(day.month, 'MM').format('MMMM')} ${
                                            day.year
                                        }`}</Table.Cell>
                                        <Table.Cell>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                {day.attendance.firstCheckIn === 0 ? (
                                                    <Label size="tiny" color="orange">
                                                        MISSED
                                                    </Label>
                                                ) : (
                                                    `${moment(day.attendance.firstCheckIn).format('HH:mm:ss')}`
                                                )}
                                                <Icon
                                                    color="green"
                                                    style={{ marginLeft: '0.5rem' }}
                                                    name="arrow alternate circle left outline"
                                                ></Icon>
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                {day.attendance.lastCheckOut === 0 ? (
                                                    <Label size="tiny" color="orange">
                                                        MISSED
                                                    </Label>
                                                ) : (
                                                    `${moment(day.attendance.lastCheckOut).format('HH:mm:ss')}`
                                                )}
                                                <Icon
                                                    color="red"
                                                    style={{ marginLeft: '0.5rem' }}
                                                    name="arrow alternate circle right outline"
                                                ></Icon>
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            {moment.utc(day.attendance.totalHours * 3600 * 1000).format('HH:mm:ss')}
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                        </Table.Body>
                    </Table>
                </Modal.Content>
            </Modal>
        );
    }
}

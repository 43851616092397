import React, {Component} from 'react'
import {Button, Header, Label, Modal, Select, Dimmer, Loader} from 'semantic-ui-react'
import {Form} from 'formsy-semantic-ui-react'
import {addValidationRule} from "formsy-react"
import axios from 'axios'
import config from 'react-global-configuration'
import timezoneLocations from "../../timezoneOptions.js"
import {CountryDropdown, RegionDropdown} from 'react-country-region-selector';
import postcode from 'postcode-validator';
import * as Formsy from 'formsy-react';
import {toast} from "react-toastify";

addValidationRule('isRequired', function (values, value) {
    return Boolean(value)
});

class AddModal extends Component {
    state = {
        loading: false,
        user: {
            "firstName": '',
            "lastName": '',
            "phone": '',
            "email": '',
            // "password": '',
            "role": null,
            "address": {
                "addressone": '',
                "addresstwo": '',
                "city": '',
                "state": '',
                "country": '',
                "zipcode": ''
            },
            timezone: ''
        },
        roles: []
    };

    componentDidMount() {
        this.setState({loading: true});
        axios.get(config.get('serverAPI').roles.filter)
            .then(response => {
                this.setState({
                    roles: response.data.data,
                }, () => {
                    if (this.props.method === "edit") {
                        axios.get(config.get('serverAPI').users.get + '/' + this.props.id)
                            .then(response => {
                                this.setState({
                                    user: response.data.data,
                                    loading: false
                                });
                            })
                    } else {
                        this.setState({loading: false})
                    }
                });
            });
    }

    handleChange = (e, {name, value}) => {
        this.setState({
            user: {
                ...this.state.user,
                [name]: value
            }
        });
    };

    handleRoleChange = (e, {name, value}) => {
        this.setState({
            user: {
                ...this.state.user,
                role: value
            }
        })
    };

    handleAddressChange = (e, {name, value}) => this.setState({
        user: {
            ...this.state.user,
            address: {
                ...this.state.user.address,
                [name]: value
            }
        }
    });

    handleTimezoneSelection = (e, {name, value}) => {
        this.setState({
            user: {
                ...this.state.user,
                timezone: value
            }
        });
    };

    selectUserCountry = (val) => {
        this.setState({
            user: {
                ...this.state.user,
                address: {
                    ...this.state.user.address,
                    country: val
                }
            }
        });
    };

    selectUserRegion = (val) => {
        this.setState({
            user: {
                ...this.state.user,
                address: {
                    ...this.state.user.address,
                    state: val
                }
            }
        });
    };

    insertOne = () => {
        this.setState({loading: true});
        axios.post(config.get('serverAPI').users.get, this.state.user)
            .then(() => {
                toast.success("User added successfully.");
                this.setState({loading: false});
                this.props.onSuccess();
            })
            .catch(error => {
                this.setState({loading: false});
            })
    };

    editOne = () => {
        this.setState({loading: true});
        const objToSend = {...this.state.user};
        // if (this.props.method === 'edit') delete objToSend.password;
        axios.put(config.get('serverAPI').users.get + '/' + this.props.id, objToSend)
            .then(response => {
                toast.success("User modified successfully.");
                this.setState({loading: false});
                this.props.onSuccess();
            })
            .catch(error => {
                this.setState({loading: false});
            })
    };

    render() {
        const roles = [];
        this.state.roles.forEach(role => {
            roles.push({
                key: role._id,
                text: role.roleName,
                value: role._id
            })
        });
        const timezoneOptions = timezoneLocations;
        const errorLabel = <Label color="red" pointing/>;

        Formsy.addValidationRule('isZipValid', (values, value) => {
            if (this.state.user.address.country) {
                try {
                    return postcode.validate(value, this.state.user.address.country);
                } catch (e) {
                    return true;
                }
            }
        });

        return (
            <Modal
                open={true} size='medium' onClose={this.props.onClose} closeIcon
            >
                <Header
                    icon={this.props.method === 'add' ? 'add user' : 'edit'}
                    content={this.props.method === 'add' ? 'Add User' : 'Edit User'}/>
                <Modal.Content>
                    <Dimmer active={this.state.loading} inverted>
                        <Loader inverted content='Loading'/>
                    </Dimmer>
                    <Form id="addForm" onValidSubmit={this.props.method === 'edit' ? this.editOne : this.insertOne}>
                        <Form.Group widths='equal'>
                            <Form.Input
                                label={this.props.method === 'edit' ? 'First Name' : null}
                                value={this.state.user.firstName}
                                validations="isRequired"
                                validationErrors={{isRequired: 'First name is required.'}}
                                errorLabel={errorLabel}
                                fluid placeholder='First name' name='firstName' onChange={this.handleChange}
                            />
                            <Form.Input
                                label={this.props.method === 'edit' ? 'Last Name' : null}
                                value={this.state.user.lastName}
                                validations="isRequired"
                                validationErrors={{isRequired: 'Last name is required.'}}
                                errorLabel={errorLabel}
                                fluid placeholder='Last name' name='lastName'
                                onChange={this.handleChange}/>
                        </Form.Group>
                        <Form.Input
                            label={this.props.method === 'edit' ? 'Phone' : null}
                            value={this.state.user.phone}
                            fluid placeholder='Phone' name='phone' onChange={this.handleChange}/>
                        <Form.Input
                            disabled={this.props.isSuperAdmin}
                            label={this.props.method === 'edit' ? 'Email' : null}
                            value={this.state.user.email}
                            validations="isRequired,isEmail"
                            validationErrors={{isRequired: 'Email is required', isEmail: 'Email not valid'}}
                            errorLabel={errorLabel}
                            fluid type='email' placeholder='Email' name='email'
                            onChange={this.handleChange}/>
                        <Form.Group widths='equal'>
                            <Form.Input
                                label={this.props.method === 'edit' ? 'Address Line 1' : null}
                                value={this.state.user.address.addressone}
                                fluid placeholder='Address Line 1' name='addressone'
                                onChange={this.handleAddressChange}/>
                            <Form.Input
                                label={this.props.method === 'edit' ? 'Address Line 2' : null}
                                value={this.state.user.address.addresstwo}
                                fluid placeholder='Address Line 2' name='addresstwo'
                                onChange={this.handleAddressChange}/>
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <CountryDropdown
                                valueType="short"
                                style={{marginLeft: "0.5rem", marginRight: "1.2rem"}}
                                value={this.state.user.address.country}
                                onChange={(val) => this.selectUserCountry(val)}
                                priorityOptions={["CA", "US", "GB"]}/>
                            <RegionDropdown
                                countryValueType="short"
                                style={{marginRight: "0.5rem"}}
                                country={this.state.user.address.country}
                                value={this.state.user.address.state}
                                onChange={(val) => this.selectUserRegion(val)}/>
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Input
                                label={this.props.method === 'edit' ? 'City' : null}
                                value={this.state.user.address.city}
                                fluid placeholder='City' name='city'
                                onChange={this.handleAddressChange}/>
                            <Form.Input
                                label={this.props.method === 'edit' ? 'Zip Code' : null}
                                value={this.state.user.address.zipcode}
                                fluid placeholder='Zip' name='zipcode'
                                onChange={this.handleAddressChange}/>
                        </Form.Group>
                        <label style={{fontWeight: 'bold'}}>Timezone</label>
                        <Select
                            value={this.state.user.timezone}
                            style={{marginBottom: '1rem'}}
                            onChange={this.handleTimezoneSelection}
                            placeholder="Select your area timezone" options={timezoneOptions}/>
                        {this.props.method !== 'edit' ? (
                            <div>
                                <strong>Role <br/></strong>
                                <Form.Group style={{marginLeft: '0.1rem', marginRight: '0.1rem'}}>
                                    <Select
                                        style={this.props.method === 'edit' ? {opacity: '0.8'} : {}}
                                        label={this.props.method === 'edit' ? 'Roles' : null}
                                        value={this.state.user.role || null}
                                        onChange={this.handleRoleChange}
                                        placeholder="Roles" options={roles}/>
                                </Form.Group>
                            </div>
                        ) : null}
                    </Form>
                    {/* <p>
                        {JSON.stringify(this.state)}
                    </p> */}
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={this.props.onClose}>
                        Cancel
                    </Button>
                    {
                        this.props.method === 'add' ? (
                            <Button color='blue' type="submit" form="addForm">
                                Add
                            </Button>
                        ) : (
                            <Button color='blue' type="submit" form="addForm">
                                Save
                            </Button>
                        )
                    }
                </Modal.Actions>
            </Modal>
        );
    }
}

export default AddModal;

import React, { Component } from 'react';
import { Label, Segment, Button, Loader, Dimmer, Grid, Image, Icon, Select, Message } from 'semantic-ui-react';
import { Form } from 'formsy-semantic-ui-react';
import axios from 'axios';
import config from 'react-global-configuration';
import ResetPasswordModal from './ResetPasswordModal';
import timezoneLocatons from '../timezoneOptions.js';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import postcode from 'postcode-validator';
import { addValidationRule } from 'formsy-react';
import { toast } from 'react-toastify';

export default class SettingsPage extends Component {
    state = {
        user: {
            _id: '',
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            role: {
                roleName: '',
            },
            timezone: '',
        },
        company: {
            companyName: '',
            faceGroupId: '',
            img: '',
            phone: '',
            fax: '',
            email: '',
            website: '',
            address: {
                addressone: '',
                addresstwo: '',
                city: '',
                country: '',
                state: '',
                zipcode: '',
            },
            enableLivenessDetection: false,
        },
        loadingUserSettings: false,
        loadingCompanySettings: false,
        imageSelected: true,
        resetPasswordModalVisible: false,
        countryChanged: false,
        imageError: '',
        imageDataUrl: '',
    };

    componentDidMount() {
        document.title = 'AttendLab | Settings';
        this.getUserData(() => {
            this.getCompanyData();
        });
    }

    getUserData = (cb) => {
        this.setState({ loadingUserSettings: true });
        axios
            .get(config.get('serverAPI').users.me)
            .then((response) => {
                this.setState(
                    {
                        user: response.data.data,
                        loadingUserSettings: false,
                    },
                    () => {
                        if (cb) cb();
                    }
                );
            })
            .catch((error) => {});
    };

    getCompanyData = () => {
        this.setState({ loadingCompanySettings: true });
        axios
            .get(config.get('serverAPI').companies.get + '/' + this.state.user.company._id)
            .then((response) => {
                this.setState(
                    {
                        company: response.data.data,
                        loadingCompanySettings: false,
                    },
                    () => {
                        this.setState({ imageDataUrl: this.state.company.img });
                    }
                );
            })
            .catch((error) => {});
    };

    handleChange = (e, { name, value }) => {
        this.setState({
            user: {
                ...this.state.user,
                [name]: value,
            },
        });
    };

    handleCompanyChange = (e, { name, value }) => {
        this.setState({
            company: {
                ...this.state.company,
                [name]: value,
            },
        });
    };

    handleAddressChange = (e, { name, value }) =>
        this.setState({
            company: {
                ...this.state.company,
                address: {
                    ...this.state.company.address,
                    [name]: value,
                },
            },
        });

    handleLivenessDetectionChange = (e, { name, value }) =>
        this.setState(
            {
                company: {
                    ...this.state.company,

                    [name]: !value,
                },
            },
            () =>
                console.log('🚀 ~ file: index.js ~ line 132 ~ SettingsPage ~ company', name, value, this.state.company)
        );

    selectCompanyCountry = (val) => {
        this.setState(
            {
                countryChanged: true,
            },
            () =>
                this.setState({
                    company: {
                        ...this.state.company,
                        address: {
                            ...this.state.company.address,
                            country: val,
                        },
                    },
                })
        );
    };

    selectCompanyRegion = (val) => {
        this.setState({
            company: {
                ...this.state.company,
                address: {
                    ...this.state.company.address,
                    state: val,
                },
            },
        });
    };

    handleTimezoneSelection = (e, { name, value }) => {
        this.setState({
            user: {
                ...this.state.user,
                timezone: value,
            },
        });
    };

    saveUserSettings = () => {
        this.setState({ loadingUserSettings: true });
        axios
            .put(config.get('serverAPI').users.get + '/' + this.state.user._id, this.state.user)
            .then(() => {
                toast.success('User settings modified successfully.');
                this.setState({ loadingUserSettings: false });
                this.props.onChange();
            })
            .catch((err) => {
                this.setState({ loadingUserSettings: false });
            });
    };

    saveCompanySettings = () => {
        if (!this.state.imageError) {
            const formData = new FormData();
            for (let key in this.state.company) {
                if (key === 'address') {
                    formData.append(key, JSON.stringify(this.state.company[key]));
                    continue;
                }
                formData.append(key, this.state.company[key]);
            }

            this.setState({ loadingCompanySettings: true });
            axios({
                method: 'put',
                url: config.get('serverAPI').companies.get + '/' + this.state.user.company._id,
                data: formData,
                config: { headers: { 'Content-Type': 'multipart/form-data' } },
            })
                .then(() => {
                    toast.success('Company settings modified successfully.');
                    this.getCompanyData();
                    this.setState({ loadingCompanySettings: false });
                    this.props.onChange();
                })
                .catch(() => {
                    this.setState({ loadingCompanySettings: false });
                });
        }
    };

    selectImage = () => {
        document.getElementById('embedpollfileinput').click();
    };

    handleImageSelection = (e) => {
        const reader = new FileReader();
        const file = e.target.files[0];

        this.setState({
            imageError: this.checkImageSize(file.size) ? 'Image size cannot be  bigger than 2MB.' : '',
        });

        reader.readAsDataURL(file);
        reader.onload = () => {
            this.setState({
                company: {
                    ...this.state.company,
                    img: file,
                },
                imageSelected: true,
                imageDataUrl: reader.result,
            });
        };
    };

    checkImageSize = (size) => {
        const twoMbs = 2 * 1024 * 1024;
        return size / twoMbs > 1;
    };

    render() {
        const errorLabel = <Label color="red" pointing />;
        const timezoneOptions = timezoneLocatons;
        addValidationRule('isZipValid', (values, value) => {
            if (this.state.company.address.country.length === 2) {
                try {
                    return postcode.validate(value, this.state.company.address.country);
                } catch (e) {
                    return true;
                }
            }
        });
        return (
            <section id="settings-page">
                <Segment>
                    <Dimmer active={this.state.loadingUserSettings} inverted>
                        <Loader inverted content="Loading" />
                    </Dimmer>
                    <h3>User Settings</h3>
                    <Form id="user-settings-form" onValidSubmit={this.saveUserSettings}>
                        <Form.Group widths="equal">
                            <Form.Input
                                label="First Name"
                                value={this.state.user.firstName}
                                validations="isRequired"
                                validationErrors={{ isRequired: 'First name is required.' }}
                                errorLabel={errorLabel}
                                fluid
                                placeholder="First name"
                                name="firstName"
                                onChange={this.handleChange}
                            />
                            <Form.Input
                                label="Last Name"
                                value={this.state.user.lastName}
                                validations="isRequired"
                                validationErrors={{ isRequired: 'Last name is required.' }}
                                errorLabel={errorLabel}
                                fluid
                                placeholder="Last name"
                                name="lastName"
                                onChange={this.handleChange}
                            />
                        </Form.Group>
                        <Form.Input
                            label="Phone"
                            value={this.state.user.phone}
                            fluid
                            placeholder="Phone"
                            name="phone"
                            onChange={this.handleChange}
                        />
                        <Form.Input
                            label="Email"
                            value={this.state.user.email}
                            validations="isEmail"
                            validationErrors={{ isEmail: 'Email is not valid.' }}
                            errorLabel={errorLabel}
                            fluid
                            placeholder="Email"
                            name="email"
                            onChange={this.handleChange}
                        />
                        <label style={{ fontWeight: 'bold' }}>Timezone</label>
                        <Select
                            value={this.state.user.timezone}
                            style={{ marginBottom: '1rem' }}
                            onChange={this.handleTimezoneSelection}
                            placeholder="Select your area timezone"
                            options={timezoneOptions}
                        />
                        {/* <Form.Input
                            value={this.state.user.role.roleName}
                            disabled
                            fluid name='role'
                        /> */}
                        <section className="actions" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({ resetPasswordModalVisible: true });
                                }}
                                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                            >
                                Reset Password
                            </a>
                            <Button color="blue" type="submit">
                                Save Changes
                            </Button>
                        </section>
                    </Form>
                </Segment>

                {this.state.user.role.superAdmin ? (
                    <Segment>
                        <Dimmer active={this.state.loadingCompanySettings} inverted>
                            <Loader inverted content="Loading" />
                        </Dimmer>
                        <h3>Company Settings</h3>
                        <Grid>
                            <Grid.Column computer={10}>
                                <Form id="company-settings-form" onValidSubmit={this.saveCompanySettings}>
                                    <Form.Input
                                        label="Company Name"
                                        value={this.state.company.companyName}
                                        validations="isRequired"
                                        validationErrors={{ isRequired: 'Company Name is required.' }}
                                        errorLabel={errorLabel}
                                        fluid
                                        placeholder="Company Name"
                                        name="companyName"
                                        onChange={this.handleCompanyChange}
                                    />
                                    <Form.Input
                                        label="Phone"
                                        value={this.state.company.phone}
                                        fluid
                                        placeholder="Phone"
                                        name="phone"
                                        onChange={this.handleCompanyChange}
                                    />
                                    <Form.Input
                                        label="Fax"
                                        value={this.state.company.fax}
                                        fluid
                                        placeholder="Fax"
                                        name="fax"
                                        onChange={this.handleCompanyChange}
                                    />
                                    <Form.Input
                                        label="email"
                                        validations="isEmail"
                                        validationError="Email is not valid."
                                        value={this.state.company.email}
                                        fluid
                                        placeholder="email"
                                        name="email"
                                        onChange={this.handleCompanyChange}
                                    />
                                    <Form.Input
                                        label="Website"
                                        value={this.state.company.website}
                                        fluid
                                        placeholder="Website"
                                        name="website"
                                        onChange={this.handleCompanyChange}
                                    />
                                    <Form.Group widths="equal">
                                        <Form.Input
                                            label="Address Line 1"
                                            value={this.state.company.address.addressone}
                                            fluid
                                            placeholder="Address Line 1"
                                            name="addressone"
                                            onChange={this.handleAddressChange}
                                        />
                                        <Form.Input
                                            label="Address Line 2"
                                            value={this.state.company.address.addresstwo}
                                            fluid
                                            placeholder="Address Line 2"
                                            name="addresstwo"
                                            onChange={this.handleAddressChange}
                                        />
                                    </Form.Group>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <label style={{ fontWeight: 'bold', width: '100%' }}>Country</label>
                                        <label
                                            style={{
                                                fontWeight: 'bold',
                                                width: '100%',
                                                marginLeft: '1rem',
                                            }}
                                        >
                                            Region
                                        </label>
                                    </div>
                                    <Form.Group widths="equal">
                                        <CountryDropdown
                                            valueType="short"
                                            style={{ marginLeft: '0.5rem', marginRight: '1.2rem' }}
                                            value={this.state.company.address.country}
                                            onChange={(val) => this.selectCompanyCountry(val)}
                                            priorityOptions={['CA', 'US', 'GB']}
                                        />
                                        <RegionDropdown
                                            countryValueType="short"
                                            style={{ marginRight: '0.5rem' }}
                                            country={this.state.company.address.country}
                                            value={this.state.company.address.state}
                                            onChange={(val) => this.selectCompanyRegion(val)}
                                        />
                                    </Form.Group>
                                    <Form.Group widths="equal">
                                        <Form.Input
                                            label="City"
                                            value={this.state.company.address.city}
                                            fluid
                                            placeholder="City"
                                            name="city"
                                            onChange={this.handleAddressChange}
                                        />

                                        <Form.Input
                                            label="Zip Code"
                                            value={this.state.company.address.zipcode}
                                            fluid
                                            placeholder="Zip"
                                            name="zipcode"
                                            onChange={this.handleAddressChange}
                                        />
                                    </Form.Group>

                                    <Form.Checkbox
                                        label="Enable Liveness Detection"
                                        value={this.state.company.enableLivenessDetection}
                                        type="radio"
                                        name="enableLivenessDetection"
                                        onChange={this.handleLivenessDetectionChange}
                                    />
                                </Form>
                            </Grid.Column>
                            <Grid.Column
                                computer={6}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-around',
                                    alignItems: 'center',
                                }}
                            >
                                {!this.state.company.img ? (
                                    <Icon
                                        name="image"
                                        size="massive"
                                        color="red"
                                        style={{ textAlign: 'center', margin: 'auto' }}
                                    />
                                ) : (
                                    <Image
                                        id="company-img"
                                        size="medium"
                                        bordered
                                        rounded
                                        fluid
                                        src={this.state.imageDataUrl}
                                    />
                                )}
                                <Button
                                    style={{ marginTop: '0.5rem' }}
                                    onClick={this.selectImage}
                                    icon="image"
                                    content="Upload Image"
                                />
                                <input
                                    onChange={this.handleImageSelection}
                                    type="file"
                                    id="embedpollfileinput"
                                    style={{ display: 'none' }}
                                />
                                {this.state.imageError && this.state.imageSelected ? (
                                    <Message negative>
                                        <Message.Header>Image cannot be uploaded!</Message.Header>
                                        <p>{this.state.imageError}</p>
                                    </Message>
                                ) : null}
                            </Grid.Column>
                        </Grid>
                        <div style={{ textAlign: 'right', marginTop: '1rem' }}>
                            <Button color="blue" type="submit" form="company-settings-form">
                                Save Changes
                            </Button>
                        </div>
                    </Segment>
                ) : null}

                {this.state.resetPasswordModalVisible ? (
                    <ResetPasswordModal
                        onClose={() => this.setState({ resetPasswordModalVisible: false })}
                        id={this.state.user._id}
                    />
                ) : null}
            </section>
        );
    }
}

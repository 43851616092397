import React, {Component} from 'react'
import {Button, Header, Modal, Select, Dimmer, Loader} from 'semantic-ui-react'
import {Form} from 'formsy-semantic-ui-react'
import axios from 'axios'
import config from 'react-global-configuration'

class AssignRoleModal extends Component {
    state = {
        roles: [],
        selectedRole: null,
        loading: false
    };

    handleRoleChange = (e, {name, value}) => {
        this.setState({
            selectedRole: value,
        })
    };

    assingRole = () => {
        this.setState({loading: true});
        axios.post(config.get('serverAPI').users.get + '/' + this.props.id + '/assign_role', {
            role: this.state.selectedRole
        })
            .then(response => {
                this.setState({loading: false});
                this.props.onSuccess();
            })
            .catch(err => {
                this.setState({loading: false});
            })
    };

    componentDidMount() {
        axios.get(config.get('serverAPI').roles.filter, {params: this.state.queryParams})
            .then((response) => {
                this.setState({roles: response.data.data})
            })
            .catch(error => {

            })
    }

    render() {
        const roles = [];
        this.state.roles.forEach(role => {
            roles.push({
                key: role._id,
                text: role.roleName,
                value: role._id
            })
        });
        return (
            <Modal size='tiny' onClose={this.props.onClose} closeIcon open={true}>
                <Header>
                    Assign role to {this.props.username}
                </Header>
                <Modal.Content>
                    <Dimmer active={this.state.loading} inverted>
                        <Loader inverted content='Loading'/>
                    </Dimmer>
                    <Form id="assignRoleForm" onValidSubmit={this.assingRole}>
                        <Form.Group style={{marginLeft: '0.1rem', marginRight: '0.1rem'}}>
                            <Select
                                onChange={this.handleRoleChange}
                                placeholder="Roles" options={roles}/>
                        </Form.Group>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={this.props.onClose}>
                        Cancel
                    </Button>
                    <Button color='blue' type="submit" form="assignRoleForm">
                        Assign
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }
}

export default AssignRoleModal;
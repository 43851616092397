import './App.css';
import React, { Component } from 'react'
import AppSidebar from './components/Sidebar/Sidebar'
import Axios from 'axios';
import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HomepageLayout from './components/LandingPage';
import { HashRouter as Router } from "react-router-dom"
import { Button, Message, Icon } from "semantic-ui-react";
import SupportModal from "./components/SupportModal";
import VerifyEmail from "./components/Login/VerifyEmail";
import SetPassword from "./components/Login/SetPassword";
import ThanksPage from "./components/Login/ThanksPage";
import config from 'react-global-configuration';

Axios.interceptors.request.use(
    config => {
        config.headers.authorization = 'Bearer ' + localStorage.getItem("token");
        return config;
    },
    error => Promise.reject(error)
);

Axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error.response) {
        if (error.response.status === 401) {
            const app = document.getElementById('app');
            const event = new CustomEvent('auth401');
            app.dispatchEvent(event);
            toast.dismiss();
            toast("Your login session has expired. Pleas login in again.");
        } else if (error.response.status === 469) {
            const app = document.getElementById('app');
            const event = new CustomEvent('auth469');
            app.dispatchEvent(event);
            toast.dismiss();
            toast("Your account hasn't been activated yet! Please check the activation email we sent to you and click on the activation button.");
        } else if (error.response.status === 403) {
            toast("You don't have a permission to do this action.");
        } else if (error.response.data.data) {
            toast.error(error.response.data.data);
        }
    }
    return Promise.reject(error);
});

export default class App extends Component {
    state = {
        loggedIn: false,
        authError: null,
        supportModalVisible: false,
        cookiesMessageVisible: false,
        registerationMode: false,
        registerationPlan: "Free"
    };

    handleLoginSuccess = () => {
        this.setState({ loggedIn: true });
    };

    handleLogout = () => {
        document.title = 'AttendLab';
        localStorage.removeItem('token');
        this.setState({ loggedIn: false, authError: null }, () => {
            Axios.post(config.get("serverAPI").login.logout).then(() => {
            }).catch(() => {
            });
        });
        window.location.replace("#")
    };

    verifyPlan = (plan) => {
        const p = plan.toLocaleLowerCase();
        const options = ["free", "professional", "enterprise"];
        return options.includes(p);
    };

    componentDidMount() {
        document.title = 'AttendLab';

        if (window.location.hash.startsWith("#/subscribe")) {
            const url = window.location.hash.split("?")[1];
            const urlParams = new URLSearchParams('?' + url);
            const plan = urlParams.get('plan');
            if (this.verifyPlan(plan))
                this.setState({ registerationMode: true, registerationPlan: plan })
            else
                toast.error("You can't do this.");
        }

        if (localStorage.getItem('token')) {
            this.setState({ loggedIn: true })
        } else {
            this.setState({ loggedIn: false })
        }

        if (!localStorage.getItem("accept-cookies")) {
            this.setState({
                cookiesMessageVisible: true
            })
        }

        const app = document.getElementById('app');
        app.addEventListener('auth401', (e) => {
            this.handleLogout();
        });
        app.addEventListener('auth469', (e) => {
            this.handleLogout();
        });
    }

    acceptCookies = () => {
        localStorage.setItem("accept-cookies", "true");
        this.setState({ cookiesMessageVisible: false })
    };

    renderPage = () => {
        if (window.location.hash.startsWith("#/verify"))
            return <VerifyEmail onClose={() => this.handleLogout()} />;
        if (window.location.hash.startsWith("#/setPassword"))
            return <SetPassword onClose={() => this.handleLogout()} />;
        if (window.location.hash.startsWith("#/thanks"))
            return <ThanksPage onClose={() => this.handleLogout()} />;
        if (this.state.loggedIn) return <AppSidebar isForbidden={false} onLogout={this.handleLogout} />;
        return <HomepageLayout
            onSubscribe={() => this.setState({ registerationMode: true, registerationPlan: "Free" })}
            cancelReg={() => this.setState({ registerationMode: false })}
            registerationMode={this.state.registerationMode}
            registerationPlan={this.state.registerationPlan}
            onLoginSuccess={this.handleLoginSuccess} />
    };

    render() {
        return (
            <Router>
                <div id="app">
                    {this.renderPage()}
                    {this.state.loggedIn ? (
                        <Button
                            basic
                            color="red"
                            onClick={() => this.setState({ supportModalVisible: true })}
                            size='huge'
                            id="support-button" circular icon='help' />
                    ) : null}

                    {this.state.supportModalVisible ? (
                        <SupportModal
                            onClose={() => this.setState({ supportModalVisible: false })}
                        />
                    ) : null}

                    <ToastContainer
                        style={{ minWidth: "400px", textAlign: "center" }}
                        position="bottom-center"
                        autoClose={10000}
                        closeOnClick
                        pauseOnVisibilityChange
                        draggable
                        pauseOnHover
                        transition={Flip}
                    />
                    {this.state.cookiesMessageVisible ? (
                        <footer style={{ fontSize: "13px", position: "fixed", bottom: "2rem", zIndex: 900000 }}>
                            <Message
                                icon="warning"
                                style={{
                                    position: "relative",
                                    width: "80%",
                                    margin: "auto",
                                    textAlign: "center"
                                }}
                                warning content="This website uses cookies to improve your online experience. By continuing to use this website, you agree to our use of cookies. If you would like to, you can change your cookie settings at any time. Our Privacy Notice provides more information about what cookies we use." />
                            <a
                                onClick={(e) => { e.preventDefault(); this.acceptCookies(); }}
                                href="#" style={{
                                    position: "absolute",
                                    top: "-20px",
                                    right: "8%",
                                    fontSize: "1.5rem",
                                    cursor: "pointer",
                                    color: "saddlebrown"
                                }}>
                                <Icon name="close" />
                            </a>
                        </footer>
                    ) : null}
                </div>
            </Router>
        )
    }
}
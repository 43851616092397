import React from 'react';
import { Popup } from "semantic-ui-react";

function freeButton(plan) {
    if (plan) return "Downgrade";
    else return "Subscribe"
}

function professionalButton(plan) {
    if (plan) {
        if (plan === "Free") return "Upgrade";
        else return "Downgrade";
    } else return "Subscribe"
}

const Plans = (props) => {
    return (
        <div class="ui container">
            <div class="ui three columns stackable grid">
                <div class="column">
                    {/*<Dimmer active={props.plan && (props.plan === "Enterprise" || props.plan === "Professional")}*/}
                    {/*        inverted>*/}
                    {/*</Dimmer>*/}
                    <div class="ui segments plan">
                        <div class="ui top attached segment grey inverted plan-title">
                            {/* <span class="plan-ribbon yellow">Barato</span> */}
                            <span class="ui header">FREE</span>
                            {props.plan && props.plan === "Free" ? (
                                <span class="plan-ribbon yellow">Subscribed</span>
                            ) : null}
                        </div>
                        <div class="ui attached segment price">
                            <div class="amount">$0.00
                                <span style={{ fontSize: "1rem", marginLeft: "0.5rem", color: "darkgrey" }}>Monthly</span>
                            </div>
                            {props.pricing.plans ? (
                                <span style={{ color: "darkgrey" }}>
                                    ({props.pricing.plans["Free"].allowed.users} User -
                                    {props.pricing.plans["Free"].allowed.employees} Employees -
                                    {props.pricing.plans["Free"].allowed.devices} Device)
                                </span>
                            ) : null}
                        </div>
                        <div class="ui  attached segment feature">
                            <i class="icon green check" />
                            Android & iOS
                        </div>
                        <div class="ui  attached segment feature">
                            <i class="icon green check" />
                            Support
                        </div>

                        <div class="ui  attached segment feature">
                            <i class="icon red remove" />
                            GEO Restriction
                        </div>
                        <div className="ui  attached segment feature">
                            <i className="icon red remove" />
                            Add-On individual items
                        </div>
                        <div class="ui  attached segment feature">
                            <i class="icon red remove" />
                            Report Exporting (csv, pdf)
                        </div>
                        <div class="ui  attached segment feature">
                            <i class="icon red remove" />
                            Audit Log
                        </div>
                        <div class="ui  attached segment feature">
                            <i class="icon red remove" />
                            IP Restriction
                        </div>

                        {props.plan && props.plan === "Free" ? (
                            <a href="#" onClick={(e) => {
                                e.preventDefault();
                                props.onCancel()
                            }} class="ui bottom attached grey button btn-plan">
                                <i class="cancel icon" />
                                Cancel
                            </a>
                        ) : (
                                <a
                                    onClick={() => {
                                        if (props.plan) props.onDowngrade("Free");
                                        if (props.fromHome) props.onSelect("Free")
                                    }}
                                    class="ui bottom attached grey button btn-plan">
                                    <i class="cart icon" />
                                    {freeButton(props.plan)}
                                </a>
                            )}

                    </div>
                </div>
                <div class="column">
                    {/*<Dimmer active={props.plan && props.plan === "Enterprise"} inverted>*/}
                    {/*</Dimmer>*/}
                    <div class="ui segments plan">
                        <div class="ui top attached segment orange inverted plan-title">
                            <span class="ui header">PROFESSIONAL</span>
                            {props.plan && props.plan === "Professional" ? (
                                <span class="plan-ribbon yellow">Subscribed</span>
                            ) : null}
                        </div>
                        <div class="ui  attached segment price">
                            <div class="amount">$20.00
                                <span style={{ fontSize: "1rem", marginLeft: "0.5rem", color: "darkgrey" }}>Monthly</span>
                            </div>
                            {props.pricing.plans ? (
                                <span style={{ color: "darkgrey" }}>
                                    ({props.pricing.plans["Professional"].allowed.users} Users -
                                    {props.pricing.plans["Professional"].allowed.employees} Employees -
                                    {props.pricing.plans["Professional"].allowed.devices} Devices)
                                </span>
                            ) : null}
                        </div>
                        <div class="ui  attached segment feature">
                            <i class="icon green check" />
                            Android & iOS
                        </div>
                        <div class="ui  attached segment feature">
                            <i class="icon green check" />
                            Support
                        </div>

                        <div class="ui  attached segment feature">
                            <i class="icon green check" />
                            GEO Restriction
                        </div>

                        <div class="ui  attached segment feature">
                            <i class="icon green check" />
                            Report Exporting (csv, pdf)
                        </div>

                        <div class="ui  attached segment feature">
                            <i class="icon green check" />
                            IP Restriction
                        </div>
                        <div className="ui  attached segment feature">
                            <i className="icon red remove" />
                            Add-On individual items
                        </div>
                        <div class="ui  attached segment feature">
                            <i class="icon red remove" />
                            Audit Log
                        </div>

                        {props.plan && props.plan === "Professional" ? (
                            <a href="#" onClick={(e) => {
                                e.preventDefault();
                                props.onDowngrade("Free")
                            }} class="ui bottom attached orange button btn-plan">
                                <i class="cancel icon" />
                                Downgrade to Free
                            </a>
                        ) : (
                                <a
                                    onClick={() => {
                                        if (professionalButton(props.plan) === "Upgrade") props.onUpgrade("Professional");
                                        else if ((professionalButton(props.plan) === "Downgrade")) props.onDowngrade("Professional")
                                        if (props.fromHome) props.onSelect("Professional")
                                    }}
                                    class="ui bottom attached orange button btn-plan">
                                    <i class="cart icon" />
                                    {professionalButton(props.plan)}
                                </a>
                            )}

                    </div>
                </div>
                <div class="column">
                    <div class="ui segments plan">
                        <div class="ui top attached segment red inverted plan-title">
                            <span class="ui header">ENTERPRISE</span>
                            {props.plan && props.plan === "Enterprise" ? (
                                <span class="plan-ribbon yellow">Subscribed</span>
                            ) : null}
                        </div>
                        <div class="ui attached segment price">
                            <div class="amount" style={{ position: "relative" }}>$40.00
                            <Popup trigger={
                                    <span style={{ fontSize: "20px", position: "absolute", top: -10 }}>+</span>
                                } content="Plus add-ons cost" size='mini' />
                                <span style={{ fontSize: "1rem", marginLeft: "0.5rem", color: "darkgrey" }}>Monthly</span>
                            </div>
                            {props.pricing.plans ? (
                                <span style={{ color: "darkgrey" }}>
                                    ({props.pricing.plans["Enterprise"].allowed.users} Users -
                                    {props.pricing.plans["Enterprise"].allowed.employees} Employees -
                                    {props.pricing.plans["Enterprise"].allowed.devices} Devices)
                                </span>
                            ) : null}
                        </div>
                        <div class="ui  attached segment feature">
                            <i class="icon green check" />
                            Android & iOS
                        </div>
                        <div class="ui  attached segment feature">
                            <i class="icon green check" />
                            Support
                        </div>

                        <div class="ui  attached segment feature">
                            <i class="icon green check" />
                            GEO Restriction
                        </div>

                        <div class="ui  attached segment feature">
                            <i class="icon green check" />
                            Report Exporting (csv, pdf)
                        </div>
                        <div class="ui  attached segment feature">
                            <i class="icon green check" />
                            IP Restriction
                        </div>
                        <div className="ui  attached segment feature">
                            <i className="icon green check" />
                            Add-On individual items
                        </div>
                        <div class="ui  attached segment feature">
                            <i class="icon green check" />
                            Audit Log
                        </div>
                        {props.plan && props.plan === "Enterprise" ? (
                            <a href="#" onClick={(e) => {
                                e.preventDefault();
                                props.onDowngrade("Free")
                            }} class="ui bottom attached red button btn-plan">
                                <i class="cancel icon" />
                                Downgrade to Free
                            </a>
                        ) : (
                                <a
                                    onClick={() => {
                                        if (props.plan) props.onUpgrade("Enterprise");
                                        if (props.fromHome) props.onSelect("Enterprise")
                                    }}
                                    class="ui bottom attached red button btn-plan">
                                    <i class="cart icon" />
                                    {props.plan ? "Upgrade" : "Subscribe"}
                                </a>
                            )}

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Plans;
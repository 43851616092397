import './index.css'
import React, {Component} from 'react'
import {Button, Form, Pagination, Table, Dimmer, Loader, Dropdown, Modal, Label, Icon} from 'semantic-ui-react'
import config from 'react-global-configuration'
import axios from 'axios'
import download from 'downloadjs'
import Moment from 'react-moment';
import 'moment-timezone';
import printJS from 'print-js';
import {DatesRangeInput} from "semantic-ui-calendar-react";
import moment from "moment";

export default class AuditLogTable extends Component {
    state = {
        timeRangeModalVisible: false,
        datesRange: '',
        tableData: [],
        meta: {},
        loading: false,
        queryParams: {
            filter: null,
            query: null,
            sort: {
                operationTimestamp: -1
            },
            page: 0
        },
    };

    componentDidMount() {
        this.getData();
    }

    handleTimeRangeChange = (event, {name, value}) => {
        if (this.state.hasOwnProperty(name)) {
            this.setState({[name]: value});
        }
    };

    setTimeRange = () => {
        const dates = this.state.datesRange.split(' - ');
        // dateFormat='MM/DD/YYYY'
        const startTime = {
            month: dates[0].split('/')[0],
            day: dates[0].split('/')[1],
            year: dates[0].split('/')[2],
        };
        let endTime = {};
        if (dates[1]) {
            endTime = {
                month: dates[1].split('/')[0],
                day: dates[1].split('/')[1],
                year: dates[1].split('/')[2],
            };
        }

        this.setState({
            queryParams: {
                ...this.state.queryParams,
                filter: {
                    ...this.state.queryParams.filter,
                    operationTimestamp: {
                        // start: moment.tz(`${startTime.month}/${startTime.day}/${startTime.year}`, 'MM/DD/YYYY', this.props.timezone).valueOf(),
                        // end: dates[1] ?
                        //     moment.tz(`${endTime.month}/${endTime.day}/${endTime.year}`, 'MM/DD/YYYY', this.props.timezone).valueOf()
                        //     :
                        //     moment.tz(`${startTime.month}/${startTime.day}/${startTime.year}`, 'MM/DD/YYYY', this.props.timezone).valueOf()
                        start: `${startTime.month}/${startTime.day}/${startTime.year}`,
                        end: dates[1] ?
                            `${endTime.month}/${endTime.day}/${endTime.year}`
                            :
                            `${startTime.month}/${startTime.day}/${startTime.year}`
                    }
                }
            }
        }, () => {
            this.setState({timeRangeModalVisible: false});
            this.getData();
        })
    };

    getData = () => {
        String.prototype.toProperCase = function () {
            return this.replace(/\w\S*/g, function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
        };
        this.setState({loading: true});
        axios.get(config.get('serverAPI').auditLog.get, {params: this.state.queryParams})
            .then(response => {
                this.setState({
                    tableData: response.data.data,
                    meta: response.data.meta,
                    loading: false
                });
            })
            .catch(error => {
            })
    };

    handlePaginationChange = (e, {activePage}) => this.setState({
        queryParams: {
            ...this.state.queryParams,
            page: activePage - 1
        }
    }, () => this.getData());


    handleSearchTextChange = (e, {name, value}) => this.setState({
        queryParams: {
            ...this.state.queryParams,
            [name]: value
        }
    });

    filterByOperation = (e, {name, value}) => {
        this.setState({
            queryParams: {
                ...this.state.queryParams,
                filter: {
                    operation: value
                }
            }
        }, () => this.getData())
    };

    handleSubmit = () => {
        const {query} = this.state.queryParams;

        this.setState({
            queryParams: {
                ...this.state.queryParams,
                query: query
            }
        }, () => this.getData());
    };

    exportToCSV = () => {
        axios.post(config.get('serverAPI').auditLog.export, null, {
            params: this.state.queryParams
        })
            .then(response => {
                download(config.get('exportLink') + '/assets/exports/' + response.data.data.path)
            })
    };

    exportToPdf = () => {
        axios.post(config.get('serverAPI').auditLog.exportPdf, null, {
            params: this.state.queryParams
        })
            .then(response => {
                download(config.get('exportLink') + '/assets/exports/' + response.data.data.path)
            })
    };

    printTable = () => {
        axios.post(config.get('serverAPI').auditLog.exportPdf, null, {
            params: this.state.queryParams
        })
            .then(response => {
                printJS(config.get('exportLink') + '/assets/exports/' + response.data.data.path)
            })
    };

    toUpperCaseFilter = (d) => {
        return d.charAt(0).toUpperCase() + d.slice(1);
    };

    render() {
        return (
            <section id="attendance-log-table">
                <section className="table-actions">
                    <div className="search-box">
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Input
                                name='query'
                                fluid loading={this.state.loading}
                                onChange={this.handleSearchTextChange}
                                icon={{name: 'search'}}
                                placeholder='Search...'
                            />
                        </Form>
                    </div>

                    <div className="actions">
                        <Button
                            onClick={this.exportToCSV}
                            content='Export to CSV'
                            icon='file excel outline'
                        />

                        <Button
                            onClick={this.exportToPdf}
                            content='Export to PDF'
                            icon='file pdf outline'
                        />

                        <Button
                            onClick={this.printTable}
                            content='Print'
                            icon='print'
                        />

                        {/* <Button.Group className='filter'>
                            <Button>
                                <Icon name='filter' />
                                Operation</Button>
                            <Dropdown onChange={this.filterByOperation} options={filterOptions} floating button className='icon' />
                        </Button.Group> */}

                        <Dropdown
                            closeOnEscape
                            text='Filter'
                            icon='filter'
                            floating
                            labeled
                            button
                            className='icon'
                        >
                            <Dropdown.Menu style={{left: 'auto', right: 0}}>
                                {/*<Dropdown.Header style={{fontStyle: 'italic'}} content='Filter by Operation'/>*/}
                                {/*<Dropdown.Item style={{fontSize: '12px', color: 'green'}}*/}
                                {/*               onClick={() => this.filterByOperation('CHECK_IN')}>*/}
                                {/*    Check In*/}
                                {/*</Dropdown.Item>*/}
                                {/*<Dropdown.Item style={{fontSize: '12px', color: 'red'}}*/}
                                {/*               onClick={() => this.filterByOperation('CHECK_OUT')}>*/}
                                {/*    Check Out*/}
                                {/*</Dropdown.Item>*/}
                                {/*<Dropdown.Item style={{fontSize: '12px'}}*/}
                                {/*               onClick={() => this.filterByOperation(null)}>*/}
                                {/*    All*/}
                                {/*</Dropdown.Item>*/}
                                <Dropdown.Header style={{fontStyle: 'italic'}} content='Filter by Date'/>
                                <Dropdown.Item style={{fontSize: '12px'}}
                                               onClick={() => this.setState({timeRangeModalVisible: true})}>
                                    Select Date Range
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        {this.state.timeRangeModalVisible ? (
                            <Modal
                                onClose={() => this.setState({timeRangeModalVisible: false})}
                                open={true}
                                size='tiny'>
                                <Modal.Header>
                                    Select Date Range
                                </Modal.Header>
                                <Modal.Content>
                                    <DatesRangeInput
                                        dateFormat='MM/DD/YYYY'
                                        onChange={this.handleTimeRangeChange}
                                        style={{width: '100%'}}
                                        name="datesRange"
                                        placeholder="From - To"
                                        value={this.state.datesRange}
                                        iconPosition="left"
                                        // onChange={this.handleChange}
                                    />
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button
                                        onClick={this.setTimeRange}
                                        icon='checkmark'
                                        labelPosition='right'
                                        content='Apply'
                                    />
                                </Modal.Actions>
                            </Modal>
                        ) : null}

                    </div>
                </section>
                {this.state.queryParams.filter && this.state.queryParams.filter.operationTimestamp ? (
                    <Label.Group color='blue' style={{marginTop: '1rem'}}>
                        <Label as='a'>
                            Date From:
                            <span style={{margin: '0 0.5rem'}}>
                                    {moment.tz(this.state.queryParams.filter.operationTimestamp.start, this.props.timezone).format('MM/DD/YYYY')}
                                </span>
                            to
                            <span style={{margin: '0 0.5rem'}}>
                                    {moment.tz(this.state.queryParams.filter.operationTimestamp.end, this.props.timezone).format('MM/DD/YYYY')}
                                </span>
                            <Icon name='close' onClick={() => {
                                const filter = {
                                    ...this.state.queryParams.filter,
                                };
                                delete filter.operationTimestamp;
                                this.setState({
                                    queryParams: {
                                        ...this.state.queryParams,
                                        filter: {
                                            ...filter
                                        }
                                    }
                                }, () => this.getData())
                            }}/>
                        </Label>
                    </Label.Group>
                ) : null}
                <Table celled compact definition>
                    <Dimmer active={this.state.loading} inverted>
                        <Loader size='medium'>Loading</Loader>
                    </Dimmer>
                    <Table.Header fullWidth>
                        <Table.Row>
                            <Table.HeaderCell>
                                User
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Operation
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Time
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {
                            this.state.tableData.map(row =>
                                <Table.Row key={row._id}>
                                    <Table.Cell>
                                        {row.user ? row.user.firstName + ' ' + row.user.lastName : 'No User'}
                                    </Table.Cell>
                                    <Table.Cell>{row.operation.split('_').join(' ').toProperCase()}</Table.Cell>
                                    <Table.Cell>{
                                        <Moment
                                            format="DD MMM YYYY - HH:mm"
                                            filter={this.toUpperCaseFilter}
                                            tz={localStorage.getItem("timezone")} fromNowDuring={5184000}>
                                            {row.operationTimestamp}
                                        </Moment>
                                    }</Table.Cell>
                                </Table.Row>
                            )
                        }
                    </Table.Body>
                </Table>

                {this.state.tableData.length === 0 && !this.state.loading ? (
                    <p style={{textAlign: 'center'}}>No Results!</p>
                ) : null}

                <section style={{display: 'flex', justifyContent: 'center'}}>
                    {this.state.meta.totalRecords >= this.state.meta.pageLimit ? (
                        <Pagination
                            onPageChange={this.handlePaginationChange}
                            defaultActivePage={this.state.meta.currentPage + 1}
                            totalPages={Math.ceil(this.state.meta.totalRecords / this.state.meta.pageLimit)}
                        />
                    ) : null}
                </section>
            </section>
        )
    }
}
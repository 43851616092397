import React, {Component} from 'react'
import {Button, Header, Label, Modal, Select, Dimmer, Loader, Image, Message} from 'semantic-ui-react'
import {Form} from 'formsy-semantic-ui-react'
import {addValidationRule} from "formsy-react"
import axios from 'axios'
import config from 'react-global-configuration'
import tracking from 'jstracking';
import {CountryDropdown, RegionDropdown, CountryRegionData} from 'react-country-region-selector';
import postcode from 'postcode-validator';
import * as Formsy from 'formsy-react';
import {toast} from "react-toastify";

addValidationRule('isRequired', function (values, value) {
    return Boolean(value)
});

class AddModal extends Component {
    state = {
        loading: false,
        employee: {
            "firstName": '',
            "lastName": '',
            "phone": '',
            "email": '',
            "location": '',
            "address": {
                "addressone": '',
                "addresstwo": '',
                "city": '',
                "state": '',
                "country": '',
                "zipcode": ''
            },
            "img": '',
        },
        imageDataUrl: '',
        imageMethod: 'upload',
        "locations": [],
        imageSelected: false,
        imageError: ""
    };

    componentDidMount() {
        this.setState({loading: true});
        axios.get(config.get('serverAPI').locations.filter)
            .then(response => {
                this.setState({
                    locations: response.data.data,
                }, () => {
                    if (this.props.method === "edit") {
                        axios.get(config.get('serverAPI').employees.get + '/' + this.props.id)
                            .then(response => {
                                this.setState({
                                    employee: response.data.data,
                                    loading: false,
                                    imageDataUrl: response.data.data.img
                                });
                            })
                    } else {
                        this.setState({loading: false})
                    }
                });
            });
    }

    handleChange = (e, {name, value}) => {
        this.setState({
            employee: {
                ...this.state.employee,
                [name]: value
            }
        });
    };

    selectEmployeeCountry = (val) => {
        this.setState({
            employee: {
                ...this.state.employee,
                address: {
                    ...this.state.employee.address,
                    country: val
                }
            }
        });
    };

    selectEmployeeRegion = (val) => {
        this.setState({
            employee: {
                ...this.state.employee,
                address: {
                    ...this.state.employee.address,
                    state: val
                }
            }
        });
    };

    handleAddressChange = (e, {name, value}) => this.setState({
        employee: {
            ...this.state.employee,
            address: {
                ...this.state.employee.address,
                [name]: value
            }
        }
    });

    handleLocationSelection = (e, {name, value}) => {
        this.setState({
            employee: {
                ...this.state.employee,
                location: value
            }
        });
    };

    selectImage = () => {
        document.getElementById('embedpollfileinput').click();
    };

    handleImageSelection = e => {
        const reader = new FileReader();
        const file = e.target.files[0];

        reader.readAsDataURL(file);
        reader.onload = () => {
            this.setState({
                employee: {
                    ...this.state.employee,
                    img: file
                },
                imageSelected: true,
                imageDataUrl: reader.result
            });
        };

        setTimeout(() => {
            if (this.checkImageSize(file.size)) {
                this.setState({
                    imageError: 'Image size is bigger than 2MB.'
                })
            } else {
                const img = document.querySelector('#face-img');
                const tracker = new tracking.ObjectTracker(['face']);
                tracking.track(img, tracker);

                tracker.on('track', (event) => {
                    this.setState({
                        imageError: (event.data.length > 0) ? null : 'This is not a face image.'
                    })
                });
            }
        }, 500)

    };

    checkImageSize = (size) => {
        const twoMbs = 2 * 1024 * 1024;
        return size / twoMbs > 1;
    };

    insertOne = () => {
        if (!this.state.imageError) {
            const formData = new FormData();
            for (let key in this.state.employee) {
                if (key === "address") {
                    formData.append(key, JSON.stringify(this.state.employee[key]));
                    continue;
                }
                formData.append(key, this.state.employee[key])
            }

            this.setState({loading: true});
            axios({
                method: 'post',
                url: config.get('serverAPI').employees.get,
                data: formData,
                config: {headers: {'Content-Type': 'multipart/form-data'}}
            })
                .then((response) => {
                    toast.success("Employee added successfully");
                    this.setState({loading: false});
                    this.props.onSuccess();
                })
                .catch((response) => {
                    this.setState({loading: false});
                });
        }
    };

    editOne = () => {
        if (!this.state.imageError) {

            this.setState({loading: true});
            const objToSend = {...this.state.employee};
            if (this.props.method === 'edit' && !this.state.imageSelected) delete objToSend.img;

            const formData = new FormData();
            for (let key in objToSend) {
                if (key === "address") {
                    formData.append(key, JSON.stringify(objToSend[key]));
                    continue;
                }
                formData.append(key, this.state.employee[key])
            }

            this.setState({loading: true});
            axios({
                method: 'put',
                url: config.get('serverAPI').employees.get + "/" + this.props.id,
                data: formData,
                config: {headers: {'Content-Type': 'multipart/form-data'}}
            })
                .then((response) => {
                    toast.success("Employee modified successfully");
                    this.setState({loading: false});
                    this.props.onSuccess();
                })
                .catch((response) => {
                    this.setState({loading: false});
                });
        }
    };

    render() {
        const locations = [];
        this.state.locations.forEach(location => {
            locations.push({
                key: location._id,
                text: location.locationName,
                value: location._id
            })
        });
        const errorLabel = <Label color="red" pointing/>;

        Formsy.addValidationRule('isZipValid', (values, value) => {
            if (this.state.employee.address.country) {
                try {
                    return postcode.validate(value, this.state.employee.address.country);
                } catch (e) {
                    return true;
                }
            }
        });

        return (
            <Modal
                open={true} onClose={this.props.onClose} closeIcon
            >
                <Header
                    icon={this.props.method === 'add' ? 'add user' : 'edit'}
                    content={this.props.method === 'add' ? 'Add Employee' : 'Edit employee'}/>
                <Modal.Content>
                    <Dimmer active={this.state.loading} inverted>
                        <Loader inverted content='Loading'/>
                    </Dimmer>
                    <Form id="addForm" onValidSubmit={this.props.method === 'edit' ? this.editOne : this.insertOne}>
                        <Form.Group widths='equal'>
                            <Form.Input
                                label={this.props.method === 'edit' ? 'First Name' : null}
                                value={this.state.employee.firstName}
                                validations="isRequired"
                                validationErrors={{isRequired: 'First name is required.'}}
                                errorLabel={errorLabel}
                                fluid placeholder='First name' name='firstName' onChange={this.handleChange}
                            />
                            <Form.Input
                                label={this.props.method === 'edit' ? 'Last Name' : null}
                                value={this.state.employee.lastName}
                                validations="isRequired"
                                validationErrors={{isRequired: 'Last name is required.'}}
                                errorLabel={errorLabel}
                                fluid placeholder='Last name' name='lastName'
                                onChange={this.handleChange}/>
                        </Form.Group>
                        {this.props.method === 'edit' ? (<label><strong>Location</strong></label>) : null}
                        <Form.Group style={{marginLeft: '0.1rem', marginRight: '0.1rem'}}>
                            <Select value={this.state.employee.location || null} onChange={this.handleLocationSelection}
                                    placeholder="Locations" options={locations}/>
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Input
                                label={this.props.method === 'edit' ? 'Phone' : null}
                                value={this.state.employee.phone}
                                fluid placeholder='Phone' name='phone' onChange={this.handleChange}/>
                            <Form.Input
                                label={this.props.method === 'edit' ? 'Email' : null}
                                value={this.state.employee.email}
                                validations="isRequired,isEmail"
                                validationErrors={{isRequired: 'Email is required', isEmail: 'Email not valid'}}
                                errorLabel={errorLabel}
                                fluid type='email' placeholder='Email' name='email'
                                onChange={this.handleChange}/>
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Input
                                label={this.props.method === 'edit' ? 'Address Line 1' : null}
                                value={this.state.employee.address.addressone}
                                fluid placeholder='Address Line 1' name='addressone'
                                onChange={this.handleAddressChange}/>
                            <Form.Input
                                label={this.props.method === 'edit' ? 'Address Line 2' : null}
                                value={this.state.employee.address.addresstwo}
                                fluid placeholder='Address Line 2' name='addresstwo'
                                onChange={this.handleAddressChange}/>
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <CountryDropdown
                                valueType="short"
                                style={{marginLeft: "0.5rem", marginRight: "1.2rem"}}
                                value={this.state.employee.address.country}
                                onChange={(val) => this.selectEmployeeCountry(val)}
                                priorityOptions={["CA", "US", "GB"]}/>
                            <RegionDropdown
                                countryValueType="short"
                                style={{marginRight: "0.5rem"}}
                                country={this.state.employee.address.country}
                                value={this.state.employee.address.state}
                                onChange={(val) => this.selectEmployeeRegion(val)}/>
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Input
                                label={this.props.method === 'edit' ? 'City' : null}
                                value={this.state.employee.address.city}
                                fluid placeholder='City' name='city'
                                onChange={this.handleAddressChange}/>

                            <Form.Input
                                label={this.props.method === 'edit' ? 'Zip Code' : null}
                                value={this.state.employee.address.zipcode}
                                fluid placeholder='Zip' name='zipcode'
                                onChange={this.handleAddressChange}/>
                        </Form.Group>
                        <Message>
                            <Message.Header>Image Limitations</Message.Header>
                            <Message.List>
                                <p style={{margin: "0.2rem 0.4rem"}}>&#9726; Image must contain a single human face.</p> 
                                <p style={{margin: "0.2rem 0.4rem"}}>&#9726; Image size must be lower than or equal 2MB.</p> 
                            </Message.List>
                        </Message>
                        <Form.Field className='image-part'>
                            <div>
                                <label style={{marginRight: '1rem'}}><strong>Face Image:</strong></label>
                                <Button.Group>
                                    <Button positive onClick={this.selectImage} type='button'>
                                        Upload Image
                                    </Button>
                                </Button.Group>
                            </div>
                            {
                                Boolean(this.state.employee.img) ? (
                                    <section>
                                        <Image id='face-img' size='small' bordered rounded
                                               src={this.state.imageDataUrl}/>
                                    </section>
                                ) : null
                            }
                        </Form.Field>
                        <input onChange={this.handleImageSelection} type="file" id="embedpollfileinput"
                               style={{display: 'none'}}/>
                        {this.state.imageError && this.state.imageSelected ? (
                            <Message negative>
                                <Message.Header>Image cannot be uploaded!</Message.Header>
                                <p>{this.state.imageError}</p>
                            </Message>
                        ) : null}
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={this.props.onClose}>
                        Cancel
                    </Button>
                    {
                        this.props.method === 'add' ? (
                            <Button color='blue' type="submit" form="addForm"
                                    disabled={this.state.imageError || !this.state.employee.img}>
                                Add
                            </Button>
                        ) : (
                            <Button color='blue' type="submit" form="addForm">
                                Save
                            </Button>
                        )
                    }
                </Modal.Actions>
            </Modal>
        );
    }
}

export default AddModal;

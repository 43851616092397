import React, {Component} from 'react'
import {Button, Grid, Header, Label, Icon, Segment} from 'semantic-ui-react'
import {Form} from 'formsy-semantic-ui-react'
import Axios from 'axios'
import config from 'react-global-configuration'

export default class SetPassword extends Component {
    state = {
        email: '',
        password: '',
        loading: false,
        token: '',
        resetSuccess: false,
    };

    componentDidMount() {
        const url = window.location.hash.split("?")[1];
        const urlParams = new URLSearchParams('?' + url);
        const token = urlParams.get('token');
        const email = urlParams.get('email');
        this.setState({
            token: token,
            email: email
        })
    }

    handleChange = (e, {name, value}) => {
        this.setState({
            [name]: value
        });
    };

    setPassword = () => {
        this.setState({loading: true});
        Axios.post(config.get('serverAPI').users.set_password, {
            code: this.state.token,
            password: this.state.password,
            email: this.state.email
        })
            .then(response => {
                this.setState({loading: false, resetSuccess: true});
            })
            .catch(error => {
                this.setState({loading: false})
            });
    };

    render() {
        const errorLabel = <Label color="red" pointing/>;
        return (
            <Grid textAlign='center' style={{height: '100%', overflow: 'auto'}} verticalAlign='middle'>
                <Grid.Column style={{maxWidth: 450}}>
                    <Segment>
                        <Header as='h2' color='teal' textAlign='center'>
                            Set your Password
                        </Header>

                        {this.state.resetSuccess ? (
                            <div>
                                <Icon size='massive' color="green" name="check circle"/>
                                <h3>Your password has been reset.</h3>
                                <a
                                    href="#"
                                    style={{textDecoration: "underline", color: "#444", cursor: "pointer"}}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.props.onClose()
                                    }}>Back to Main Page</a>
                            </div>
                        ) : (
                            <Form onValidSubmit={this.setPassword}>
                                <Form.Group widths='equal'>
                                    <Form.Input
                                        type='password'
                                        value={this.state.password}
                                        validations={{matchRegexp: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[A-Za-z0-9!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]{8,}$/}}
                                        validationError="Password must have minimum length of 8,at least 1 upper case letter, 1 number and 1 special character."
                                        errorLabel={errorLabel}
                                        fluid placeholder='New Password' name='password' onChange={this.handleChange}
                                    />
                                    <Form.Input
                                        type='password'
                                        validations="equalsField:password"
                                        validationErrors={{'equalsField': 'Two passwords do not match.'}}
                                        errorLabel={errorLabel}
                                        fluid placeholder='Confirm Password' name='confirmPassword'/>
                                </Form.Group>
                                <Button
                                    type='submit' loading={this.state.loading} color='teal' fluid size='huge'>
                                    Set Password
                                    <Icon name='arrow right'/>
                                </Button>
                            </Form>
                        )}
                    </Segment>
                </Grid.Column>
            </Grid>
        )
    }
}
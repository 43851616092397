import React, {Component} from 'react'
import {Button, Label, Modal, Dimmer, Loader} from 'semantic-ui-react'
import {Form} from 'formsy-semantic-ui-react'
import {addValidationRule} from "formsy-react"
import axios from 'axios'
import config from 'react-global-configuration'
import { toast } from 'react-toastify'

addValidationRule('isRequired', function (values, value) {
    return Boolean(value)
});

export default class ResetPasswordModal extends Component {
    state = {
        code: '',
        password: '',
        loading: false
    };

    handleChange = (e, {name, value}) => {
        this.setState({
            [name]: value
        });
    };

    componentDidMount() {
        this.setState({loading: true});
        axios.get(config.get('serverAPI').users.get + '/' + this.props.id + '/request_reset')
            .then(response => {
                this.setState({loading: false});
            })
    }

    resetPassword = () => {
        this.setState({loading: true});
        axios.post(config.get('serverAPI').users.get + '/' + this.props.id + '/reset_password', {
            code: this.state.code,
            password: this.state.password
        }).then(response => {
            this.setState({loading: false});
            toast.success("Your password has been reset successfully.")
            this.props.onClose()
        }).catch(err => {
            this.setState({loading: false});
        })
    };

    render() {
        const errorLabel = <Label color="red" pointing/>;
        return (
            <Modal open={true} onClose={this.props.onClose} closeIcon>
                <Modal.Header>
                    Reset Password
                </Modal.Header>
                <Modal.Content>
                    <Dimmer active={this.state.loading} inverted>
                        <Loader inverted content='Loading'/>
                    </Dimmer>
                    <p style={{textAlign: 'center'}}>
                        Please check your email for authentication code.
                    </p>
                    <Form id='reset-form' onValidSubmit={this.resetPassword}>
                        <Form.Input
                            value={this.state.code}
                            validations="isRequired"
                            validationErrors={{isRequired: 'Authentication code is required.'}}
                            errorLabel={errorLabel}
                            fluid placeholder='Authentication key' name='code' onChange={this.handleChange}
                        />
                        <Form.Group widths='equal'>
                            <Form.Input
                                type='password'
                                value={this.state.password}
                                validations={{matchRegexp: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[A-Za-z0-9!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]{8,}$/}}
                                validationError="Password must have minimum length of 8,at least 1 upper case letter, 1 number and 1 special character."
                                errorLabel={errorLabel}
                                fluid placeholder='New Password' name='password' onChange={this.handleChange}
                            />
                            <Form.Input
                                type='password'
                                validations="equalsField:password"
                                validationErrors={{'equalsField': 'Two passwords do not match.'}}
                                errorLabel={errorLabel}
                                fluid placeholder='Confirm Password' name='confirmPassword'/>
                        </Form.Group>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={this.props.onClose}>
                        Cancel
                    </Button>
                    <Button color='blue' type="submit" form="reset-form">
                        Reset Password
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }
}
import React, {Component} from 'react'
import {Button, Header, Icon, Modal} from 'semantic-ui-react'

class CannotAddModal extends Component {
    state = {
        visible: true,
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.show) {
            this.setState({visible: nextProps.show})
        }
    }

    goToSubscriptionPage = () => {
        this.setState({visible: false});
        window.location.assign("/#/panel/membership")
    };

    getContextMessage = () => {
        if (this.props.plan === "Free" || this.props.plan === "Professional")
            return (
                <p>To add more {this.props.unitType}, you should upgrade to a higher plan.</p>
            );
        else return (
            <p>To add more {this.props.unitType}, you should extend upgrade your subscription.</p>
        )
    };

    render() {
        return (
            <Modal
                open={this.state.visible} basic size='small'
                closeIcon onClose={this.props.onClose}
            >
                <Header icon='exclamation' content="You Can't Do That"/>
                <Modal.Content>
                    {this.getContextMessage()}
                </Modal.Content>
                <Modal.Actions>
                    <Button color='grey' basic onClick={() => this.props.onClose()}>
                        <Icon name='remove'/> Never mind
                    </Button>
                    <Button size="big" color='green' inverted onClick={this.goToSubscriptionPage}>
                        <Icon style={{marginRight: '0.5rem'}} name='arrow right'/> Go to Subscription
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
};

export default CannotAddModal;
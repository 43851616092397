import React, { Component } from 'react'
import { Button, Grid, Header, Label, Icon, Message, Image } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react';
import * as Formsy from 'formsy-react';
import Axios from 'axios'
import config from 'react-global-configuration';
// import timezoneLocatons from "../timezoneOptions.js"
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
// import postcode from 'postcode-validator';
// import { PhoneNumberUtil } from 'google-libphonenumber';
import loginPhoto from "./logo_Admin.png"
import mailPhoto from "./mail.png"

// const phoneNumberUtil = PhoneNumberUtil.getInstance();

export default class Register extends Component {
    state = {
        loading: false,
        user: {
            firstName: '',
            lastName: '',
            // user_phone: '',
            email: '',
            password: '',
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            // user_address: {
            //     addressone: '',
            //     addresstwo: '',
            //     city: '',
            //     country: '',
            //     state: '',
            //     zipcode: ''
            // }
        },
        company: {
            companyName: '',
            // imgbase64: "",
            // company_phone: '',
            company_address: {
                // addressone: '',
                // addresstwo: '',
                // city: '',
                country: '',
                state: '',
                // zipcode: ''
            }
        },
        status: 'user',
        customValidations: {
            user: {
                visible: false,
                messages: []
            },
            company: {
                visible: false,
                messages: []
            }
        },
        doneRegistration: false,
        verifyingUserEmail: false,
    };

    // selectUserCountry = (val) => {
    //     this.setState({
    //         user: {
    //             ...this.state.user,
    //             user_address: {
    //                 ...this.state.user.user_address,
    //                 country: val
    //             }
    //         }
    //     });
    // };

    // selectUserRegion = (val) => {
    //     this.setState({
    //         user: {
    //             ...this.state.user,
    //             user_address: {
    //                 ...this.state.user.user_address,
    //                 state: val
    //             }
    //         }
    //     });
    // };

    selectCompanyCountry = (val) => {
        this.setState({
            company: {
                ...this.state.company,
                company_address: {
                    ...this.state.company.company_address,
                    country: val
                }
            }
        });
    };

    selectCompanyRegion = (val) => {
        this.setState({
            company: {
                ...this.state.company,
                company_address: {
                    ...this.state.company.company_address,
                    state: val
                }
            }
        });
    };

    handleChange = (e, { name, value }) => {
        this.setState({
            user: {
                ...this.state.user,
                [name]: value
            }
        });
    };

    // handleTimezoneSelection = (e, { name, value }) => {
    //     this.setState({
    //         user: {
    //             ...this.state.user,
    //             timezone: value
    //         }
    //     });
    // };

    handleCompanyChange = (e, { name, value }) => {
        this.setState({
            company: {
                ...this.state.company,
                [name]: value
            }
        });
    };

    handleAddressChange = (e, { name, value }) => this.setState({
        company: {
            ...this.state.company,
            company_address: {
                ...this.state.company.company_address,
                [name]: value
            }
        }
    });

    // handleUserAddressChange = (e, { name, value }) => this.setState({
    //     user: {
    //         ...this.state.user,
    //         user_address: {
    //             ...this.state.user.user_address,
    //             [name]: value
    //         }
    //     }
    // });

    userFilled = () => {
        const errorMessages = [];
        // if (this.state.user.timezone === "")
        //     errorMessages.push("Please select your timezone.");
        // if (this.state.user.user_address.country === "")
        //     errorMessages.push("Please select your country.");
        // if (this.state.user.user_address.state === "")
        //     errorMessages.push("Please select your region.");

        if (errorMessages.length > 0) {
            this.setState({
                customValidations: {
                    ...this.state.customValidations,
                    user: {
                        visible: true,
                        messages: errorMessages
                    }
                }
            });
        } else {
            this.setState({
                verifyingUserEmail: true,
                customValidations: {
                    ...this.state.customValidations,
                    user: {
                        visible: false,
                        messages: []
                    }
                }
            });
            Axios.post(config.get("serverAPI").verify.email, { ...this.state.user })
                .then(() => {
                    this.setState({ verifyingUserEmail: false });
                    this.setState({ status: 'company' });
                })
                .catch(() => {
                    this.setState({ verifyingUserEmail: false });
                    errorMessages.push("This email has already been taken.");
                })
        }
    };

    registerNewUser = () => {
        const errorMessages = [];
        if (this.state.company.company_address.country === "")
            errorMessages.push("Please select a country.");
        if (this.state.company.company_address.state === "")
            errorMessages.push("Please select a region.");

        if (errorMessages.length > 0)
            this.setState({
                customValidations: {
                    ...this.state.customValidations,
                    company: {
                        visible: true,
                        messages: errorMessages
                    }
                }
            });

        else {
            this.setState({ loading: true });
            Axios.post(config.get('serverAPI').companies.get, {
                ...this.state.user,
                ...this.state.company,
                plan: this.props.plan
            })
                .then(Response => {
                    this.setState({ loading: false });
                    if (this.props.plan === "Free")
                        this.setState({ doneRegistration: true });
                    else
                        window.location.replace(Response.data.data.url);
                })
                .catch(error => {
                    this.setState({ loading: false });
                })
        }
    };

    getColor = () => {
        return "#4360BA";
    };

    render() {
        const errorLabel = <Label color="red" pointing />;
        // const timezoneOptions = timezoneLocatons;

        // Formsy.addValidationRule('isZipValid', (values, value) => {
        //     if (this.state.user.user_address.country) {
        //         try {
        //             return postcode.validate(value, this.state.user.user_address.country);
        //         } catch (e) {
        //             return true;
        //         }
        //     }
        // });

        // Formsy.addValidationRule('isCompanyZipValid', (values, value) => {
        //     if (this.state.company.company_address.country) {
        //         try {
        //             return postcode.validate(value, this.state.company.company_address.country);
        //         } catch (e) {
        //             return true;
        //         }
        //     }

        //     return true;
        // });

        // Formsy.addValidationRule('isPhoneValid', (values, value) => {
        //     if (this.state.user.user_address.country) {
        //         try {
        //             const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(
        //                 value, this.state.user.user_address.country
        //             );
        //             return phoneNumberUtil.isValidNumber(phoneNumber);
        //         } catch (e) {
        //             return false;
        //         }
        //     }
        //     return true;
        // });

        // Formsy.addValidationRule('isCompanyPhoneValid', (values, value) => {
        //     if (this.state.company.company_address.country) {
        //         try {
        //             const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(
        //                 value, this.state.company.company_address.country
        //             );
        //             return phoneNumberUtil.isValidNumber(phoneNumber);
        //         } catch (e) {
        //             return false;
        //         }
        //     }
        //     return true;
        // });


        Formsy.addValidationRule('isRequired', function (values, value) {
            return Boolean(value)
        });

        return (
            <Grid textAlign='center' style={{ height: '100%', overflow: 'auto' }} verticalAlign='middle'>
                <Grid.Column style={{ maxWidth: 550 }}>
                    <Image style={{margin: "auto"}} src={loginPhoto} />
                    {this.state.doneRegistration ? (
                        <section style={{marginTop: "1rem"}}>
                            <Image size="medium" src={mailPhoto} style={{margin: "auto"}} />
                            <Header as='h2' style={{ color: this.getColor(), textTransform: "uppercase" }} textAlign='center'>
                                Thanks for your subscription
                            </Header>
                            <h3>Please check your email to verify your account.</h3>
                            <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'space-around' }}>
                                <a
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.props.onClose()
                                    }}
                                    style={{ textDecoration: 'underline', color: '#444' }}
                                    href='#'>Back to Login</a>
                            </div>
                        </section>
                    ) : (
                            <section style={{marginTop: "1.5rem"}}>
                                <Header as='h2' style={{ color: this.getColor() }} textAlign='center'>
                                    Subscribe to <span style={{ textDecoration: "underline" }}>{this.props.plan}</span> Plan
                            </Header>
                                {
                                    this.state.status === 'user' ? (
                                        <React.Fragment>
                                            <h4 style={{ margin: "0.5rem" }}>User Information</h4>
                                            <Form onValidSubmit={this.userFilled} id="signup-userinfo">
                                                <Form.Group widths='equal'>
                                                    <Form.Input
                                                        value={this.state.user.firstName}
                                                        validations="isRequired"
                                                        validationErrors={{ isRequired: 'First name is required.' }}
                                                        errorLabel={errorLabel}
                                                        fluid placeholder='First name' name='firstName'
                                                        onChange={this.handleChange}
                                                    />
                                                    <Form.Input
                                                        value={this.state.user.lastName}
                                                        validations="isRequired"
                                                        validationErrors={{ isRequired: 'Last name is required.' }}
                                                        errorLabel={errorLabel}
                                                        fluid placeholder='Last name' name='lastName'
                                                        onChange={this.handleChange} />
                                                </Form.Group>
                                                {/* <Form.Group widths='equal'>
                                                    <CountryDropdown
                                                        valueType="short"
                                                        style={{ marginLeft: "0.5rem", marginRight: "1.2rem" }}
                                                        value={this.state.user.user_address.country}
                                                        onChange={(val) => this.selectUserCountry(val)}
                                                        priorityOptions={["CA", "US", "GB"]} />
                                                    <RegionDropdown
                                                        countryValueType="short"
                                                        style={{ marginRight: "0.5rem" }}
                                                        country={this.state.user.user_address.country}
                                                        value={this.state.user.user_address.state}
                                                        onChange={(val) => this.selectUserRegion(val)} />
                                                </Form.Group>
                                                <Form.Input
                                                    value={this.state.user.user_phone}
                                                    validations="isPhoneValid"
                                                    validationErrors={{ isPhoneValid: 'Phone is not valid.' }}
                                                    errorLabel={errorLabel}
                                                    fluid placeholder='Phone' name='user_phone' onChange={this.handleChange}
                                                >
                                                </Form.Input> */}
                                                <Form.Input
                                                    value={this.state.user.email}
                                                    validations="isRequired"
                                                    validationErrors={{ isRequired: 'Email is required.' }}
                                                    errorLabel={errorLabel}
                                                    fluid placeholder='Email' name='email' onChange={this.handleChange}
                                                />
                                                <Form.Group widths='equal'>
                                                    <Form.Input
                                                        type='password'
                                                        value={this.state.user.password}
                                                        validations={{ matchRegexp: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[A-Za-z0-9!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]{8,}$/ }}
                                                        validationError="Password must have minimum length of 8,at least 1 upper case letter, 1 number and 1 special character."
                                                        errorLabel={errorLabel}
                                                        fluid placeholder='Password' name='password'
                                                        onChange={this.handleChange}
                                                    />
                                                    <Form.Input
                                                        type='password'
                                                        validations="equalsField:password"
                                                        validationErrors={{ 'equalsField': 'Two passwords do not match.' }}
                                                        errorLabel={errorLabel}
                                                        fluid placeholder='Confirm Password' name='confirmPassword' />

                                                </Form.Group>

                                                {/* <Select
                                                    required
                                                    value={this.state.user.timezone}
                                                    style={{ marginBottom: '1rem' }}
                                                    onChange={this.handleTimezoneSelection}
                                                    placeholder="Select your area timezone" options={timezoneOptions} />
                                                <Form.Group widths='equal'>
                                                    <Form.Input
                                                        value={this.state.user.user_address.addressone}
                                                        validations="isRequired"
                                                        validationErrors={{ isRequired: 'Address line 1 is required.' }}
                                                        errorLabel={errorLabel}
                                                        fluid placeholder='Address Line 1' name='addressone'
                                                        onChange={this.handleUserAddressChange} />
                                                    <Form.Input
                                                        value={this.state.user.user_address.addresstwo}
                                                        fluid placeholder='Address Line 2' name='addresstwo'
                                                        onChange={this.handleUserAddressChange} />
                                                </Form.Group>
                                                <Form.Group widths='equal'>
                                                    <Form.Input
                                                        value={this.state.user.user_address.city}
                                                        validations="isRequired"
                                                        validationErrors={{ isRequired: 'City is required.' }}
                                                        errorLabel={errorLabel}
                                                        fluid placeholder='City' name='city'
                                                        onChange={this.handleUserAddressChange} />
                                                    <Form.Input
                                                        value={this.state.user.user_address.zipcode}
                                                        validations="isZipValid"
                                                        validationErrors={{ isZipValid: 'Zip code is not valid.' }}
                                                        errorLabel={errorLabel}
                                                        fluid placeholder='Zip' name='zipcode'
                                                        onChange={this.handleUserAddressChange} />
                                                </Form.Group> */}
                                                <Message>
                                                        By clicking the "Next" button, you agree to the <a target="_blank" href="https://attendlab.com/?page_id=283"> terms of use</a> and <a target="_blank" href="https://attendlab.com/?page_id=3">privacy policy.</a>
                                                </Message>
                                                <Button
                                                    loading={this.state.verifyingUserEmail}
                                                    type='submit' style={{ background: this.getColor(), color: "whitesmoke" }} fluid
                                                    size='huge'>
                                                    Next
                                                <Icon name='arrow right' />
                                                </Button>
                                            </Form>
                                        </React.Fragment>
                                    ) : (
                                            <React.Fragment>
                                                <h4 style={{ margin: "0.6rem" }}>Company Information</h4>
                                                <Form onValidSubmit={this.registerNewUser}>
                                                    <Form.Input
                                                        value={this.state.company.companyName}
                                                        validations="isRequired"
                                                        validationErrors={{ isRequired: 'Company Name is required.' }}
                                                        errorLabel={errorLabel}
                                                        fluid placeholder='Company Name' name='companyName'
                                                        onChange={this.handleCompanyChange} />
                                                    <Form.Group widths='equal'>
                                                        <CountryDropdown
                                                            valueType="short"
                                                            style={{ marginLeft: "0.5rem", marginRight: "1.2rem" }}
                                                            value={this.state.company.company_address.country}
                                                            onChange={(val) => this.selectCompanyCountry(val)}
                                                            priorityOptions={["CA", "US", "GB"]} />
                                                        <RegionDropdown
                                                            countryValueType="short"
                                                            style={{ marginRight: "0.5rem" }}
                                                            country={this.state.company.company_address.country}
                                                            value={this.state.company.company_address.state}
                                                            onChange={(val) => this.selectCompanyRegion(val)} />
                                                    </Form.Group>
                                                    {/* <Form.Input
                                                        value={this.state.company.company_phone}
                                                        validations="isCompanyPhoneValid"
                                                        validationErrors={{ isCompanyPhoneValid: 'Phone is not valid.' }}
                                                        errorLabel={errorLabel}
                                                        fluid placeholder='Phone' name='company_phone'
                                                        onChange={this.handleCompanyChange} />
                                                    <Form.Group widths='equal'>
                                                        <Form.Input
                                                            value={this.state.company.company_address.addressone}
                                                            validations="isRequired"
                                                            validationErrors={{ isRequired: 'Address line 1 is required.' }}
                                                            errorLabel={errorLabel}
                                                            fluid placeholder='Address Line 1' name='addressone'
                                                            onChange={this.handleAddressChange} />
                                                        <Form.Input
                                                            value={this.state.company.company_address.addresstwo}
                                                            fluid placeholder='Address Line 2' name='addresstwo'
                                                            onChange={this.handleAddressChange} />
                                                    </Form.Group>
                                                    <Form.Group widths='equal'>
                                                        <Form.Input
                                                            value={this.state.company.company_address.city}
                                                            // validations="isRequired"
                                                            // validationErrors={{isRequired: 'City is required.'}}
                                                            errorLabel={errorLabel}
                                                            fluid placeholder='City' name='city'
                                                            onChange={this.handleAddressChange} />
                                                        <Form.Input
                                                            value={this.state.company.company_address.zipcode}
                                                            validations="isCompanyZipValid"
                                                            validationErrors={{ isCompanyZipValid: 'Zip code is not valid.' }}
                                                            errorLabel={errorLabel}
                                                            fluid placeholder='Zip' name='zipcode'
                                                            onChange={this.handleAddressChange} />
                                                    </Form.Group> */}
                                                    <Message>
                                                        By clicking the "Register" button, you agree to the <a target="_blank" href="https://attendlab.com/?page_id=283"> terms of use</a> and <a target="_blank" href="https://attendlab.com/?page_id=3">privacy policy.</a>
                                                    </Message>
                                                    <Button type='submit' loading={this.state.loading}
                                                        style={{ background: this.getColor(), color: "whitesmoke" }}
                                                        fluid
                                                        size='huge'>
                                                        Register
                                            </Button>
                                                </Form>
                                            </React.Fragment>
                                        )
                                }
                                {this.state.customValidations.user.visible ? (
                                    <Message
                                        error list={this.state.customValidations.user.messages} />
                                ) : null}
                                {this.state.customValidations.company.visible ? (
                                    <Message
                                        error list={this.state.customValidations.company.messages} />
                                ) : null}
                                <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'space-around' }}>
                                    <a
                                        onClick={this.props.onClose}
                                        style={{ textDecoration: 'underline', color: '#444' }}
                                        href='#'>Back to Login</a>
                                    {this.state.status !== 'user' ? (
                                        <a
                                            onClick={() => this.setState({ status: 'user' })}
                                            style={{ textDecoration: 'underline', color: '#444' }}
                                            href='#'>Back to User Information</a>
                                    ) : null}
                                </div>
                            </section>
                        )}
                </Grid.Column>
            </Grid>
        )
    }
}

import React, { Component } from 'react'
import {Button, Header, Icon, Modal} from "semantic-ui-react";

export default class CancelConfirmationModal extends Component {
    render() {
        return (
            <Modal open={true} basic size='small' onClose={this.props.onClose} closeIcon>
                <Header icon='cancel' content="Cancel Subscription"/>
                <Modal.Content>
                    <p>
                        To delete your account, please contact support
                    </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='grey' basic onClick={this.props.onClose}>
                        <Icon name='remove'/> Ok!
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }
}
import React, { Component } from 'react';
import { Icon, Image, Menu, Sidebar, Button, Segment, Popup, Placeholder, Label } from 'semantic-ui-react';
import { HashRouter as Router, Route, Link } from 'react-router-dom';
import './Sidebar.css';
import UsersTable from '../tables/Users';
import EmployeeTable from '../tables/Employees';
import LocationsTable from '../tables/Locations';
import RolesTable from '../tables/Roles';
import AttendanceLogTable from '../tables/AttendanceLog';
import AuditLogTable from '../tables/AuditLog';
import SettingsPage from '../SettingsPage';
import Dashboard from '../Dashboard';
import Axios from 'axios';
import config from 'react-global-configuration';
import MembershipManager from '../Membership';
import InvoicesTable from '../tables/Invoices';
import ThanksPage from '../Login/ThanksPage';
import TimeSheetTable from './../tables/TimeSheet/index';
import EmployeeTimeSheet from './../tables/EmployeeTimeSheet/index';

export default class AppSidebar extends Component {
    state = {
        visible: true,
        currentPage: window.location.hash.split('/')[2] || 'dashboard',
        user: {
            name: '',
            role: '',
            companyID: '',
            image: '',
            timezone: '',
            superAdmin: false,
            membership: '',
            subscriptionDetails: '',
            permissions: [],
        },
        loading: false,
        rightComponent: '',
        tpModalType: '',
    };

    // toggleVisibility = () => {
    //   this.setState({
    //     ...this.state,
    //     visible: !this.state.visible
    //   })
    // };

    componentDidMount() {
        this.getData();
    }

    getData() {
        this.setState({ loading: true });
        Axios.get(config.get('serverAPI').users.me).then((res) => {
            this.setState(
                {
                    user: {
                        name: res.data.data.firstName + ' ' + res.data.data.lastName,
                        role: res.data.data.role.roleName,
                        companyID: res.data.data.company._id,
                        timezone: res.data.data.timezone,
                        superAdmin: res.data.data.role.superAdmin,
                        membership: res.data.data.company.plan,
                        subscriptionDetails: res.data.data.company.subscription,
                        permissions: res.data.data.role.permissions,
                    },
                },
                () => {
                    localStorage.setItem('timezone', this.state.user.timezone);
                    localStorage.setItem('user_email', res.data.data.email);
                    this.setRightComponent();
                    this.getCompanyImage();
                }
            );
        });
    }

    getCompanyImage = () => {
        this.setState({ loading: true });
        Axios.get(config.get('serverAPI').companies.get + '/' + this.state.user.companyID).then((res) => {
            this.setState(
                {
                    user: {
                        ...this.state.user,
                        image: res.data.data.img,
                    },
                },
                () => {
                    this.setState({ loading: false });
                    localStorage.setItem('subscriptionId', res.data.data.subscriptionId);
                }
            );
        });
    };

    handleLogout = () => {
        this.props.onLogout();
    };

    getPlanColor = () => {
        const plan = this.state.user.membership;
        if (plan === 'Free') return 'grey';
        else if (plan === 'Professional') return 'orange';
        else return 'red';
    };

    setRightComponent = () => {
        if (window.location.hash === '#/') {
            if (this.findRole('READ_REPORTS')) window.location.assign('/#/panel/dashboard');
            else if (this.findRole('READ_USER')) window.location.assign('/#/panel/users');
            else if (this.findRole('READ_EMPLOYEE')) window.location.assign('/#/panel/employees');
            else if (this.findRole('READ_LOCATION')) window.location.assign('/#/panel/locations');
            else if (this.findRole('READ_ROLE')) window.location.assign('/#/panel/roles');
            else if (this.findRole('READ_ATTENDANCE_LOG')) window.location.assign('/panel/attendanceLog');
            else if (this.findRole('READ_AUDIT_LOG')) window.location.assign('/#/panel/auditLog');
        }
    };

    findRole = (roleName) => {
        return this.state.user.permissions.indexOf(roleName) !== -1;
    };

    render() {
        const { visible } = this.state;

        return (
            <Router>
                <div id="app-sidebar">
                    <Sidebar.Pushable
                        as={Segment}
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Sidebar className="side" as={Menu} animation="push" inverted vertical visible={visible}>
                            <section className="userInfo">
                                {this.state.loading ? (
                                    <div>
                                        <Placeholder
                                            style={{
                                                height: 100,
                                                width: 150,
                                                borderRadius: '10%',
                                                opacity: 0.1,
                                            }}
                                        >
                                            <Placeholder.Header>
                                                <Placeholder.Image />
                                            </Placeholder.Header>
                                        </Placeholder>
                                        <Placeholder
                                            style={{
                                                height: 40,
                                                width: 150,
                                                margin: '0',
                                                marginTop: '0.5rem',
                                                borderRadius: '10%',
                                                opacity: 0.1,
                                            }}
                                        >
                                            <Placeholder.Line length="full" />
                                        </Placeholder>
                                    </div>
                                ) : (
                                    <div style={{ textAlign: 'center' }}>
                                        {this.state.user.image ? (
                                            <Image
                                                style={{ margin: 'auto' }}
                                                size="small"
                                                rounded
                                                src={this.state.user.image}
                                            />
                                        ) : (
                                            <Icon name="image" inverted size="massive" />
                                        )}
                                        <h3 className="greeting">{this.state.user.name}</h3>
                                        <p
                                            style={{
                                                color: 'whitesmoke',
                                                marginTop: '0',
                                                textAlign: 'center',
                                                marginBottom: '0.5rem',
                                            }}
                                        >
                                            {this.state.user.role}
                                        </p>
                                        {this.state.user.superAdmin ? (
                                            <p
                                                className="membership-plan"
                                                style={{
                                                    marginBottom: 0,
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <Label color={this.getPlanColor()}>{this.state.user.membership}</Label>
                                            </p>
                                        ) : null}
                                    </div>
                                )}

                                {/* <Button
                  onClick={this.toggleVisibility}
                  icon className="toggle-sidebar" circular color='blue' size='large'>
                  <Icon name='bars' color='red' inverted />
                </Button> */}
                            </section>

                            <div className="links">
                                <section className="top">
                                    {this.findRole('READ_REPORTS') ? (
                                        <Link to="/panel/dashboard">
                                            <Menu.Item
                                                onClick={() =>
                                                    this.setState({
                                                        currentPage: 'dashboard',
                                                    })
                                                }
                                                active={this.state.currentPage === 'dashboard'}
                                            >
                                                <Icon name="dashboard" />
                                                Dashboard
                                            </Menu.Item>
                                        </Link>
                                    ) : null}
                                    {this.findRole('READ_LOCATION') ? (
                                        <Link to="/panel/locations">
                                            <Menu.Item
                                                onClick={() =>
                                                    this.setState({
                                                        currentPage: 'locations',
                                                    })
                                                }
                                                active={this.state.currentPage === 'locations'}
                                            >
                                                <Icon name="map marker alternate" />
                                                Locations / Devices
                                            </Menu.Item>
                                        </Link>
                                    ) : null}
                                    {this.findRole('READ_ROLE') ? (
                                        <Link to="/panel/roles">
                                            <Menu.Item
                                                onClick={() =>
                                                    this.setState({
                                                        currentPage: 'roles',
                                                    })
                                                }
                                                active={this.state.currentPage === 'roles'}
                                            >
                                                <Icon name="sliders horizontal" />
                                                Roles
                                            </Menu.Item>
                                        </Link>
                                    ) : null}
                                    {this.findRole('READ_USER') ? (
                                        <Link to="/panel/users">
                                            <Menu.Item
                                                onClick={() =>
                                                    this.setState({
                                                        currentPage: 'users',
                                                    })
                                                }
                                                active={this.state.currentPage === 'users'}
                                            >
                                                <Icon>
                                                    <i className="fas fa-user-tie" />
                                                </Icon>
                                                Users
                                            </Menu.Item>
                                        </Link>
                                    ) : null}
                                    {this.findRole('READ_EMPLOYEE') ? (
                                        <Link to="/panel/employees">
                                            <Menu.Item
                                                onClick={() =>
                                                    this.setState({
                                                        currentPage: 'employees',
                                                    })
                                                }
                                                active={this.state.currentPage === 'employees'}
                                            >
                                                <Icon name="users" />
                                                Employees
                                            </Menu.Item>
                                        </Link>
                                    ) : null}
                                    {this.findRole('READ_ATTENDANCE_LOG') ? (
                                        <Link to="/panel/attendanceLog">
                                            <Menu.Item
                                                style={
                                                    this.state.user.membership !== 'Enterprise' &&
                                                    !this.state.user.superAdmin
                                                        ? { border: 'none' }
                                                        : {}
                                                }
                                                onClick={() =>
                                                    this.setState({
                                                        currentPage: 'attendanceLog',
                                                    })
                                                }
                                                active={this.state.currentPage === 'attendanceLog'}
                                            >
                                                <Icon>
                                                    <i className="fas fa-user-clock" />
                                                </Icon>
                                                Attendance Log
                                            </Menu.Item>
                                        </Link>
                                    ) : null}
                                    {/* Need to add a permission to READ_TIME_SHEET */}
                                    {this.findRole('READ_ATTENDANCE_LOG') ? (
                                        <Link to="/panel/timesheet">
                                            <Menu.Item
                                                style={
                                                    this.state.user.membership !== 'Enterprise' &&
                                                    !this.state.user.superAdmin
                                                        ? { border: 'none' }
                                                        : {}
                                                }
                                                onClick={() =>
                                                    this.setState({
                                                        currentPage: 'timeSheet',
                                                    })
                                                }
                                                active={this.state.currentPage === 'timeSheet'}
                                            >
                                                <Icon>
                                                    <i class="far fa-calendar-alt" />
                                                </Icon>
                                                Time Sheet
                                            </Menu.Item>
                                        </Link>
                                    ) : null}
                                    {this.state.user.membership === 'Enterprise' && this.findRole('READ_AUDIT_LOG') ? (
                                        <Link to="/panel/auditLog">
                                            <Menu.Item
                                                style={
                                                    this.state.user.membership !== 'Enterprise' &&
                                                    !this.state.user.superAdmin
                                                        ? { border: 'none' }
                                                        : {}
                                                }
                                                onClick={() =>
                                                    this.setState({
                                                        currentPage: 'auditLog',
                                                    })
                                                }
                                                active={this.state.currentPage === 'auditLog'}
                                            >
                                                <Icon>
                                                    <i className="fab fa-black-tie" />
                                                </Icon>
                                                Audit Log
                                            </Menu.Item>
                                        </Link>
                                    ) : null}
                                    {this.state.user.superAdmin ? (
                                        <React.Fragment>
                                            <Link to="/panel/membership">
                                                <Menu.Item
                                                    onClick={() =>
                                                        this.setState({
                                                            currentPage: 'membership',
                                                        })
                                                    }
                                                    active={this.state.currentPage === 'membership'}
                                                >
                                                    <Icon name="vcard" />
                                                    Subscription
                                                </Menu.Item>
                                            </Link>
                                            <Link to="/panel/invoices">
                                                <Menu.Item
                                                    style={{ border: 'none' }}
                                                    onClick={() =>
                                                        this.setState({
                                                            currentPage: 'invoices',
                                                        })
                                                    }
                                                    active={this.state.currentPage === 'invoices'}
                                                >
                                                    <Icon name="money bill alternate outline" />
                                                    Invoices
                                                </Menu.Item>
                                            </Link>
                                        </React.Fragment>
                                    ) : null}
                                </section>

                                <section className="bottom">
                                    <Link to="/panel/settings">
                                        <Button
                                            style={{
                                                background: '#DB1B31',
                                                color: 'whitesmoke',
                                            }}
                                        >
                                            <Icon name="settings" />
                                            Settings
                                        </Button>
                                    </Link>

                                    <Button color="black" onClick={this.handleLogout}>
                                        <Icon name="log out" />
                                        Logout
                                    </Button>
                                </section>
                            </div>
                        </Sidebar>

                        <Sidebar.Pusher style={{ overflow: 'auto' }}>
                            <Segment basic>
                                {!visible ? (
                                    <Popup
                                        trigger={
                                            <Button
                                                onClick={this.toggleVisibility}
                                                icon
                                                className="toggle-sidebar after"
                                                circular
                                                size="large"
                                                color="blue"
                                            >
                                                <Icon name="angle right" color="blue" inverted />
                                            </Button>
                                        }
                                        content="Show Sidebar"
                                        horizontalOffset={-15}
                                        verticalOffset={-8}
                                        position="right center"
                                        inverted
                                    />
                                ) : null}
                                <main id="application-content">
                                    <React.Fragment>
                                        <Route path="/panel/dashboard" component={Dashboard} />
                                        <Route path="/subscribe" component={Dashboard} />
                                        <Route
                                            path="/thanks"
                                            render={(props) => (
                                                <ThanksPage
                                                    onClose={this.props.onLogout}
                                                    {...props}
                                                    plan={this.state.user.membership}
                                                />
                                            )}
                                        />
                                        <Route
                                            path="/panel/membership"
                                            render={(props) => (
                                                <MembershipManager
                                                    onLogout={this.props.onLogout}
                                                    {...props}
                                                    plan={this.state.user.membership}
                                                />
                                            )}
                                        />
                                        <Route
                                            path="/panel/invoices"
                                            render={(props) => (
                                                <InvoicesTable {...props} plan={this.state.user.membership} />
                                            )}
                                        />
                                        <Route
                                            path="/panel/users"
                                            render={(props) => (
                                                <UsersTable
                                                    permissions={{
                                                        create: this.findRole('CREATE_USER'),
                                                        update: this.findRole('UPDATE_USER'),
                                                        delete: this.findRole('DELETE_USER'),
                                                        reports: this.findRole('EXPORT_USERS'),
                                                        assignRole: this.findRole('ASSIGN_ROLE'),
                                                    }}
                                                    {...props}
                                                    plan={this.state.user.membership}
                                                />
                                            )}
                                        />
                                        <Route
                                            path="/panel/employees"
                                            render={(props) => (
                                                <EmployeeTable
                                                    permissions={{
                                                        create: this.findRole('CREATE_EMPLOYEE'),
                                                        update: this.findRole('UPDATE_EMPLOYEE'),
                                                        delete: this.findRole('DELETE_EMPLOYEE'),
                                                        reports: this.findRole('EXPORT_EMPLOYEES'),
                                                    }}
                                                    {...props}
                                                    plan={this.state.user.membership}
                                                />
                                            )}
                                        />
                                        <Route
                                            path="/panel/locations"
                                            render={(props) => (
                                                <LocationsTable
                                                    permissions={{
                                                        create: this.findRole('CREATE_LOCATION'),
                                                        update: this.findRole('UPDATE_LOCATION'),
                                                        delete: this.findRole('DELETE_LOCATION'),
                                                    }}
                                                    {...props}
                                                    plan={this.state.user.membership}
                                                />
                                            )}
                                        />
                                        <Route
                                            path="/panel/roles"
                                            render={(props) => (
                                                <RolesTable
                                                    permissions={{
                                                        create: this.findRole('CREATE_ROLE'),
                                                        update: this.findRole('UPDATE_ROLE'),
                                                        delete: this.findRole('DELETE_ROLE'),
                                                    }}
                                                    {...props}
                                                    plan={this.state.user.membership}
                                                />
                                            )}
                                        />
                                        <Route
                                            path="/panel/attendanceLog"
                                            render={(props) => (
                                                <AttendanceLogTable
                                                    permissions={{
                                                        create: this.findRole('CREATE_ATTENDANCE_LOG'),
                                                        update: this.findRole('UPDATE_ATTENDANCE_LOG'),
                                                        reports: this.findRole('EXPORT_ATTENDANCE_LOG'),
                                                    }}
                                                    timezone={this.state.user.timezone}
                                                    {...props}
                                                    plan={this.state.user.membership}
                                                />
                                            )}
                                        />
                                        <Route
                                            path="/panel/timesheet"
                                            exact
                                            render={(props) => (
                                                <TimeSheetTable
                                                    permissions={{
                                                        create: this.findRole('CREATE_ATTENDANCE_LOG'),
                                                        update: this.findRole('UPDATE_ATTENDANCE_LOG'),
                                                        reports: this.findRole('EXPORT_ATTENDANCE_LOG'),
                                                    }}
                                                    timezone={this.state.user.timezone}
                                                    {...props}
                                                    plan={this.state.user.membership}
                                                />
                                            )}
                                        />
                                        <Route
                                            path="/panel/timesheet/:employeeId"
                                            exact
                                            render={(props) => (
                                                <EmployeeTimeSheet
                                                    permissions={{
                                                        create: this.findRole('CREATE_ATTENDANCE_LOG'),
                                                        update: this.findRole('UPDATE_ATTENDANCE_LOG'),
                                                        reports: this.findRole('EXPORT_ATTENDANCE_LOG'),
                                                    }}
                                                    timezone={this.state.user.timezone}
                                                    {...props}
                                                    plan={this.state.user.membership}
                                                />
                                            )}
                                        />
                                        {this.state.user.membership === 'Enterprise' ? (
                                            <Route
                                                path="/panel/auditLog"
                                                render={(props) => (
                                                    <AuditLogTable
                                                        timezone={this.state.user.timezone}
                                                        {...props}
                                                        plan={this.state.user.membership}
                                                    />
                                                )}
                                            />
                                        ) : null}
                                        <Route
                                            path="/panel/settings"
                                            render={() => <SettingsPage onChange={() => this.getData()} />}
                                        />
                                    </React.Fragment>
                                    <footer>
                                        <hr />
                                        <div className="footer-content">
                                            <p>
                                                ©2019, AttendLab® | All Rights Reserved to{' '}
                                                <a target="_blank" href="https://hayasolutions.com/">
                                                    Haya Solutions Inc.
                                                </a>
                                            </p>
                                            <p>
                                                <a target="_blank" href="https://attendlab.com/?page_id=283">
                                                    Terms of Use
                                                </a>
                                            </p>
                                            <p>
                                                <a target="_blank" href="https://attendlab.com/?page_id=3">
                                                    Privacy Policy
                                                </a>
                                            </p>
                                        </div>
                                    </footer>
                                </main>
                            </Segment>
                        </Sidebar.Pusher>
                    </Sidebar.Pushable>
                </div>
            </Router>
        );
    }
}

import React, {Component} from 'react'
import {Modal, Button, Table, Radio, Label, Dimmer, Loader} from 'semantic-ui-react'
import {Form} from 'formsy-semantic-ui-react'
import Axios from 'axios'
import config from 'react-global-configuration'
import {toast} from "react-toastify";

export default class AddRole extends Component {
    state = {
        role: {
            roleName: '',
            description: '',
            permissions: ["READ_COMPANY"],
        },
        loading: false
    };

    componentDidMount() {
        if (this.props.method === 'edit') {
            this.setState({loading: true});
            Axios.get(config.get('serverAPI').roles.get + '/' + this.props.id)
                .then(response => {
                    this.setState({
                        role: response.data.data
                    }, () => this.setState({loading: false}))
                })
        }
    }

    handleChange = (e, {name, value}) => {
        this.setState({
            role: {
                ...this.state.role,
                [name]: value
            }
        });
    };

    togglePermission = (permission) => {
        if (this.state.role.permissions.indexOf(permission) === -1) {
            this.setState({
                role: {
                    ...this.state.role,
                    permissions: [
                        ...this.state.role.permissions, permission
                    ]
                }
            })
        } else {
            if (permission.startsWith("READ")) {
                let type = "";
                if (permission.split("_").length === 2)
                    type = permission.split("_")[1];
                else type = permission.split("_")[1] + "_" + permission.split("_")[2];

                const found = this.state.role.permissions.filter(p => {
                    return p.endsWith(type);
                });

                const permissionsArray = [...this.state.role.permissions];
                if (found) {
                    for (const p of found) {
                        const index = permissionsArray.indexOf(p);
                        permissionsArray.splice(index, 1);
                    }

                    this.setState({
                        role: {
                            ...this.state.role,
                            permissions: permissionsArray
                        }
                    })
                }
            } else {
                const index = this.state.role.permissions.indexOf(permission);
                const permissionsArray = [...this.state.role.permissions];
                permissionsArray.splice(index, 1);
                this.setState({
                    role: {
                        ...this.state.role,
                        permissions: permissionsArray
                    }
                })
            }
        }
    };

    insertOne = () => {
        this.setState({loading: true});
        Axios.post(config.get('serverAPI').roles.get, this.state.role)
            .then(response => {
                toast.success("Role added successfully.");
                this.setState({loading: false});
                this.props.onSuccess()
            })
            .catch(err => {
                this.setState({loading: false});
            })
    };

    editOne = () => {
        this.setState({loading: true});
        Axios.put(config.get('serverAPI').roles.get + '/' + this.props.id, this.state.role)
            .then(response => {
                toast.success("Role modified successfully.");
                this.setState({loading: false});
                this.props.onSuccess()
            })
    };

    render() {
        const errorLabel = <Label color="red" pointing/>;
        return (
            <Modal open={true} onClose={this.props.onClose} closeIcon>
                <Modal.Header>
                    {this.props.method === 'edit' ? 'Edit Role' : 'Add New Role'}
                </Modal.Header>
                <Modal.Content>
                    <Dimmer active={this.state.loading} inverted>
                        <Loader inverted content='Loading'/>
                    </Dimmer>
                    <Form id="add-form" onValidSubmit={this.props.method === 'edit' ? this.editOne : this.insertOne}>
                        <Form.Input
                            label={this.props.method === 'edit' ? 'Role Name' : null}
                            value={this.state.role.roleName}
                            validations="isRequired"
                            validationErrors={{isRequired: 'Role Name is required.'}}
                            errorLabel={errorLabel}
                            fluid placeholder='Role Name' name='roleName' onChange={this.handleChange}
                        />
                        <Form.Input
                            label={this.props.method === 'edit' ? 'Description' : null}
                            value={this.state.role.description}
                            fluid placeholder='Description' name='description'
                            onChange={this.handleChange}/>
                    </Form>
                    <Table definition>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={3}/>
                                <Table.HeaderCell style={{textAlign: 'center'}}>View</Table.HeaderCell>
                                <Table.HeaderCell style={{textAlign: 'center'}}>Create</Table.HeaderCell>
                                <Table.HeaderCell style={{textAlign: 'center'}}>Delete</Table.HeaderCell>
                                <Table.HeaderCell style={{textAlign: 'center'}}>Update</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body style={{textAlign: 'center'}}>
                            <Table.Row>
                                <Table.Cell>User</Table.Cell>
                                <Table.Cell>
                                    <Radio
                                        checked={this.state.role.permissions.indexOf('READ_USER') !== -1}
                                        onClick={() => this.togglePermission('READ_USER')} toggle/>
                                </Table.Cell>
                                <Table.Cell>
                                    <Radio

                                        disabled={this.state.role.permissions.indexOf("READ_USER") === -1}
                                        checked={this.state.role.permissions.indexOf('CREATE_USER') !== -1}
                                        onClick={() => this.togglePermission('CREATE_USER')} toggle/>
                                </Table.Cell>
                                <Table.Cell>
                                    <Radio
                                        disabled={this.state.role.permissions.indexOf("READ_USER") === -1}
                                        checked={this.state.role.permissions.indexOf('DELETE_USER') !== -1}
                                        onClick={() => this.togglePermission('DELETE_USER')} toggle/>
                                </Table.Cell>
                                <Table.Cell>
                                    <Radio
                                        disabled={this.state.role.permissions.indexOf("READ_USER") === -1}
                                        checked={this.state.role.permissions.indexOf('UPDATE_USER') !== -1}
                                        onClick={() => this.togglePermission('UPDATE_USER')} toggle/>
                                </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>Role</Table.Cell>
                                <Table.Cell>
                                    <Radio
                                        checked={this.state.role.permissions.indexOf('READ_ROLE') !== -1}
                                        onClick={() => this.togglePermission('READ_ROLE')} toggle/>
                                </Table.Cell>
                                <Table.Cell>
                                    <Radio
                                        disabled={this.state.role.permissions.indexOf("READ_ROLE") === -1}
                                        checked={this.state.role.permissions.indexOf('CREATE_ROLE') !== -1}
                                        onClick={() => this.togglePermission('CREATE_ROLE')} toggle/>
                                </Table.Cell>
                                <Table.Cell>
                                    <Radio
                                        disabled={this.state.role.permissions.indexOf("READ_ROLE") === -1}
                                        checked={this.state.role.permissions.indexOf('DELETE_ROLE') !== -1}
                                        onClick={() => this.togglePermission('DELETE_ROLE')} toggle/>
                                </Table.Cell>
                                <Table.Cell>
                                    <Radio
                                        disabled={this.state.role.permissions.indexOf("READ_ROLE") === -1}
                                        checked={this.state.role.permissions.indexOf('UPDATE_ROLE') !== -1}
                                        onClick={() => this.togglePermission('UPDATE_ROLE')} toggle/>
                                </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>Employee</Table.Cell>
                                <Table.Cell>
                                    <Radio
                                        checked={this.state.role.permissions.indexOf('READ_EMPLOYEE') !== -1}
                                        onClick={() => this.togglePermission('READ_EMPLOYEE')} toggle/>
                                </Table.Cell>
                                <Table.Cell>
                                    <Radio
                                        disabled={this.state.role.permissions.indexOf("READ_EMPLOYEE") === -1}
                                        checked={this.state.role.permissions.indexOf('CREATE_EMPLOYEE') !== -1}
                                        onClick={() => this.togglePermission('CREATE_EMPLOYEE')} toggle/>
                                </Table.Cell>
                                <Table.Cell>
                                    <Radio
                                        disabled={this.state.role.permissions.indexOf("READ_EMPLOYEE") === -1}
                                        checked={this.state.role.permissions.indexOf('DELETE_EMPLOYEE') !== -1}
                                        onClick={() => this.togglePermission('DELETE_EMPLOYEE')} toggle/>
                                </Table.Cell>
                                <Table.Cell>
                                    <Radio
                                        disabled={this.state.role.permissions.indexOf("READ_EMPLOYEE") === -1}
                                        checked={this.state.role.permissions.indexOf('UPDATE_EMPLOYEE') !== -1}
                                        onClick={() => this.togglePermission('UPDATE_EMPLOYEE')} toggle/>
                                </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>Location</Table.Cell>
                                <Table.Cell>
                                    <Radio
                                        checked={this.state.role.permissions.indexOf('READ_LOCATION') !== -1}
                                        onClick={() => this.togglePermission('READ_LOCATION')} toggle/>
                                </Table.Cell>
                                <Table.Cell>
                                    <Radio
                                        disabled={this.state.role.permissions.indexOf("READ_LOCATION") === -1}
                                        checked={this.state.role.permissions.indexOf('CREATE_LOCATION') !== -1}
                                        onClick={() => this.togglePermission('CREATE_LOCATION')} toggle/>
                                </Table.Cell>
                                <Table.Cell>
                                    <Radio
                                        disabled={this.state.role.permissions.indexOf("READ_LOCATION") === -1}
                                        checked={this.state.role.permissions.indexOf('DELETE_LOCATION') !== -1}
                                        onClick={() => this.togglePermission('DELETE_LOCATION')} toggle/>
                                </Table.Cell>
                                <Table.Cell>
                                    <Radio
                                        disabled={this.state.role.permissions.indexOf("READ_LOCATION") === -1}
                                        checked={this.state.role.permissions.indexOf('UPDATE_LOCATION') !== -1}
                                        onClick={() => this.togglePermission('UPDATE_LOCATION')} toggle/>
                                </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>Device</Table.Cell>
                                <Table.Cell>
                                    <Radio
                                        checked={this.state.role.permissions.indexOf('READ_DEVICE') !== -1}
                                        onClick={() => this.togglePermission('READ_DEVICE')} toggle/>
                                </Table.Cell>
                                <Table.Cell>
                                    <Radio
                                        disabled={this.state.role.permissions.indexOf("READ_DEVICE") === -1}
                                        checked={this.state.role.permissions.indexOf('CREATE_DEVICE') !== -1}
                                        onClick={() => this.togglePermission('CREATE_DEVICE')} toggle/>
                                </Table.Cell>
                                <Table.Cell>
                                    <Radio
                                        disabled={this.state.role.permissions.indexOf("READ_DEVICE") === -1}
                                        checked={this.state.role.permissions.indexOf('DELETE_DEVICE') !== -1}
                                        onClick={() => this.togglePermission('DELETE_DEVICE')} toggle/>
                                </Table.Cell>
                                <Table.Cell>
                                    <Radio
                                        disabled={this.state.role.permissions.indexOf("READ_DEVICE") === -1}
                                        checked={this.state.role.permissions.indexOf('UPDATE_DEVICE') !== -1}
                                        onClick={() => this.togglePermission('UPDATE_DEVICE')} toggle/>
                                </Table.Cell>
                            </Table.Row>

                            {this.props.plan === "Enterprise" ? (
                                <Table.Row>
                                    <Table.Cell>Audit Log</Table.Cell>
                                    <Table.Cell>
                                        <Radio
                                            checked={this.state.role.permissions.indexOf('READ_AUDIT_LOG') !== -1}
                                            onClick={() => this.togglePermission('READ_AUDIT_LOG')} toggle/>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Radio disabled
                                               checked={this.state.role.permissions.indexOf('CREATE_AUDIT_LOG') !== -1}
                                               onClick={() => this.togglePermission('CREATE_AUDIT_LOG')} toggle/>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Radio disabled
                                               checked={this.state.role.permissions.indexOf('DELETE_AUDIT_LOG') !== -1}
                                               onClick={() => this.togglePermission('DELETE_AUDIT_LOG')} toggle/>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Radio disabled
                                               checked={this.state.role.permissions.indexOf('UPDATE_AUDIT_LOG') !== -1}
                                               onClick={() => this.togglePermission('UPDATE_AUDIT_LOG')} toggle/>
                                    </Table.Cell>
                                </Table.Row>
                            ) : null}

                            <Table.Row>
                                <Table.Cell>Attendance Log</Table.Cell>
                                <Table.Cell>
                                    <Radio
                                        checked={this.state.role.permissions.indexOf('READ_ATTENDANCE_LOG') !== -1}
                                        onClick={() => this.togglePermission('READ_ATTENDANCE_LOG')} toggle/>
                                </Table.Cell>
                                <Table.Cell>
                                    <Radio disabled
                                           checked={this.state.role.permissions.indexOf('CREATE_ATTENDANCE_LOG') !== -1}
                                           onClick={() => this.togglePermission('CREATE_ATTENDANCE_LOG')} toggle/>
                                </Table.Cell>
                                <Table.Cell>
                                    <Radio disabled
                                           checked={this.state.role.permissions.indexOf('DELETE_ATTENDANCE_LOG') !== -1}
                                           onClick={() => this.togglePermission('DELETE_ATTENDANCE_LOG')} toggle/>
                                </Table.Cell>
                                <Table.Cell>
                                    <Radio
                                        disabled={this.state.role.permissions.indexOf("READ_ATTENDANCE_LOG") === -1}
                                        checked={this.state.role.permissions.indexOf('UPDATE_ATTENDANCE_LOG') !== -1}
                                        onClick={() => this.togglePermission('UPDATE_ATTENDANCE_LOG')} toggle/>
                                </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>Dashboard</Table.Cell>
                                <Table.Cell>
                                    <Radio
                                        checked={this.state.role.permissions.indexOf('READ_REPORTS') !== -1}
                                        onClick={() => this.togglePermission('READ_REPORTS')} toggle/>
                                </Table.Cell>
                                <Table.Cell>
                                    <Radio disabled
                                           checked={this.state.role.permissions.indexOf('CREATE_REPORTS') !== -1}
                                           onClick={() => this.togglePermission('CREATE_REPORTS')} toggle/>
                                </Table.Cell>
                                <Table.Cell>
                                    <Radio disabled
                                           checked={this.state.role.permissions.indexOf('DELETE_REPORTS') !== -1}
                                           onClick={() => this.togglePermission('DELETE_REPORTS')} toggle/>
                                </Table.Cell>
                                <Table.Cell>
                                    <Radio disabled
                                           checked={this.state.role.permissions.indexOf('UPDATE_REPORTS') !== -1}
                                           onClick={() => this.togglePermission('UPDATE_REPORTS')} toggle/>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.props.onClose} color='red'>Cancel</Button>
                    <Button type='submit' form='add-form' color='blue'>
                        {this.props.method === 'edit' ? 'Apply' : 'Add'}
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }
}

import './index.css'
import React, {Component} from 'react'
import {Button, Checkbox, Form, Icon, Pagination, Popup, Table, Dimmer, Loader, Dropdown} from 'semantic-ui-react'
import config from 'react-global-configuration'
import axios from 'axios'
import DeleteModal from "./DeleteModal"
import AddModal from "./AddModal"
import download from 'downloadjs'
import AssignRoleModal from './AssignRoleModal'
import printJS from 'print-js'
import CannotAddModal from "../Employees/CannotAddModal";
import {toast} from "react-toastify";

export default class UsersTable extends Component {
    state = {
        tableData: [],
        meta: {},
        loading: false,
        deleteUsers: {
            modalVisible: false,
            id: null,
            multiple: false
        },
        queryParams: {
            filter: {},
            query: null,
            sort: {},
            page: 0
        },
        selectedRows: {},
        allRowsSelected: false,
        addModalVisible: false,
        editId: '',
        addOrEdit: 'add',
        roles: [],
        isSuperAdmin: false,
        assignRole: {
            userID: '',
            username: '',
            active: false
        },
        canAddUsers: true,
        cannotAddModalVisible: false,
    };

    getData = () => {
        this.setState({loading: true});
        axios.get(config.get('serverAPI').users.get, {params: this.state.queryParams})
            .then((response) => {
                this.setState({
                    tableData: response.data.data,
                    meta: response.data.meta,
                }, () => {
                    this.getAllowedUers();
                });
            })
            .catch(error => {

            })
    };

    getAllowedUers = () => {
        axios.get(config.get('serverAPI').subscription.stats)
            .then(response => {
                this.setState({
                    canAddUsers: response.data.data.user.allowed > response.data.data.user.count,
                    loading: false
                })
            })
            .catch(err => {
                this.setState({loading: false});
            })
    };

    fillRoles = () => {
        axios.get(config.get('serverAPI').roles.filter)
            .then(response => {
                const filterByRoleOptions = [{
                    key: null,
                    text: 'All',
                    value: null
                }];
                response.data.data.forEach(role => {
                    const option = {
                        key: role._id,
                        text: role.roleName,
                        value: role._id
                    };
                    filterByRoleOptions.push(option)
                });
                this.setState({
                    roles: filterByRoleOptions
                })
            })
    };

    filterByRole = (value) => {
        this.setState({
            queryParams: {
                ...this.state.queryParams,
                filter: value === null ? {} : {
                    role: value
                },
            },
            selectedRows: {},
            allRowsSelected: false,
        }, () => this.getData())
    };

    componentDidMount() {
        document.title = 'AttendLab | Users';
        this.getData();
        this.fillRoles()
    }

    handleSearchTextChange = (e, {name, value}) => this.setState({
        queryParams: {
            ...this.state.queryParams,
            [name]: value
        },
        selectedRows: {},
        allRowsSelected: false,
    });

    handleSubmit = () => {
        const {query} = this.state.queryParams;

        this.setState({
            queryParams: {
                ...this.state.queryParams,
                query: query
            }
        }, () => this.getData());
    };

    handlePaginationChange = (e, {activePage}) => this.setState({
        queryParams: {
            ...this.state.queryParams,
            page: activePage - 1
        }
    }, () => this.getData());

    toggleSelectAll = () => {
        if (!this.state.allRowsSelected) {
            const selected = {};
            for (const row of this.state.tableData) {
                selected[row._id] = true;
            }
            this.setState({
                selectedRows: {
                    ...selected
                },
                allRowsSelected: true
            })
        } else {
            this.setState({
                selectedRows: {},
                allRowsSelected: false
            })
        }
    };

    toggleSelect = (id) => {
        if (this.state.selectedRows[id]) {
            const {[id]: value, ...withRemovedId} = this.state.selectedRows;
            this.setState({
                selectedRows: withRemovedId,
                allRowsSelected: false
            })
        } else {
            this.setState({
                    selectedRows: {
                        ...this.state.selectedRows,
                        [id]: true,
                    },
                }, () => {
                    if (Object.keys(this.state.selectedRows).length === this.state.tableData.length)
                        this.setState({
                            allRowsSelected: true
                        });
                }
            );
        }
    };

    handleSort = (clickedColumn) => {
        const sortDirection = this.state.queryParams.sort[clickedColumn];
        if (sortDirection) {
            if (sortDirection === 1) {
                this.setState({
                    queryParams: {
                        ...this.state.queryParams,
                        sort: {
                            [clickedColumn]: -1
                        }
                    }
                }, () => {
                    this.getData()
                })
            } else {
                this.setState({
                    queryParams: {
                        ...this.state.queryParams,
                        sort: {
                            [clickedColumn]: 1
                        }
                    }
                }, () => {
                    this.getData()
                })
            }
        } else {
            this.setState({
                queryParams: {
                    ...this.state.queryParams,
                    sort: {
                        [clickedColumn]: 1
                    }
                }
            }, () => {
                this.getData()
            })
        }
    };

    sortDirection = (clickedColumn) => {
        const sortDirection = this.state.queryParams.sort[clickedColumn];
        if (sortDirection) {
            if (sortDirection === 1) return 'ascending';
            else if (sortDirection === -1) return 'descending';
        }
        return null
    };

    onAddClicked = () => {
        if (this.state.canAddUsers) {
            this.setState({addModalVisible: true, addOrEdit: 'add'});
        } else {
            this.setState({cannotAddModalVisible: true})
        }
    };

    deleteUsers = (id) => {
        this.setState({
            deleteUsers: {
                modalVisible: true,
                id: id,
            }
        });
    };

    deleteSelectedUsers = () => {
        const rowsToDelete = [];
        for (const row in this.state.selectedRows) {
            const rowSelected = this.state.selectedRows[row];
            if (rowSelected) rowsToDelete.push(row)
        }

        this.setState({
            deleteUsers: {
                modalVisible: true,
                id: rowsToDelete,
                multiple: true
            }
        })
    };

    exportSelectedRows = () => {
        const rowsToExport = [];
        for (const row in this.state.selectedRows) {
            const rowSelected = this.state.selectedRows[row];
            if (rowSelected) rowsToExport.push(row)
        }
        if (rowsToExport.length === 0) {
            for (const row of this.state.tableData)
                rowsToExport.push(row._id)
        }

        axios.post(config.get('serverAPI').users.export, {
            ids: rowsToExport
        }, {
            params: this.state.queryParams
        })
            .then(response => {
                download(config.get('exportLink') + '/assets/exports/' + response.data.data.path)
            })
    };

    exportSelectedRowsAsPdf = () => {
        const rowsToExport = [];
        for (const row in this.state.selectedRows) {
            const rowSelected = this.state.selectedRows[row];
            if (rowSelected) rowsToExport.push(row)
        }
        if (rowsToExport.length === 0) {
            for (const row of this.state.tableData)
                rowsToExport.push(row._id)
        }

        axios.post(config.get('serverAPI').users.exportPdf, {
            ids: rowsToExport
        })
            .then(response => {
                download(config.get('exportLink') + '/assets/exports/' + response.data.data.path)
            })
    };

    printTable = () => {
        const rowsToExport = [];
        for (const row in this.state.selectedRows) {
            const rowSelected = this.state.selectedRows[row];
            if (rowSelected) rowsToExport.push(row)
        }
        if (rowsToExport.length === 0) {
            for (const row of this.state.tableData)
                rowsToExport.push(row._id)
        }

        axios.post(config.get('serverAPI').users.exportPdf, {
            ids: rowsToExport
        })
            .then(response => {
                printJS(config.get('exportLink') + '/assets/exports/' + response.data.data.path)
            })
    };

    confirmDelete = () => {
        axios.post(config.get('serverAPI').users.delete, {
            ids: this.state.deleteUsers.multiple ? this.state.deleteUsers.id : [this.state.deleteUsers.id]
        })
            .then(() => {
                toast.success("User deleted successfully.");
                this.setState({
                    deleteUsers: {
                        modalVisible: false
                    }
                });
                this.getData()
            });
    };

    editUsers = (id, isSuperAdmin) => {
        this.setState({
            editId: id,
            addModalVisible: true,
            addOrEdit: 'edit',
            isSuperAdmin: isSuperAdmin
        })
    };

    handleSuccess = () => {
        this.setState({
            addModalVisible: false,
        });
        this.getData();
    };

    getRoleText = () => {
        const text = this.state.roles.find(e => e.key === this.state.queryParams.filter.role);
        if (text)
            return text.text;
        return 'All';
    };

    handleAssignRoleButtonClick = (row) => {
        this.setState({
            assignRole: {
                userID: row._id,
                username: row.firstName + ' ' + row.lastName,
                active: true
            }
        })
    };

    render() {
        return (
            <section id="users-table">
                <section className="table-actions">
                    <div className="search-box">
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Input
                                name='query'
                                fluid loading={this.state.loading}
                                onChange={this.handleSearchTextChange}
                                icon={{name: 'search'}}
                                placeholder='Search...'
                            />
                        </Form>
                    </div>

                    <div className="actions">
                        {this.props.plan !== "Free" && this.props.permissions.reports ? (
                            <React.Fragment>
                                <Button
                                    onClick={this.exportSelectedRows}
                                    content='Export to CSV'
                                    icon='file excel outline'
                                />

                                {/*<Button*/}
                                {/*    onClick={this.exportSelectedRowsAsPdf}*/}
                                {/*    content='Export to PDF'*/}
                                {/*    icon='file pdf outline'*/}
                                {/*/>*/}

                                {/*<Button*/}
                                {/*    onClick={this.printTable}*/}
                                {/*    content='Print'*/}
                                {/*    icon='print'*/}
                                {/*/>*/}
                            </React.Fragment>
                        ) : null}
                        <Dropdown
                            text='Filter'
                            icon='filter'
                            floating
                            labeled
                            button
                            className='icon'
                        >
                            <Dropdown.Menu style={{left: 'auto', right: 0}}>
                                <Dropdown.Header content='Filter by Role'/>
                                {this.state.roles.map((role) => {
                                    return (
                                        <Dropdown.Item
                                            onClick={() => this.filterByRole(role.value)}>
                                            {role.text}
                                        </Dropdown.Item>
                                    )
                                })}
                            </Dropdown.Menu>
                        </Dropdown>

                        {this.props.permissions.create ? (
                            <Popup trigger={
                                <Button icon onClick={this.onAddClicked}>
                                    <Icon name='add user'/>
                                </Button>
                            } content="Add User" size='mini'/>
                        ) : null}
                    </div>
                </section>

                <Table celled compact definition sortable>
                    <Dimmer active={this.state.loading} inverted>
                        <Loader size='medium'>Loading</Loader>
                    </Dimmer>
                    <Table.Header fullWidth>
                        <Table.Row>
                            <Table.HeaderCell>
                                <Checkbox
                                    onChange={this.toggleSelectAll}
                                    checked={this.state.allRowsSelected && Object.keys(this.state.selectedRows).length > 0}
                                />
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={this.sortDirection('firstName')}
                                onClick={() => this.handleSort('firstName')}
                            >
                                First Name
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={this.sortDirection('lastName')}
                                onClick={() => this.handleSort('lastName')}
                            >Last Name</Table.HeaderCell>
                            <Table.HeaderCell>Phone</Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={this.sortDirection('email')}
                                onClick={() => this.handleSort('email')}
                            >Email</Table.HeaderCell>
                            <Table.HeaderCell>Role</Table.HeaderCell>
                            {this.props.permissions.update || this.props.permissions.delete ? (
                                <Table.HeaderCell width={1} style={{textAlign: 'center'}}>
                                    <Popup trigger={
                                        <Button
                                            onClick={this.deleteSelectedUsers}
                                            circular compact basic content=''
                                            color='red' icon='trash alternate'
                                            disabled={Object.keys(this.state.selectedRows).length === 0 || !this.props.permissions.delete}
                                        />
                                    } content='Delete Selected' size='mini'/>
                                </Table.HeaderCell>
                            ) : null}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.state.tableData.map(row =>
                            <Table.Row key={row._id} active={this.state.selectedRows[row._id]}>
                                <Table.Cell collapsing>
                                    <Checkbox
                                        checked={this.state.selectedRows[row._id]}
                                        onChange={() => this.toggleSelect(row._id)}
                                    />
                                </Table.Cell>
                                <Table.Cell>{row.firstName}</Table.Cell>
                                <Table.Cell>{row.lastName}</Table.Cell>
                                <Table.Cell>{row.phone}</Table.Cell>
                                <Table.Cell>{row.email}</Table.Cell>
                                <Table.Cell>
                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        {row.role === null ? <span style={{color: 'darkgrey'}}>No role</span> :
                                            <span>{row.role.roleName}</span>}
                                        {this.props.permissions.assignRole ? (
                                            <Popup trigger={
                                                <Button
                                                    circular compact basic icon='sliders'
                                                    onClick={() => this.handleAssignRoleButtonClick(row)}/>
                                            } content='Assign Role' size='mini'/>
                                        ) : null}
                                    </div>
                                </Table.Cell>
                                {this.props.permissions.update || this.props.permissions.delete ? (
                                    <Table.Cell>
                                        <div className="actions"
                                             style={{display: 'flex', justifyContent: 'space-around'}}>
                                            {this.props.permissions.update ? (
                                                <Popup trigger={
                                                    <Button circular compact basic icon='edit'
                                                            onClick={() => this.editUsers(row._id, row.role.superAdmin)}/>
                                                } content='Edit User' size='mini'/>
                                            ) : null}
                                            {this.props.permissions.delete ? (
                                                <Popup trigger={
                                                    <Button
                                                        onClick={() => this.deleteUsers(row._id)}
                                                        circular compact basic
                                                        color='red' icon='trash alternate'/>
                                                } content='Delete User' size='mini'/>
                                            ) : null}
                                        </div>
                                    </Table.Cell>
                                ) : null}
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>

                {this.state.tableData.length === 0 && !this.state.loading ? (
                    <p style={{textAlign: 'center'}}>No Results!</p>
                ) : null}

                <section style={{display: 'flex', justifyContent: 'center'}}>
                    {this.state.meta.totalRecords >= this.state.meta.pageLimit ? (
                        <Pagination
                            onPageChange={this.handlePaginationChange}
                            defaultActivePage={this.state.meta.currentPage + 1}
                            totalPages={Math.ceil(this.state.meta.totalRecords / this.state.meta.pageLimit)}
                        />
                    ) : null}
                </section>

                {this.state.deleteUsers.modalVisible ? (
                    <DeleteModal model='user' onConfirm={this.confirmDelete}
                                 onClose={() => this.setState({deleteUsers: {modalVisible: false}})}/>
                ) : null}

                {
                    this.state.addModalVisible ? (
                        <AddModal
                            isSuperAdmin={this.state.isSuperAdmin}
                            method={this.state.addOrEdit}
                            id={this.state.editId}
                            onSuccess={this.handleSuccess}
                            onClose={() => this.setState({addModalVisible: false})}/>
                    ) : null
                }

                {this.state.cannotAddModalVisible ? (
                    <CannotAddModal
                        onClose={() => this.setState({cannotAddModalVisible: false})}
                        plan={this.props.plan}
                        unitType="users"
                    />
                ) : null}

                {this.state.assignRole.active ? (
                        <AssignRoleModal
                            id={this.state.assignRole.userID}
                            username={this.state.assignRole.username}
                            onClose={
                                () => this.setState({
                                    assignRole: {...this.state.assignRole, active: false}
                                })
                            }
                            onSuccess={() => this.setState({
                                assignRole: {...this.state.assignRole, active: false}
                            }, () => this.getData())}
                        />)
                    : null}

            </section>
        )
    }
}

import React, { Component } from 'react'
import { Button, Grid, Header, Label, Divider, Icon, Image, Segment, Message } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import Axios from 'axios'
import config from 'react-global-configuration'

export default class Register extends Component {
    state = {
        email: '',
        password: '',
        code: '',
        loading: false,
        status: 'email',
        emailInvalid: false
    };

    handleChange = (e, { name, value }) => {
        this.setState({
            [name]: value
        });
    };

    sendEmail = () => {
        this.setState({ loading: true });
        Axios.post(config.get('serverAPI').users.forget_password, { email: this.state.email })
            .then(Response => {
                this.setState({ loading: false, status: 'code' });
            })
            .catch(error => {
                this.setState({ emailInvalid: true, loading: false })
            });
    };

    resetPassword = () => {
        this.setState({ loading: true });
        Axios.post(config.get('serverAPI').users.change_password, {
            code: this.state.code,
            password: this.state.password,
            email: this.state.email
        })
            .then(response => {
                this.setState({ loading: false });
                this.props.onClose({ reset: true })
            })
            .catch(error => {
                this.setState({ loading: false })
            });
    };

    render() {
        const errorLabel = <Label color="red" pointing />;
        return (
            <Grid textAlign='center' style={{ height: '100%', overflow: 'auto' }} verticalAlign='middle'>
                <Grid.Column style={{ maxWidth: 450 }}>
                    <section>
                        <Header as='h3' textAlign='center' style={{color: "#4360BA"}}>
                            RESET PASSWORD
                        </Header>
                        {
                            this.state.status === 'email' ? (
                                <Form onValidSubmit={this.sendEmail}>
                                    <Form.Input
                                        value={this.state.email}
                                        validations="isRequired,isEmail"
                                        validationErrors={{ isRequired: 'Email is required', isEmail: 'Email not valid' }}
                                        errorLabel={errorLabel}
                                        fluid placeholder='Type your email.' name='email' onChange={this.handleChange}
                                    />
                                    <Button
                                        className="login-button"
                                        size="big"
                                        type='submit' loading={this.state.loading} style={{ background: "#4360BA", color: "whitesmoke" }} fluid>
                                        Next
                                        <Icon name='arrow right' />
                                    </Button>
                                    {this.state.emailInvalid ? (
                                        <Message negative>
                                            This email is not registered.
                                        </Message>
                                    ) : null}
                                </Form>
                            ) : (
                                    <Form onValidSubmit={this.resetPassword}>
                                        <p style={{ textAlign: 'center' }}>
                                            Please check your email for authentication code.
                                    </p>
                                        <Form.Input
                                            value={this.state.code}
                                            validations="isRequired"
                                            validationErrors={{ isRequired: 'Authentication code is required.' }}
                                            errorLabel={errorLabel}
                                            fluid placeholder='Authentication key' name='code' onChange={this.handleChange}
                                        />
                                        <Form.Group widths='equal'>
                                            <Form.Input
                                                type='password'
                                                value={this.state.password}
                                                validations={{ matchRegexp: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[A-Za-z0-9!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]{8,}$/ }}
                                                validationError="Password must have minimum length of 8,at least 1 upper case letter, 1 number and 1 special character."
                                                errorLabel={errorLabel}
                                                fluid placeholder='New Password' name='password'
                                                onChange={this.handleChange}
                                            />
                                            <Form.Input
                                                type='password'
                                                validations="equalsField:password"
                                                validationErrors={{ 'equalsField': 'Two passwords do not match.' }}
                                                errorLabel={errorLabel}
                                                fluid placeholder='Confirm Password' name='confirmPassword' />
                                        </Form.Group>
                                        <Button
                                            className="login-button"
                                            size="big"
                                            type='submit' loading={this.state.loading} style={{ background: "#4360BA", color: "whitesmoke" }} fluid>
                                            Reset Password
                                        <Icon name='arrow right' />
                                        </Button>
                                    </Form>
                                )
                        }
                        <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'space-around' }}>
                            <a
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.props.onClose({ reset: false })
                                }}
                                style={{ textDecoration: 'underline', color: "#4360BA" }}
                                href='#'>Back to Login</a>
                        </div>
                    </section>
                </Grid.Column>
            </Grid>
        )
    }
}
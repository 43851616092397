import React, { Component } from 'react';
import './index.css';
import { Header, Modal, Table, Dimmer, Loader, Icon, Label, Button, Dropdown, Form } from 'semantic-ui-react';

import axios from 'axios';
import config from 'react-global-configuration';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { empTimeSheet } from './fakeData';
import download from 'downloadjs';

export default class EmployeeTimeSheet extends Component {
    state = {
        employee: {
            _id: '',
            firstName: 'Employee',
            lastName: '',
        },
        tableData: [],
        queryParams: {
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            employee: '',
        },
        loading: false,
    };

    componentDidMount() {
        const startDate = new URLSearchParams(this.props.location.search).get('startDate');
        const endDate = new URLSearchParams(this.props.location.search).get('endDate');
        this.setState(
            {
                queryParams: {
                    ...this.state.queryParams,
                    startDate,
                    endDate,
                    employee: this.props.match.params.employeeId,
                },
            },
            () => this.getData()
        );
    }

    getData = () => {
        this.setState({ loading: true });
        axios
            .get(`${config.get('serverAPI').timeSheet.get}/${this.props.match.params.employeeId}`, {
                params: this.state.queryParams,
            })
            .then((response) => {
                this.setState({
                    employee: response.data.data.employee,
                    tableData: response.data.data.perDay,
                    loading: false,
                });
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                });
            });
    };

    exportToCSV = () => {
        axios
            .post(config.get('serverAPI').timeSheet.exportDetailed, null, {
                params: this.state.queryParams,
            })
            .then((response) => {
                download(config.get('exportLink') + '/assets/exports/' + response.data.data.path);
            });
    };

    exportToPdf = () => {
        axios
            .post(config.get('serverAPI').timeSheet.exportPdfDetailed, null, {
                params: this.state.queryParams,
            })
            .then((response) => {
                download(config.get('exportLink') + '/assets/exports/' + response.data.data.path);
            });
    };

    getTotalHours = (th) => {
        let totalHours = th * 60 * 60;
        let hours = Math.floor(totalHours / 3600);
        totalHours -= hours * 3600;
        let minutes = Math.floor(totalHours / 60) % 60;
        totalHours -= minutes * 60;
        let seconds = Math.floor(totalHours % 60);
        const result = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${
            seconds < 10 ? '0' + seconds : seconds
        }`;

        return result;
    };

    render() {
        return (
            <section id="employee-time-sheet-table">
                <section className="table-actions">
                    <div className="header">
                        <Header as="h2">
                            <Link
                                to={`/panel/timesheet?startDate=${this.state.queryParams.startDate}&endDate=${this.state.queryParams.endDate}`}
                            >
                                <Icon name="arrow circle left" style={{ cursor: 'pointer' }} />
                            </Link>
                            <Header.Content>{`${this.state.employee.firstName} ${this.state.employee.lastName} Time Sheet`}</Header.Content>
                        </Header>
                    </div>

                    <div className="actions">
                        {this.props.plan !== 'Free' && this.props.permissions.reports ? (
                            <React.Fragment>
                                <Dropdown
                                    text="Export to CSV"
                                    icon="file excel outline"
                                    floating
                                    labeled
                                    button
                                    className="icon"
                                >
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={this.exportToCSV}>Normal Export</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Button onClick={this.exportToPdf} content="Export to PDF" icon="file pdf outline" />
                            </React.Fragment>
                        ) : null}
                    </div>
                </section>

                <Dimmer active={this.state.loading} inverted>
                    <Loader inverted content="Loading" />
                </Dimmer>
                <Label.Group color="blue" style={{ marginTop: '1rem' }}>
                    <Label as="a">
                        Date From:
                        <span style={{ margin: '0 0.5rem' }}>
                            {moment(this.state.queryParams.startDate).format('MM/DD/YYYY')}
                        </span>
                        to
                        <span style={{ margin: '0 0.5rem' }}>
                            {moment(this.state.queryParams.endDate).format('MM/DD/YYYY')}
                        </span>
                    </Label>
                </Label.Group>
                <Table celled compact definition sortable>
                    <Table.Header fullWidth>
                        <Table.Row>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.HeaderCell>In</Table.HeaderCell>
                            <Table.HeaderCell>Out</Table.HeaderCell>
                            <Table.HeaderCell>Total Hours</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {this.state.tableData
                            .sort(
                                (a, b) =>
                                    moment(`${a.year}-${a.month}-${a.day}`) - moment(`${b.year}-${b.month}-${b.day}`)
                            )
                            .map((day) => (
                                <Table.Row key={day.attendance.firstCheckIn}>
                                    <Table.Cell>{`${day.day} ${moment(day.month, 'MM').format('MMMM')} ${
                                        day.year
                                    }`}</Table.Cell>
                                    <Table.Cell>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            {day.attendance.firstCheckIn === 0 ? (
                                                <Label size="small" color="orange">
                                                    MISSED
                                                </Label>
                                            ) : (
                                                `${moment.utc(day.attendance.firstCheckIn).format('HH:mm:ss')}`
                                            )}
                                            <Icon
                                                color="green"
                                                style={{ marginLeft: '0.5rem' }}
                                                name="arrow alternate circle left outline"
                                            ></Icon>
                                        </div>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            {day.attendance.lastCheckOut === 0 ? (
                                                <Label size="small" color="orange">
                                                    MISSED
                                                </Label>
                                            ) : (
                                                `${moment.utc(day.attendance.lastCheckOut).format('HH:mm:ss')}`
                                            )}
                                            <Icon
                                                color="red"
                                                style={{ marginLeft: '0.5rem' }}
                                                name="arrow alternate circle right outline"
                                            ></Icon>
                                        </div>
                                    </Table.Cell>
                                    <Table.Cell>{this.getTotalHours(day.attendance.totalHours)}</Table.Cell>
                                </Table.Row>
                            ))}
                    </Table.Body>
                </Table>
            </section>
        );
    }
}

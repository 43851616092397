import React, {Component} from 'react'
import {Button, Header, Icon, Modal} from 'semantic-ui-react'

class UpgradeConfirmationModal extends Component {
    confirmUpgrade = () => {
        this.props.onConfirm();
    };

    render() {
        return (
            <Modal
                open={true} basic size='small' onClose={this.props.onClose} closeIcon
            >
                <Header icon='sort amount up' content={'Upgrade to ' + this.props.upgradePlan}/>
                <Modal.Content>
                    <p>
                        Are you sure you want to upgrade to <span style={{fontWeight: 'bold'}}>{this.props.upgradePlan}</span> plan
                    </p>
                    <p>
                        Notes
                    </p>
                    <ul>
                        <li>Subscription changes could take a little while.</li>
                        <li>During subscription changes, you will not be able to login.</li>
                        <li>A verification email will be sent to you once the subscription changes completed.</li>
                    </ul>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='grey' basic onClick={this.props.onClose}>
                        <Icon name='remove'/> Cancel
                    </Button>
                    <Button color='green' size="big" inverted onClick={this.confirmUpgrade}>
                        <Icon name='checkmark'/> Yes, upgrade
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
};

export default UpgradeConfirmationModal;
import './index.css';
import React, { Component } from 'react';
import { Button, Form, Pagination, Table, Dimmer, Loader, Dropdown, Modal, Label } from 'semantic-ui-react';
import config from 'react-global-configuration';
import axios from 'axios';
import 'moment-timezone';
import moment from 'moment';
import { DatesRangeInput } from 'semantic-ui-calendar-react';
import TimeSheetDetailsModal from './TimeSheetDetailsModal';
import { Link } from 'react-router-dom';
import { timeSheets } from './fakeData';
import download from 'downloadjs';

export default class TimeSheetTable extends Component {
    state = {
        timeRangeModalVisible: false,
        tableData: [],
        employeeSearchKey: '',
        showEmployeeTimeSheetDetails: false,
        employeeDetailsId: '',
        employeeDetailsName: '',
        meta: {},
        datesRange: '',
        loading: false,
        queryParams: {
            startDate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            searchKey: '',
            page: 0,
        },
    };

    componentDidMount() {
        document.title = 'AttendLab | Time Sheet';
        const startDate = new URLSearchParams(this.props.location.search).get('startDate');
        const endDate = new URLSearchParams(this.props.location.search).get('endDate');
        if (startDate && endDate) {
            console.log('🚀 ~ file: index.js ~ line 36 ~ TimeSheetTable ~ componentDidMount ~ startDate', startDate);
            console.log('🚀 ~ file: index.js ~ line 38 ~ TimeSheetTable ~ componentDidMount ~ endDate', endDate);
            this.setState(
                {
                    datesRange: `${moment(startDate).format('MM/DD/YYYY')} - ${moment(endDate).format('MM/DD/YYYY')}`,
                    queryParams: {
                        ...this.state.queryParams,
                        startDate,
                        endDate,
                    },
                },
                () => this.getData()
            );
        } else {
            this.getData();
        }
    }

    getData = () => {
        this.setState({ loading: true });
        console.log(
            '🚀 ~ file: index.js ~ line 40 ~ TimeSheetTable ~ .get ~ this.state.queryParams',
            this.state.queryParams
        );
        axios
            .get(config.get('serverAPI').timeSheet.get, {
                params: this.state.queryParams,
            })
            .then((response) => {
                console.log(`response`, response);
                this.setState({
                    tableData: response.data.data,
                    meta: response.data.meta,
                    loading: false,
                });
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                });
            });
    };

    getFilteredEmployees = () => {
        axios
            .get(config.get('serverAPI').timeSheet.filter, {
                params: { searchKey: this.state.employeeSearchKey },
            })
            .then((response) => {
                console.log(
                    '🚀 ~ file: index.js ~ line 80 ~ TimeSheetTable ~ response',
                    this.state.employeeSearchKey,
                    response.data.data[0]._id
                );
                this.setState({
                    queryParams: {
                        ...this.state.queryParams,
                        employee: response.data.data[0]._id,
                    },
                });
            })
            .catch((error) => {})
            .then(() => {
                this.getData();
            });
    };

    handlePaginationChange = (e, { activePage }) =>
        this.setState(
            {
                queryParams: {
                    ...this.state.queryParams,
                    page: activePage - 1,
                },
            },
            () => this.getData()
        );

    handleSearchTextChange = (e, { name, value }) => {
        console.log(`name, value`, name, value);
        this.setState({
            queryParams: {
                ...this.state.queryParams,
                searchKey: value,
            },
        });
    };

    closeEmployeeTimeSheetDetailsModal = () => this.setState({ showEmployeeTimeSheetDetails: false });
    openEmployeeTimeSheetDetailsModal = (employee) => {
        console.log('🚀 ~ file: index.js ~ line 132 ~ TimeSheetTable ~ employee._id', employee._id);

        this.setState({
            employeeDetailsId: employee._id,
            employeeDetailsName: `${employee.firstName} ${employee.lastName}`,
            showEmployeeTimeSheetDetails: true,
        });
    };

    handleSubmit = () => {
        this.getData();
    };

    exportToCSV = () => {
        axios
            .post(config.get('serverAPI').timeSheet.export, null, {
                params: this.state.queryParams,
            })
            .then((response) => {
                console.log(
                    '🚀 ~ file: index.js ~ line 149 ~ TimeSheetTable ~ .then ~ response',
                    config.get('exportLink') + '/assets/exports/' + response.data.data.path
                );
                download(config.get('exportLink') + '/assets/exports/' + response.data.data.path);
            });
    };

    exportToPdf = () => {
        axios
            .post(config.get('serverAPI').timeSheet.exportPdf, null, {
                params: this.state.queryParams,
            })
            .then((response) => {
                download(config.get('exportLink') + '/assets/exports/' + response.data.data.path);
            });
    };

    handleTimeRangeChange = (event, { name, value }) => {
        if (this.state.hasOwnProperty(name)) {
            this.setState({ [name]: value });
        }
    };

    setTimeRange = () => {
        const dates = this.state.datesRange.split(' - ');
        this.setState(
            {
                queryParams: {
                    ...this.state.queryParams,
                    startDate: moment(dates[0]).format('YYYY-MM-DD'),
                    endDate: moment(dates[1]).format('YYYY-MM-DD'),
                },
            },
            () => {
                this.setState({ timeRangeModalVisible: false });
                this.getData();
            }
        );
    };

    getTotalHours = (th) => {
        let totalHours = th * 60 * 60;
        let hours = Math.floor(totalHours / 3600);
        totalHours -= hours * 3600;
        let minutes = Math.floor(totalHours / 60) % 60;
        totalHours -= minutes * 60;
        let seconds = Math.floor(totalHours % 60);
        const result = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${
            seconds < 10 ? '0' + seconds : seconds
        }`;

        return result;
    };

    render() {
        return (
            <section id="time-sheet-table">
                <section className="table-actions">
                    <div className="search-box">
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Input
                                list="languages"
                                name="searchKey"
                                fluid
                                loading={this.state.loading}
                                onChange={this.handleSearchTextChange}
                                icon={{ name: 'search' }}
                                placeholder="Search..."
                            />
                        </Form>
                    </div>

                    <div className="actions">
                        {this.props.plan !== 'Free' && this.props.permissions.reports ? (
                            <React.Fragment>
                                <Dropdown
                                    text="Export to CSV"
                                    icon="file excel outline"
                                    floating
                                    labeled
                                    button
                                    className="icon"
                                >
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={this.exportToCSV}>Normal Export</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Button onClick={this.exportToPdf} content="Export to PDF" icon="file pdf outline" />
                            </React.Fragment>
                        ) : null}
                        <Dropdown closeOnEscape text="Filter" icon="filter" floating labeled button className="icon">
                            <Dropdown.Menu style={{ left: 'auto', right: 0 }}>
                                <Dropdown.Header style={{ fontStyle: 'italic' }} content="Filter by Date" />
                                <Dropdown.Item
                                    style={{ fontSize: '12px' }}
                                    onClick={() =>
                                        this.setState({
                                            timeRangeModalVisible: true,
                                        })
                                    }
                                >
                                    Select Date Range
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        {this.state.timeRangeModalVisible ? (
                            <Modal
                                onClose={() =>
                                    this.setState({
                                        timeRangeModalVisible: false,
                                    })
                                }
                                open={true}
                                size="tiny"
                            >
                                <Modal.Header>Select Date Range</Modal.Header>
                                <Modal.Content>
                                    <DatesRangeInput
                                        dateFormat="MM/DD/YYYY"
                                        onChange={this.handleTimeRangeChange}
                                        style={{ width: '100%' }}
                                        name="datesRange"
                                        placeholder="From - To"
                                        value={this.state.datesRange}
                                        iconPosition="left"
                                        // onChange={this.handleChange}
                                    />
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button
                                        onClick={this.setTimeRange}
                                        icon="checkmark"
                                        labelPosition="right"
                                        content="Apply"
                                    />
                                </Modal.Actions>
                            </Modal>
                        ) : null}
                    </div>
                </section>

                {this.state.queryParams.startDate ? (
                    <Label.Group color="blue" style={{ marginTop: '1rem' }}>
                        <Label as="a">
                            Date From:
                            <span style={{ margin: '0 0.5rem' }}>
                                {moment(this.state.queryParams.startDate).format('MM/DD/YYYY')}
                            </span>
                            to
                            <span style={{ margin: '0 0.5rem' }}>
                                {moment(this.state.queryParams.endDate).format('MM/DD/YYYY')}
                            </span>
                        </Label>
                    </Label.Group>
                ) : null}
                <Table celled compact definition sortable>
                    <Dimmer active={this.state.loading} inverted>
                        <Loader size="medium">Loading</Loader>
                    </Dimmer>
                    <Table.Header fullWidth>
                        <Table.Row>
                            <Table.HeaderCell>First Name</Table.HeaderCell>
                            <Table.HeaderCell>Last Name</Table.HeaderCell>
                            <Table.HeaderCell>Total Hours</Table.HeaderCell>
                            <Table.HeaderCell />
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {this.state.tableData.map((row) => (
                            <Table.Row key={row.employee._id}>
                                <Table.Cell>{row.employee.firstName}</Table.Cell>
                                <Table.Cell>{row.employee.lastName}</Table.Cell>
                                <Table.Cell>{this.getTotalHours(row.totalHours)}</Table.Cell>
                                <Table.Cell>
                                    <Link
                                        to={`/panel/timesheet/${row.employee._id}?startDate=${this.state.queryParams.startDate}&endDate=${this.state.queryParams.endDate}`}
                                    >
                                        Details
                                    </Link>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>

                {this.state.tableData.length === 0 && !this.state.loading ? (
                    <p style={{ textAlign: 'center' }}>No Results!</p>
                ) : null}

                <section style={{ display: 'flex', justifyContent: 'center' }}>
                    {this.state.meta.totalRecords >= this.state.meta.pageLimit ? (
                        <Pagination
                            onPageChange={this.handlePaginationChange}
                            defaultActivePage={this.state.meta.currentPage + 1}
                            totalPages={Math.ceil(this.state.meta.totalRecords / this.state.meta.pageLimit)}
                        />
                    ) : null}
                </section>
                {this.state.showEmployeeTimeSheetDetails && (
                    <TimeSheetDetailsModal
                        employeeId={this.state.employeeDetailsId}
                        employeeName={this.state.employeeDetailsName}
                        startDate={this.state.queryParams.startDate}
                        endDate={this.state.queryParams.endDate}
                        onClose={this.closeEmployeeTimeSheetDetailsModal}
                    />
                )}
            </section>
        );
    }
}

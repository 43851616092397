import React, {Component} from 'react'
import {Button, Header, Icon, Modal} from "semantic-ui-react";

export default class AddonConfirmationModal extends Component {
    render() {
        return (
            <Modal open={true} basic size='small' onClose={this.props.onClose} closeIcon>
                <Header icon='resize horizontal' content="Extend your subscription"/>
                <Modal.Content>
                    <p>
                        <span style={{fontWeight: "bold"}}>{this.props.price}</span>
                        $ will be deducted from your account balance for the extension of your subscription. Are you
                        sure?
                    </p>
                    <p>
                        Note: changes will take a little while to be applied.
                    </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' basic onClick={this.props.onClose}>
                        <Icon name='remove'/> No, cancel
                    </Button>
                    <Button color='green' inverted onClick={this.props.onConfirm}>
                        <Icon name='checkmark'/> Yes I'm sure
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }
}
import './index.css'
import React, { Component } from 'react'
import {Button, Table, Dimmer, Loader, Icon} from 'semantic-ui-react'
import config from 'react-global-configuration'
import axios from 'axios'
import download from 'downloadjs'
import 'moment-timezone';

export default class InvoicesTable extends Component {
    state = {
        tableData: [],
        loading: false,
        queryParams: {
            page: 1
        },
        meta: {}
    };

    componentDidMount() {
        document.title = 'AttendLab | Invoices';
        this.getData();
    }

    getData = () => {
        this.setState({ loading: true });
        axios.get(config.get('serverAPI').invoices.get, {params: this.state.queryParams})
            .then(response => {
                this.setState({
                    tableData: response.data.data,
                    meta: response.data.meta,
                    loading: false
                });
            })
            .catch(error => {
            })
    };

    handlePaginationChange = (e, {activePage}) => this.setState({
        queryParams: {
            ...this.state.queryParams,
            page: activePage - 1
        }
    }, () => this.getData());


    // handleSearchTextChange = (e, {name, value}) => this.setState({
    //     queryParams: {
    //         ...this.state.queryParams,
    //         [name]: value
    //     }
    // });

    //
    paginateOneForward = () => {
        this.setState({
            queryParams: {
                currentPage: this.state.queryParams.page + 1
            }
        })
    };

    paginateOneBackward = () => {
        this.setState({
            queryParams: {
                currentPage: this.state.queryParams.page - 1
            }
        })
    };

    exportToCSV = () => {
        axios.post(config.get('serverAPI').invoices.export)
            .then(response => {
                download(config.get('exportLink') + '/assets/exports/' + response.data.data.path)
            })
    };

    exportToPdf = (id) => {
        axios.post(config.get('serverAPI').invoices.exportPdf, {
            invoiceId: id
        })
            .then(response => {
                download(config.get('exportLink') + '/assets/exports/' + response.data.data.path)
            })
    };

    // printTable = () => {
    //     axios.post(config.get('serverAPI').auditLog.exportPdf)
    //         .then(response => {
    //             printJS(config.get('exportLink') + '/assets/exports/' + response.data.data.path)
    //         })
    // };

    render() {
        return (
            <section>
                <section className="table-actions">
                    <div className="search-box">
                        {/*<Form onSubmit={this.handleSubmit}>*/}
                        {/*    <Form.Input*/}
                        {/*        name='query'*/}
                        {/*        fluid loading={this.state.loading}*/}
                        {/*        onChange={this.handleSearchTextChange}*/}
                        {/*        icon={{name: 'search'}}*/}
                        {/*        placeholder='Search...'*/}
                        {/*    />*/}
                        {/*</Form>*/}
                    </div>

                    <div className="actions" style={{textAlign: "right"}}>
                        <Button
                            onClick={this.exportToCSV}
                            content='Export to CSV'
                            icon='file excel outline'
                        />

                        {/*<Button*/}
                        {/*    onClick={this.exportToPdf}*/}
                        {/*    content='Export to PDF'*/}
                        {/*    icon='file pdf outline'*/}
                        {/*/>*/}

                        {/*<Button*/}
                        {/*    onClick={this.printTable}*/}
                        {/*    content='Print'*/}
                        {/*    icon='print'*/}
                        {/*/>*/}

                        {/* <Button.Group className='filter'>
                            <Button>
                                <Icon name='filter' />
                                Operation</Button>
                            <Dropdown onChange={this.filterByOperation} options={filterOptions} floating button className='icon' />
                        </Button.Group> */}
                    </div>
                </section>

                <Table celled compact definition>
                    <Dimmer active={this.state.loading} inverted>
                        <Loader size='medium'>Loading</Loader>
                    </Dimmer>
                    <Table.Header fullWidth>
                        <Table.Row>
                            <Table.HeaderCell>
                                Number
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Date
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Status
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Total
                            </Table.HeaderCell>
                            <Table.HeaderCell style={{textAlign: "center"}}>
                                PDF
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {
                            this.state.tableData.map(row =>
                                <Table.Row key={row._id}>
                                    <Table.Cell>
                                        {row.number}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {row.invoice_date}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <span style={{textTransform: "capitalize"}}>
                                            {row.status}
                                        </span>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {row.total + " " + row.currency_code}
                                    </Table.Cell>
                                    <Table.Cell width={2} style={{textAlign: "center"}}>
                                        <Button
                                            onClick={() => this.exportToPdf(row.invoice_id)}
                                            basic size="mini" icon>
                                            Download <Icon name='download' />
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                            )
                        }
                    </Table.Body>
                </Table>

                {this.state.tableData.length === 0 && !this.state.loading ? (
                    <p style={{ textAlign: 'center' }}>No Results!</p>
                ) : null}

                <section style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button.Group>
                        <Button
                            disabled={this.state.queryParams.page === 1}
                            onClick={this.paginateOneBackward}
                            labelPosition='left' icon='left chevron' content='Back' />
                        <Button
                            disabled={this.state.tableData.length <= this.state.meta.pageLimit}
                            onClick={this.paginateOneForward}
                            labelPosition='right' icon='right chevron' content='Next' />
                    </Button.Group>
                </section>
            </section>
        )
    }
}
import React, { Component } from 'react'
import { Button, Grid, Header, Message, Label, Image } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import Axios from 'axios'
import config from 'react-global-configuration'
import ForgetPassword from './ForgetPassword'
import "./index.css"
import loginPhoto from "./Login_Image.png"
import logo from "./logo_Admin.png"

class LoginForm extends Component {
    state = {
        email: '',
        password: '',
        loading: false,
        loginError: {
            error: false,
            message: ''
        },
        loginFormVisible: true,
        forgetPasswordVisible: false,
        resetSuccess: false,
    };

    handleChange = (e, { name, value }) => {
        this.setState({
            [name]: value
        });
    };

    handleLogin = () => {
        this.setState({ loading: true });
        Axios.post(config.get('serverAPI').login.get + '/', {
            email: this.state.email,
            password: this.state.password
        }).then(response => {
            this.setState({ loading: false });
            localStorage.setItem('token', response.data.data.token);
            localStorage.setItem('userID', response.data.data.user.id);
            this.props.onSuccess()
        }).catch(error => {
            this.setState({
                loginError: {
                    error: true,
                    message: 'Email or Password is invalid.'
                },
                loading: false
            })
        });
    };

    render() {
        const errorLabel = <Label color="red" pointing />;
        return <div className='login-form' style={{ width: "100%", margin: "10rem auto" }}>
            {
                this.state.loginFormVisible ? (
                    <Grid id="login-section">
                        <section className="photo-section">
                            <Image src={loginPhoto} />
                        </section>
                        <section className="login-section">
                            <Image src={logo} size="tiny" />
                            <Header as="h1" style={{ margin: "1rem 0" }}>Welcome</Header>
                            <Header as='h3' style={{ margin: "0 0 1.5rem 0", color: "rgb(134, 128, 130)" }} textAlign='left'>
                                Login to Admin Portal
                                </Header>

                            <Form size='large' onValidSubmit={this.handleLogin}>
                                <Form.Input
                                    size="mini"
                                    value={this.state.email}
                                    validations="isRequired,isEmail"
                                    validationErrors={{ isRequired: 'Email is required', isEmail: 'Email not valid' }}
                                    errorLabel={errorLabel}
                                    onChange={this.handleChange}
                                    name='email'
                                    fluid icon='user'
                                    iconPosition='left' placeholder='Email address' />
                                <Form.Input
                                    size="mini"
                                    value={this.state.password}
                                    validations="isRequired"
                                    validationErrors={{ isRequired: 'Password is required' }}
                                    errorLabel={errorLabel}
                                    onChange={this.handleChange}
                                    fluid
                                    name='password'
                                    icon='lock'
                                    iconPosition='left'
                                    placeholder='Password'
                                    type='password'
                                />
                                {
                                    this.state.loginError.error ? (
                                        <p style={{
                                            color: 'red',
                                            fontSize: '.9'
                                        }}>{this.state.loginError.message}</p>
                                    ) : null
                                }
                                <div style={{ margin: '0.3rem 0 0.8rem 0', display: 'flex', justifyContent: "flex-end" }}>
                                    <a
                                        onClick={() => this.setState({
                                            loginFormVisible: false,
                                            forgetPasswordVisible: true
                                        })}
                                        style={{ fontSize: "0.9rem", textDecoration: 'underline' }} href='#'>Forgot
                                            Password?</a>
                                </div>
                                <Button
                                    className="login-button"
                                    size="big" loading={this.state.loading} style={{ background: "#4360BA", color: "white" }} fluid>
                                    Login
                                </Button>
                                <div style={{ textAlign: "center", marginTop: "1rem" }}>
                                    <a href="#"
                                        onClick={e => {
                                            e.preventDefault();
                                            this.props.onSubscribe();
                                        }}
                                        style={{ textDecoration: "underline" }}
                                    >Create New Account</a>
                                </div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginTop: "1rem",
                                    fontSize: "0.9rem",
                                    textDecoration: "underline",
                                }}>
                                    <p>
                                        <a target="_blank" href="https://attendlab.com/?page_id=283">
                                            Terms of Use
                                        </a>
                                    </p>
                                    <p>
                                        <a target="_blank" href="https://attendlab.com/?page_id=3">
                                            Privacy Policy
                                        </a>
                                    </p>
                                </div>
                                {this.state.resetSuccess ? (
                                    <Message positive>
                                        <p>Password has been reset successfully.</p> Login with your new password.
                                        </Message>
                                ) : null}

                            </Form>
                        </section>
                    </Grid>
                ) : null
            }

            {this.state.forgetPasswordVisible ? (
                <React.Fragment>
                    <Image  style={{margin: "1.5rem auto"}} src={logo} />
                    <ForgetPassword onClose={(reset) => this.setState({
                        forgetPasswordVisible: false,
                        loginFormVisible: true,
                        resetSuccess: reset.reset
                    })} />
                </React.Fragment>
            ) : null}
        </div>
    }
}

export default LoginForm
